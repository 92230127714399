import React, { useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import TaskCard from './TaskCard';
import { Card, Typography, Button, Input, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

const { Title } = Typography;

function Column({ column, tasks, onEdit, onRemove }) {
  const { t } = useTranslation();
  const { isOver, setNodeRef } = useDroppable({ id: column.id });
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(column.title);

  const style = {
    width: '300px',
    minHeight: '500px',
    backgroundColor: isOver ? '#f0f0f0' : '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
  };

  const handleEditSubmit = () => {
    if (editedTitle.trim() !== '') {
      onEdit(column.id, editedTitle);
      setIsEditing(false);
    }
  };

  const handleEditCancel = () => {
    setEditedTitle(column.title);
    setIsEditing(false);
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {isEditing ? (
            <Input
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              onPressEnter={handleEditSubmit}
              style={{ width: '70%' }}
            />
          ) : (
            <Title level={4} style={{ margin: 0 }}>{column.title}</Title>
          )}
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {isEditing ? (
              <>
                <Button
                  icon={<CheckOutlined />}
                  onClick={handleEditSubmit}
                  style={{ marginRight: '5px' }}
                  size="small"
                />
                <Button
                  icon={<CloseOutlined />}
                  onClick={handleEditCancel}
                  size="small"
                />
              </>
            ) : (
              <>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => setIsEditing(true)}
                  style={{ marginRight: '5px' }}
                  size="small"
                />
                <Popconfirm
                  title={t("Are you sure you want to remove this column?")}
                  onConfirm={onRemove}
                  okText={t("Yes")}
                  cancelText={t("No")}
                >
                  <Button
                    icon={<DeleteOutlined />}
                    size="small"
                  />
                </Popconfirm>
              </>
            )}
          </span>
        </div>
      }
      extra={<span style={{ backgroundColor: '#f0f0f0', padding: '2px 8px', borderRadius: '12px' }}>
        {column.taskIds.length}
      </span>}
      bodyStyle={{ padding: '10px', height: 'calc(100% - 57px)', overflowY: 'auto' }}
    >
      <SortableContext items={column.taskIds} strategy={verticalListSortingStrategy}>
        {column.taskIds.map((taskId) => (
          <TaskCard key={taskId} id={taskId} task={tasks[taskId]} />
        ))}
        {column.taskIds.length === 0 && (
          <div style={{ textAlign: 'center', color: '#999', marginTop: '20px' }}>
            {t('Drag tasks here')}
          </div>
        )}
      </SortableContext>
    </Card>
  );
}

export default Column;