import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase';
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  writeBatch
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import VerticalMenu from '../VerticalMenu';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Modal, Input, Button, Card, Space, Typography, Progress, 
  Layout, Row, Col, Select, message, Avatar, Dropdown, Menu,
  Tooltip, ColorPicker, Popconfirm, Empty, Tag, Statistic,
  Form, Divider
} from 'antd';
import { 
  PlusOutlined, EditOutlined, DeleteOutlined, MoreOutlined, 
  AppstoreOutlined, UnorderedListOutlined, SortAscendingOutlined,
  InfoCircleOutlined, SettingOutlined
} from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;
const { Header, Content } = Layout;
const { Search } = Input;
const { Option } = Select;

const Companies = () => {
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [editedCompany, setEditedCompany] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterIndustry, setFilterIndustry] = useState('All');
  const [filterBusinessModel, setFilterBusinessModel] = useState('All');
  const [companySettingsModalVisible, setCompanySettingsModalVisible] = useState(false);
  const userId = auth.currentUser.uid;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    fetchCompanies();
  }, [userId]);

  const fetchCompanies = async () => {
    const companiesCollectionRef = collection(db, "companies");
    const q = query(companiesCollectionRef, where("userId", "==", userId));
    const querySnapshot = await getDocs(q);
    const fetchedCompanies = querySnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    }));
    setCompanies(fetchedCompanies);
  };

  const handleAddCompany = async (values) => {
    try {
      const color = getRandomColor();
      await addDoc(collection(db, 'companies'), {
        ...values,
        userId: userId,
        color,
        created: new Date()
      });
      message.success(t('Company added successfully!'));
      setIsModalVisible(false);
      form.resetFields();
      fetchCompanies();
    } catch (error) {
      console.error("Error adding company document: ", error);
      message.error(t("Error adding company"));
    }
  };

  const handleUpdateCompany = async () => {
    if (editedCompany) {
      const companyRef = doc(db, 'companies', editedCompany.id);
      await updateDoc(companyRef, { 
        name: editedCompany.name,
        industry: editedCompany.industry,
        businessModel: editedCompany.businessModel,
        website: editedCompany.website,
        color: editedCompany.color
      });
      setCompanySettingsModalVisible(false);
      message.success(t('Company updated successfully'));
      fetchCompanies();
    }
  };

  const handleDeleteCompany = async () => {
    if (editedCompany) {
      try {
        const batch = writeBatch(db);
        
        // Delete the company
        const companyRef = doc(db, 'companies', editedCompany.id);
        batch.delete(companyRef);
        
        await batch.commit();
        message.success(t('Company deleted successfully'));
        setCompanySettingsModalVisible(false);
        fetchCompanies();
      } catch (error) {
        console.error("Error deleting company: ", error);
        message.error(t('Error deleting company'));
      }
    }
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getInitials = (name) => {
    return name.split(' ').map(word => word[0]).join('').toUpperCase();
  };

  const filteredAndSortedCompanies = useMemo(() => {
    return companies
      .filter(company => 
        company.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (filterIndustry === 'All' || company.industry === filterIndustry) &&
        (filterBusinessModel === 'All' || company.businessModel === filterBusinessModel)
      )
      .sort((a, b) => {
        if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
  }, [companies, searchTerm, filterIndustry, filterBusinessModel, sortBy, sortOrder]);

  const industries = [...new Set(companies.map(company => company.industry))];
  const businessModels = [...new Set(companies.map(company => company.businessModel))];

  const companyList = useMemo(() => (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', justifyContent: 'flex-start' }}>
      {filteredAndSortedCompanies.length === 0 ? (
        <Empty
          description={
            <Space direction="vertical" align="center">
              <Text>{t("No companies found")}</Text>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)}>
                {t("Add Company")}
              </Button>
            </Space>
          }
        />
      ) : (
        <AnimatePresence>
          {filteredAndSortedCompanies.map(company => (
            <motion.div
              key={company.id}
              layout
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.3 }}
              style={{ width: 'calc(25% - 12px)', minWidth: '250px', marginBottom: '16px' }}
            >
              <Card
                hoverable
                onClick={() => navigate(`/company/${company.id}`)}
                style={{ 
                  width: '100%',
                  height: '100%',
                  borderRadius: 8, 
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                bodyStyle={{ 
                  padding: 16, 
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <Space direction="vertical" size="small" style={{ width: '100%', height: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Avatar style={{ backgroundColor: company.color }} size={64}>
                        {getInitials(company.name)}
                      </Avatar>
                      <Dropdown overlay={
                        <Menu>
                          <Menu.Item key="1" icon={<EditOutlined />} onClick={(e) => {
                            e.domEvent.stopPropagation();
                            setEditedCompany(company);
                            setCompanySettingsModalVisible(true);
                          }}>
                            {t("Edit")}
                          </Menu.Item>
                          <Menu.Item key="2" icon={<DeleteOutlined />} onClick={(e) => {
                            e.domEvent.stopPropagation();
                            setEditedCompany(company);
                            setCompanySettingsModalVisible(true);
                          }}>
                            {t("Delete")}
                          </Menu.Item>
                        </Menu>
                      }>
                        <Button icon={<MoreOutlined />} type="text" onClick={e => e.stopPropagation()} />
                      </Dropdown>
                    </div>
                    <Title level={4} style={{ margin: '16px 0 8px', flex: 1 }}>{company.name}</Title>
                    <Paragraph type="secondary" ellipsis={{ rows: 2 }}>{company.industry}</Paragraph>
                    <Space>
                      <Tag color="blue">{company.businessModel}</Tag>
                      <Tag color="green">{company.website}</Tag>
                    </Space>
                  </Space>
                </div>
              </Card>
            </motion.div>
          ))}
        </AnimatePresence>
      )}
    </div>
  ), [filteredAndSortedCompanies, navigate, t]);

  return (
    <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f0f2f5' }}>
      <VerticalMenu />
      <Layout style={{ minHeight: '100vh', background: '#f0f2f5' }}>
        <Header style={{ background: '#fff', padding: '0 24px', boxShadow: '0 2px 8px rgba(0,0,0,0.06)' }}>
          <Row justify="space-between" align="middle" style={{ height: '100%' }}>
            <Col>
              <Title level={2} style={{ margin: 0 }}>{t("Companies")}</Title>
            </Col>
            <Col>
              <Space>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)}>
                  {t("Add Company")}
                </Button>
              </Space>
            </Col>
          </Row>
        </Header>
        <Content style={{ padding: '24px', maxWidth: '1200px', width: '100%', margin: '0 auto' }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6}>
              <Card>
                <Statistic title={t("Total Companies")} value={companies.length} />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card>
                <Statistic title={t("B2B Companies")} value={companies.filter(c => c.businessModel === 'B2B').length} />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card>
                <Statistic title={t("B2C Companies")} value={companies.filter(c => c.businessModel === 'B2C').length} />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card>
                <Statistic title={t("Industries")} value={industries.length} />
              </Card>
            </Col>
          </Row>
          <Card style={{ marginTop: 16 }}>
            <Space style={{ marginBottom: 16, width: '100%', justifyContent: 'space-between' }}>
              <Space wrap>
                <Search
                  placeholder={t("Search companies")}
                  allowClear
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ width: 200 }}
                />
                <Select 
                  style={{ width: 150 }} 
                  value={filterIndustry}
                  onChange={setFilterIndustry}
                  placeholder={t("Filter Industry")}
                >
                  <Option value="All">{t("All Industries")}</Option>
                  {industries.map(industry => (
                    <Option key={industry} value={industry}>{industry}</Option>
                  ))}
                </Select>
                <Select 
                  style={{ width: 150 }} 
                  value={filterBusinessModel}
                  onChange={setFilterBusinessModel}
                  placeholder={t("Filter Business Model")}
                >
                  <Option value="All">{t("All Models")}</Option>
                  {businessModels.map(model => (
                    <Option key={model} value={model}>{model}</Option>
                  ))}
                </Select>
                <Select 
                  style={{ width: 120 }} 
                  value={sortBy}
                  onChange={setSortBy}
                  placeholder={t("Sort By")}
                >
                  <Option value="name">{t("Name")}</Option>
                  <Option value="industry">{t("Industry")}</Option>
                  <Option value="businessModel">{t("Business Model")}</Option>
                </Select>
                <Button 
                  icon={sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortAscendingOutlined rotate={180} />} 
                  onClick={() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}
                />
              </Space>
              <Space>
                <Tooltip title={t("Grid View")}>
                  <Button 
                    type={viewMode === 'grid' ? 'primary' : 'default'}
                    icon={<AppstoreOutlined />}
                    onClick={() => setViewMode('grid')}
                  />
                </Tooltip>
                <Tooltip title={t("List View")}>
                  <Button
                    type={viewMode === 'list' ? 'primary' : 'default'}
                    icon={<UnorderedListOutlined />}
                    onClick={() => setViewMode('list')}
                  />
                </Tooltip>
              </Space>
            </Space>
            {companyList}
          </Card>
        </Content>
      </Layout>

      <Modal
        title={t("Add New Company")}
        visible={isModalVisible}
        onOk={() => form.submit()}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
        }}
      >
        <Form form={form} layout="vertical" onFinish={handleAddCompany}>
          <Form.Item name="name" label={t("Name")} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="industry" label={t("Industry")}>
            <Input />
          </Form.Item>
          <Form.Item name="businessModel" label={t("Business Model")} rules={[{ required: true }]}>
            <Select>
              <Option value="B2B">B2B</Option>
              <Option value="B2C">B2C</Option>
            </Select>
          </Form.Item>
          <Form.Item name="website" label={t("Website")}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t("Company Settings")}
        visible={companySettingsModalVisible}
        onOk={handleUpdateCompany}
        onCancel={() => setCompanySettingsModalVisible(false)}
        width={500}
      >
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Input
            placeholder={t("Company Name")}
            value={editedCompany?.name}
            onChange={(e) => setEditedCompany({ ...editedCompany, name: e.target.value })}
          />
          <Input
            placeholder={t("Industry")}
            value={editedCompany?.industry}
            onChange={(e) => setEditedCompany({ ...editedCompany, industry: e.target.value })}
          />
          <Select
            style={{ width: '100%' }}
            placeholder={t("Business Model")}
            value={editedCompany?.businessModel}
            onChange={(value) => setEditedCompany({ ...editedCompany, businessModel: value })}
          >
            <Option value="B2B">B2B</Option>
            <Option value="B2C">B2C</Option>
          </Select>
          <Input
            placeholder={t("Website")}
            value={editedCompany?.website}
            onChange={(e) => setEditedCompany({ ...editedCompany, website: e.target.value })}
          />
          <div>
            <Text>{t("Company Color")}</Text>
            <ColorPicker
              value={editedCompany?.color}
              onChange={(color) => setEditedCompany({ ...editedCompany, color: color.toHexString() })}
            />
          </div>
          <Divider />
          <div>
            <Title level={5} style={{ color: '#ff4d4f' }}>{t("Danger Zone")}</Title>
            <Popconfirm
              title={t("Are you sure you want to delete this company?")}
              onConfirm={handleDeleteCompany}
              okText={t("Yes")}
              cancelText={t("No")}
              okButtonProps={{ danger: true }}
            >
              <Button danger icon={<DeleteOutlined />}>
                {t("Delete Company")}
              </Button>
            </Popconfirm>
          </div>
        </Space>
      </Modal>
    </div>
  );
};

export default Companies;