import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import {
  collection,
  onSnapshot,
  addDoc,
  query,
  where,
  doc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { 
  Layout, Typography, Avatar, Space, Button, message, Table, Popconfirm, 
  Modal, Input, Form, Select, Drawer, Descriptions, Divider, Tag, Steps,
  Card, Row, Col, Tooltip, Statistic, List
} from 'antd';
import { 
  EditOutlined, DeleteOutlined, PlusOutlined, MailOutlined, UserOutlined, 
  PhoneOutlined, EyeOutlined, TeamOutlined, BuildOutlined, GlobalOutlined, BankOutlined
} from '@ant-design/icons';
import VerticalMenu from './VerticalMenu';
import { useMediaQuery } from 'react-responsive';

const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;
const { Step } = Steps;

function Contacts() {
  const [contacts, setContacts] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  const [isCompanyModalVisible, setIsCompanyModalVisible] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentContact, setCurrentContact] = useState({});
  const [contactForm] = Form.useForm();
  const [companyForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const userId = auth.currentUser.uid;

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const contactsRef = collection(db, 'contacts');
    const q = query(contactsRef, where('userId', '==', userId));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedContacts = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      const filteredContacts = fetchedContacts.filter(contact =>
        contact.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setContacts(filteredContacts);
    });
    return () => unsubscribe();
  }, [userId, searchTerm]);

  useEffect(() => {
    const companiesRef = collection(db, 'companies');
    const qCompanies = query(companiesRef, where('userId', '==', userId));
    const unsubscribeCompanies = onSnapshot(qCompanies, (snapshot) => {
      setCompanies(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
    return () => unsubscribeCompanies();
  }, [userId]);

  const handleAddContact = async (values) => {
    try {
      const companyDetails = companies.find(company => company.id === values.company);
      const color = getRandomColor();
      await addDoc(collection(db, 'contacts'), {
        ...values,
        userId,
        color,
        companyId: values.company,
        companyName: companyDetails ? companyDetails.name : '',
      });
      message.success('Contact added successfully!');
      setIsContactModalVisible(false);
      contactForm.resetFields();
    } catch (error) {
      console.error("Error adding contact: ", error);
      message.error("Error adding contact");
    }
  };

  const handleAddCompany = async (companyValues) => {
    try {
      const color = getRandomColor();
      await addDoc(collection(db, 'companies'), {
        ...companyValues,
        userId,
        color
      });
      message.success('Company added successfully!');
      setIsCompanyModalVisible(false);
      companyForm.resetFields();
    } catch (error) {
      console.error("Error adding company: ", error);
      message.error("Error adding company");
    }
  };

  const handleDelete = async (contactId) => {
    try {
      await deleteDoc(doc(db, 'contacts', contactId));
      message.success('Contact deleted successfully');
    } catch (error) {
      console.error("Error deleting document: ", error);
      message.error("Error deleting contact");
    }
  };

  const showDeleteConfirm = (contactId) => {
    confirm({
      title: 'Are you sure you want to delete this contact?',
      content: 'This action cannot be undone',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDelete(contactId);
      },
    });
  };

  const showContactDetails = (contact) => {
    setCurrentContact(contact);
    setDrawerVisible(true);
  };

  const onFormFinish = async (values) => {
    try {
      const contactRef = doc(db, 'contacts', currentContact.id);
      await updateDoc(contactRef, {
        ...values,
        companyName: companies.find(c => c.id === values.company)?.name,
        companyId: values.company,
      });
      message.success('Contact updated successfully!');
      setDrawerVisible(false);
    } catch (error) {
      console.error('Error updating contact:', error);
      message.error('Failed to update contact.');
    }
  };

  const updateStatus = async (newStatus) => {
    try {
      const contactRef = doc(db, 'contacts', currentContact.id);
      await updateDoc(contactRef, { status: newStatus });
      message.success('Status updated successfully!');
      setCurrentContact({ ...currentContact, status: newStatus });
    } catch (error) {
      message.error('Failed to update status.');
    }
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const statusOptions = [
    { label: 'New', value: 'New', color: 'blue' },
    { label: 'Contacted', value: 'Contacted', color: 'orange' },
    { label: 'Interested', value: 'Interested', color: 'cyan' },
    { label: 'Qualified', value: 'Qualified', color: 'geekblue' },
    { label: 'Negotiation', value: 'Negotiation', color: 'purple' },
    { label: 'Won', value: 'Won', color: 'green' },
  ];

  const statusSteps = ['New', 'Contacted', 'Interested', 'Qualified', 'Negotiation', 'Won'];

  const columns = [
    {
      title: 'Contact',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Space>
          <Avatar style={{ backgroundColor: record.color }}>{text[0].toUpperCase()}</Avatar>
          <Text strong>{text}</Text>
        </Space>
      ),
    },
    { 
      title: 'Company', 
      dataIndex: 'companyName', 
      key: 'company', 
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      responsive: ['md'],
    },
    { 
      title: 'Title', 
      dataIndex: 'title', 
      key: 'title', 
      sorter: (a, b) => a.title.localeCompare(b.title),
      responsive: ['lg'],
    },
    { 
      title: 'Status', 
      dataIndex: 'status', 
      key: 'status', 
      sorter: (a, b) => a.status.localeCompare(b.status),  
      render: (text, record) => (
        <Tag color={statusOptions.find(option => option.value === record.status)?.color || 'default'}>
          {record.status}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title="Send Email">
            <Button icon={<MailOutlined />} href={`mailto:${record.email}`} />
          </Tooltip>
          <Tooltip title="View Details">
            <Button icon={<EyeOutlined />} onClick={() => showContactDetails(record)} />
          </Tooltip>
          <Tooltip title="Delete">
            <Button icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(record.id)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const renderMobileList = () => (
    <List
      dataSource={contacts}
      renderItem={(item) => (
        <List.Item
          actions={[
            <Button icon={<MailOutlined />} href={`mailto:${item.email}`} />,
            <Button icon={<EyeOutlined />} onClick={() => showContactDetails(item)} />,
            <Button icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(item.id)} />,
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar style={{ backgroundColor: item.color }}>{item.name[0].toUpperCase()}</Avatar>}
            title={<Text strong>{item.name}</Text>}
            description={
              <>
                <div>{item.companyName}</div>
                <Tag color={statusOptions.find(option => option.value === item.status)?.color || 'default'}>
                  {item.status}
                </Tag>
              </>
            }
          />
        </List.Item>
      )}
    />
  );

  return (
    <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f0f2f5' }}>
    <VerticalMenu />
      <div style={{ flex: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
        <Layout>
          <Content style={{ margin: '24px 16px', padding: 24, background: '#fff' }}>
            <Title level={2}>Contacts</Title>
            <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Card>
                  <Statistic
                    title="Total Contacts"
                    value={contacts.length}
                    prefix={<TeamOutlined />}
                  />
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Card>
                  <Statistic
                    title="Companies"
                    value={companies.length}
                    prefix={<BankOutlined />}
                  />
                </Card>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
              <Col xs={24} sm={12}>
                <Input
                  placeholder="Search contacts"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <Space>
                  <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsContactModalVisible(true)}>
                    Add Contact
                  </Button>
                  <Button icon={<PlusOutlined />} onClick={() => setIsCompanyModalVisible(true)}>
                    Add Company
                  </Button>
                </Space>
              </Col>
            </Row>
            {isMobile ? renderMobileList() : (
              <Table
                dataSource={contacts}
                columns={columns}
                rowKey="id"
                pagination={{
                  showSizeChanger: true,
                  pageSizeOptions: ['10', '50', '100', '200'],
                  defaultPageSize: 10,
                }}
              />
            )}
          </Content>
        </Layout>

        {/* Add Contact Modal */}
        <Modal
          title="Add Contact"
          visible={isContactModalVisible}
          onCancel={() => setIsContactModalVisible(false)}
          footer={null}
        >
          <Form form={contactForm} layout="vertical" onFinish={handleAddContact}>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="company" label="Company" rules={[{ required: true }]}>
              <Select
                placeholder="Select a company"
                showSearch
                optionFilterProp="children"
              >
                {companies.map((company) => (
                  <Option key={company.id} value={company.id}>{company.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="title" label="Title">
              <Input />
            </Form.Item>
            <Form.Item name="status" label="Status" initialValue="New">
              <Select>
                {statusOptions.map(option => (
                  <Option key={option.value} value={option.value}>{option.label}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="number" label="Phone Number">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Add Contact</Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* Add Company Modal */}
        <Modal
          title="Add New Company"
          visible={isCompanyModalVisible}
          onCancel={() => setIsCompanyModalVisible(false)}
          footer={null}
        >
          <Form form={companyForm} layout="vertical" onFinish={handleAddCompany}>
            <Form.Item name="name" label="Company Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="industry" label="Industry">
              <Input />
            </Form.Item>
            <Form.Item name="businessModel" label="Business Model">
              <Select>
                <Option value="B2B">B2B</Option>
                <Option value="B2C">B2C</Option>
              </Select>
            </Form.Item>
            <Form.Item name="website" label="Website" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Add Company</Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* Contact Details Drawer */}
       {/* Contact Details Drawer */}
       <Drawer
          title={`${currentContact?.name}'s Details`}
          width={isMobile ? '100%' : 640}
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
        >
          <Form form={editForm} layout="vertical" onFinish={onFormFinish} initialValues={currentContact}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="name" label="Full Name">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="email" label="Email">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="company" label="Company">
                  <Select>
                    {companies.map((company) => (
                      <Option key={company.id} value={company.id}>{company.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="title" label="Title">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="number" label="Phone Number">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Save Changes</Button>
            </Form.Item>
          </Form>
          <Divider />
          <Title level={4}>Contact Status</Title>
          {isMobile ? (
            <Steps direction="vertical" current={statusSteps.indexOf(currentContact?.status)} onChange={updateStatus}>
              {statusSteps.map(status => (
                <Step key={status} title={status} />
              ))}
            </Steps>
          ) : (
            <Steps current={statusSteps.indexOf(currentContact?.status)} onChange={updateStatus}>
              {statusSteps.map(status => (
                <Step key={status} title={status} />
              ))}
            </Steps>
          )}
        </Drawer>
      </div>
    </div>
  );
}

export default Contacts;