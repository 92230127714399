import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import {
  AppstoreOutlined,
  DesktopOutlined,
  BuildOutlined,
  ProjectOutlined,
  BankOutlined,
  ContactsOutlined,
  SettingOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import { db, auth } from '../firebase';
import { collection, query, getDocs, doc, getDoc, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import logo from '../img/logo.png';

function VerticalMenu() {
  const { t } = useTranslation();
  const [topProjects, setTopProjects] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(localStorage.getItem('menuCollapsed') === 'true');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    localStorage.setItem('menuCollapsed', collapsed);
  }, [collapsed]);

  useEffect(() => {
    const fetchTopProjects = async () => {
      const user = auth.currentUser;
      const userId = user.uid;
      const featuresQuery = query(collection(db, 'features'), where('userId', '==', userId));
      const featuresSnapshot = await getDocs(featuresQuery);

      const featureCounts = {};
      featuresSnapshot.forEach(doc => {
        const featureData = doc.data();
        const projectId = featureData.projectId;
        featureCounts[projectId] = (featureCounts[projectId] || 0) + 1;
      });

      const sortedProjects = Object.entries(featureCounts)
        .sort(([, a], [, b]) => b - a)
        .slice(0, 3);

      const projects = await Promise.all(
        sortedProjects.map(async ([projectId]) => {
          const projectDocSnap = await getDoc(doc(db, 'projects', projectId));
          if (projectDocSnap.exists()) {
            return { id: projectId, name: projectDocSnap.data().name };
          }
          return null;
        })
      );

      setTopProjects(projects.filter(project => project !== null));
    };

    fetchTopProjects();
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClick = (e) => {
    if (e.key) {
      switch (e.key) {
        case '2':
          navigate('/');
          break;
        case '4':
          navigate('/TaskBoard');
          break;
        case 'calendar':
          navigate('/Calendar');
          break;
        case 'crm-companies':
          navigate('/Companies');
          break;
        case 'crm-contacts':
          navigate('/Contacts');
          break;
        case 'crm-sales-board':
          navigate('/DealBoard');
          break;
        case 'settings':
          navigate('/settings');
          break;
        default:
          if (e.key.startsWith('project-')) {
            const projectId = e.key.split('-')[1];
            navigate(`/projects/${projectId}/features`);
          }
          break;
      }
    }
  };

  const projectItems = topProjects.map(project => ({
    key: `project-${project.id}`,
    icon: <DesktopOutlined />,
    label: project.name,
  }));

  const mainItems = [
    { key: 'projects-title', label: t('PROJECTS'), type: 'group' },
    { key: '2', icon: <AppstoreOutlined />, label: isMobile ? '' : t('Projects') },
    { key: 'sub1', icon: <BuildOutlined />, label: isMobile ? '' : t('Top Projects'), children: projectItems },
    { key: '4', icon: <ProjectOutlined />, label: isMobile ? '' : t('Taskboard') },
    { key: 'calendar', icon: <CalendarOutlined />, label: isMobile ? '' : t('Calendar') },
    { key: 'crm-title', label: t('CRM'), type: 'group' },
    { key: 'crm-companies', icon: <BankOutlined />, label: isMobile ? '' : t('Companies') },
    { key: 'crm-contacts', icon: <ContactsOutlined />, label: isMobile ? '' : t('Contacts') },
    { key: 'crm-sales-board', icon: <ProjectOutlined />, label: isMobile ? '' : t('Deals') },
  ];

  const settingsItem = { key: 'settings', icon: <SettingOutlined />, label: isMobile ? '' : t('Settings') };

  const mobileItems = [
    mainItems[1],
    mainItems[3],
    mainItems[4], // Calendar
    mainItems[6],
    mainItems[7],
    mainItems[8],
    settingsItem
  ];

  const menuWidth = collapsed ? 80 : 200;

  const DesktopMenu = () => (
    <div style={{ width: menuWidth, height: '100vh', background: 'white', transition: 'width 0.3s', display: 'flex', flexDirection: 'column' }}>
      <div 
        style={{ 
          padding: '10px', 
          textAlign: 'center', 
          width: '100%', 
          cursor: 'pointer' 
        }}
        onClick={toggleCollapsed}
      >
        <img 
          src={logo} 
          alt="Logo" 
          style={{ 
            maxWidth: collapsed ? '60%' : '40%',
            transition: 'max-width 0.3s'
          }} 
        />
      </div>
      <Menu
        defaultSelectedKeys={[location.pathname]}
        mode="inline"
        inlineCollapsed={collapsed}
        items={mainItems}
        onClick={handleMenuClick}
        style={{ flex: 1, borderRight: 0 }}
      />
      <Menu
        mode="inline"
        inlineCollapsed={collapsed}
        items={[settingsItem]}
        onClick={handleMenuClick}
        style={{ borderRight: 0 }}
      />
    </div>
  );

  const MobileMenu = () => (
    <Menu
      mode="horizontal"
      items={mobileItems}
      onClick={handleMenuClick}
      selectedKeys={[location.pathname]}
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '60px',
        backgroundColor: 'white',
        borderTop: '1px solid #f0f0f0',
        zIndex: 1000,
      }}
    />
  );

  return isMobile ? <MobileMenu /> : <DesktopMenu />;
}

export default VerticalMenu;