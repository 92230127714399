import React, { useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactFlow, {
  Background,
  Controls,
  Panel,
  useNodesState,
  useEdgesState,
  addEdge,
  MiniMap,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { db } from '../../firebase';
import {
  collection,
  doc,
  getDoc,
  setDoc,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';
import {
  Layout,
  Button,
  Input,
  Select,
  Space,
  Typography,
  Modal,
  message,
  ColorPicker,
  Tooltip,
  Spin,
  Switch,
  Drawer,
  Tabs,
  Divider,
  Tag,
  Upload,
} from 'antd';
import {
  PlusOutlined,
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  SaveOutlined,
  UndoOutlined,
  RedoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  SettingOutlined,
  ExportOutlined,
  FileImageOutlined,
  DatabaseOutlined,
  TableOutlined,
  ApiOutlined,
  CloudServerOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import html2canvas from 'html2canvas';
import VerticalMenu from '../VerticalMenu';

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const NODE_TYPES = {
  TABLE: 'table',
  API: 'api',
  MICROSERVICE: 'microservice',
  NOTE: 'note',
};

const RELATIONSHIP_TYPES = {
  ONE_TO_ONE: '1:1',
  ONE_TO_MANY: '1:n',
  MANY_TO_MANY: 'n:n',
  INHERITS: 'inherits',
  USES: 'uses',
};

const FIELD_TYPES = [
  { value: 'string', label: 'String' },
  { value: 'number', label: 'Number' },
  { value: 'boolean', label: 'Boolean' },
  { value: 'date', label: 'Date' },
  { value: 'object', label: 'Object' },
  { value: 'array', label: 'Array' },
  { value: 'reference', label: 'Reference' },
];

// Custom Node Component
const TableNode = ({ data, selected }) => {
  return (
    <div
      style={{
        padding: '10px',
        borderRadius: '3px',
        width: 200,
        fontSize: '12px',
        color: '#222',
        textAlign: 'left',
        borderWidth: selected ? '2px' : '1px',
        borderStyle: 'solid',
        borderColor: selected ? '#1890ff' : '#ddd',
        backgroundColor: '#fff',
      }}
    >
      <div style={{ 
        marginBottom: '8px', 
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      }}>
        {data.type === NODE_TYPES.TABLE && <TableOutlined />}
        {data.type === NODE_TYPES.API && <ApiOutlined />}
        {data.type === NODE_TYPES.MICROSERVICE && <CloudServerOutlined />}
        <strong>{data.label}</strong>
      </div>
      {data.fields?.map((field, index) => (
        <div key={index} style={{ 
          display: 'flex',
          justifyContent: 'space-between',
          padding: '4px 0',
          borderTop: '1px solid #f0f0f0'
        }}>
          <span>{field.name}</span>
          <Tag color="blue" style={{ fontSize: '10px' }}>{field.type}</Tag>
        </div>
      ))}
    </div>
  );
};

const nodeTypes = {
  table: TableNode,
  api: TableNode,
  microservice: TableNode,
  note: TableNode,
};

function MindMap() {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const reactFlowWrapper = useRef(null);
  const navigate = useNavigate();

  // State
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [newNodeModal, setNewNodeModal] = useState(false);
  const [newNode, setNewNode] = useState({
    type: NODE_TYPES.TABLE,
    data: {
      label: '',
      type: NODE_TYPES.TABLE,
      fields: [],
      color: '#1890ff',
    },
  });

  // Load data from Firestore
  React.useEffect(() => {
    const mindMapRef = doc(db, 'mindMaps', projectId);
    
    const unsubscribe = onSnapshot(mindMapRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        if (data.nodes) setNodes(data.nodes);
        if (data.edges) setEdges(data.edges);
      } else {
        setDoc(mindMapRef, { nodes: [], edges: [] });
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [projectId]);

  // Save to Firestore
  const handleSave = async () => {
    try {
      const mindMapRef = doc(db, 'mindMaps', projectId);
      await updateDoc(mindMapRef, {
        nodes,
        edges,
      });
      message.success(t('Mind map saved successfully'));
    } catch (error) {
      console.error('Error saving mind map:', error);
      message.error(t('Error saving mind map'));
    }
  };

  // Add new node
  const handleAddNode = () => {
    const position = {
      x: Math.random() * 500,
      y: Math.random() * 500,
    };

    const newNodeObject = {
      id: `node-${uuidv4()}`,
      type: newNode.type,
      position,
      data: {
        ...newNode.data,
        label: newNode.data.label || 'New Node',
      },
    };

    setNodes((nds) => nds.concat(newNodeObject));
    setNewNodeModal(false);
    setNewNode({
      type: NODE_TYPES.TABLE,
      data: {
        label: '',
        type: NODE_TYPES.TABLE,
        fields: [],
        color: '#1890ff',
      },
    });
  };

  // Handle edge creation
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge({ ...params, animated: true }, eds)),
    [setEdges]
  );

  // Handle node/edge selection
  const onSelectionChange = useCallback(({ nodes, edges }) => {
    setSelectedNode(nodes?.[0]);
    setSelectedEdge(edges?.[0]);
  }, []);

  // Export mind map as image
  const handleExportImage = async () => {
    try {
      const element = document.querySelector('.react-flow');
      const canvas = await html2canvas(element);
      const link = document.createElement('a');
      link.download = 'mindmap.png';
      link.href = canvas.toDataURL();
      link.click();
      message.success(t('Mind map exported successfully'));
    } catch (error) {
      message.error(t('Failed to export mind map'));
    }
  };

  if (isLoading) {
    return (
      <div style={{ 
        height: '100vh', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center' 
      }}>
        <Spin size="large" tip={t('Loading mind map...')} />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
    <VerticalMenu />
    <div style={{ flex: 1, overflowY: 'auto'}}>
    <Layout style={{ height: '100vh' }}>
      <Header style={{ 
        background: '#fff', 
        padding: '0 24px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.06)',
        zIndex: 1,
      }}>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          height: '100%',
        }}>
                      <Button type="link" icon={<ArrowLeftOutlined />} onClick={() => navigate(`/projects/${projectId}/features`)}>
            {t("Back")}
          </Button>
          <Title level={4} style={{ margin: 0 }}>{t('Mind Map')}</Title>
          <Space>
            <Button
              icon={<PlusOutlined />}
              onClick={() => setNewNodeModal(true)}
            >
              {t('Add Node')}
            </Button>
            <Button
              icon={<SaveOutlined />}
              onClick={handleSave}
            >
              {t('Save')}
            </Button>
            <Tooltip title={t('Export as Image')}>
              <Button
                icon={<FileImageOutlined />}
                onClick={handleExportImage}
              />
            </Tooltip>
          </Space>
        </div>
      </Header>
      
      <Content style={{ height: 'calc(100vh - 64px)' }}>
        <div style={{ width: '100%', height: '100%' }} ref={reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onSelectionChange={onSelectionChange}
            nodeTypes={nodeTypes}
            fitView
            deleteKeyCode="Delete"
          >
            <Background />
            <Controls />
            <MiniMap />
            <Panel position="top-right">
              <Space direction="vertical">
                <Button 
                  type="default"
                  icon={<ZoomInOutlined />}
                  onClick={() => {
                    const { zoom } = reactFlowWrapper.current.getBoundingClientRect();
                    reactFlowWrapper.current.zoomTo(zoom + 0.2);
                  }}
                />
                <Button 
                  type="default"
                  icon={<ZoomOutOutlined />}
                  onClick={() => {
                    const { zoom } = reactFlowWrapper.current.getBoundingClientRect();
                    reactFlowWrapper.current.zoomTo(zoom - 0.2);
                  }}
                />
              </Space>
            </Panel>
          </ReactFlow>
        </div>
      </Content>

      {/* New Node Modal */}
      <Modal
        title={t('Add New Node')}
        open={newNodeModal}
        onOk={handleAddNode}
        onCancel={() => setNewNodeModal(false)}
      >
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Select
            style={{ width: '100%' }}
            value={newNode.type}
            onChange={(value) => setNewNode({
              ...newNode,
              type: value,
              data: { ...newNode.data, type: value }
            })}
          >
            <Select.Option value={NODE_TYPES.TABLE}>
              <Space><TableOutlined /> {t('Database Table')}</Space>
            </Select.Option>
            <Select.Option value={NODE_TYPES.API}>
              <Space><ApiOutlined /> {t('API')}</Space>
            </Select.Option>
            <Select.Option value={NODE_TYPES.MICROSERVICE}>
              <Space><CloudServerOutlined /> {t('Microservice')}</Space>
            </Select.Option>
          </Select>
          
          <Input
            placeholder={t('Node Name')}
            value={newNode.data.label}
            onChange={(e) => setNewNode({
              ...newNode,
              data: { ...newNode.data, label: e.target.value }
            })}
          />

          {newNode.type === NODE_TYPES.TABLE && (
            <div>
              <Text strong>{t('Fields')}</Text>
              {newNode.data.fields.map((field, index) => (
                <Space key={index} style={{ marginTop: 8, width: '100%' }}>
                  <Input
                    placeholder={t('Field Name')}
                    value={field.name}
                    onChange={(e) => {
                      const newFields = [...newNode.data.fields];
                      newFields[index] = { ...field, name: e.target.value };
                      setNewNode({
                        ...newNode,
                        data: { ...newNode.data, fields: newFields }
                      });
                    }}
                  />
                  <Select
                    value={field.type}
                    onChange={(value) => {
                      const newFields = [...newNode.data.fields];
                      newFields[index] = { ...field, type: value };
                      setNewNode({
                        ...newNode,
                        data: { ...newNode.data, fields: newFields }
                      });
                    }}
                  >
                    {FIELD_TYPES.map(type => (
                      <Select.Option key={type.value} value={type.value}>
                        {type.label}
                      </Select.Option>
                    ))}
                  </Select>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      const newFields = newNode.data.fields.filter((_, i) => i !== index);
                      setNewNode({
                        ...newNode,
                        data: { ...newNode.data, fields: newFields }
                      });
                    }}
                  />
                </Space>
              ))}
              <Button
                type="dashed"
                block
                icon={<PlusOutlined />}
                onClick={() => {
                  setNewNode({
                    ...newNode,
                    data: {
                      ...newNode.data,
                      fields: [...newNode.data.fields, { name: '', type: 'string' }]
                    }
                  });
                }}
                style={{ marginTop: 8 }}
              >
                {t('Add Field')}
              </Button>
            </div>
          )}
        </Space>
      </Modal>

      {/* Node Properties Drawer */}
      <Drawer
        title={t('Node Properties')}
        placement="right"
        onClose={() => setSelectedNode(null)}
        open={!!selectedNode}
        width={320}
      >
        {selectedNode && (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Input
              value={selectedNode.data.label}
              onChange={(e) => {
                const updatedNodes = nodes.map(node =>
                  node.id === selectedNode.id
                    ? { ...node, data: { ...node.data, label: e.target.value } }
                    : node
                );
                setNodes(updatedNodes);
              }}
            />
            {selectedNode.data.type === NODE_TYPES.TABLE && (
              <div>
<Text strong>{t('Fields')}</Text>
                {selectedNode.data.fields?.map((field, index) => (
                  <Space key={index} style={{ marginTop: 8, width: '100%' }}>
                    <Input
                      placeholder={t('Field Name')}
                      value={field.name}
                      onChange={(e) => {
                        const newFields = [...selectedNode.data.fields];
                        newFields[index] = { ...field, name: e.target.value };
                        const updatedNodes = nodes.map(node =>
                          node.id === selectedNode.id
                            ? { ...node, data: { ...node.data, fields: newFields } }
                            : node
                        );
                        setNodes(updatedNodes);
                      }}
                    />
                    <Select
                      value={field.type}
                      style={{ width: 120 }}
                      onChange={(value) => {
                        const newFields = [...selectedNode.data.fields];
                        newFields[index] = { ...field, type: value };
                        const updatedNodes = nodes.map(node =>
                          node.id === selectedNode.id
                            ? { ...node, data: { ...node.data, fields: newFields } }
                            : node
                        );
                        setNodes(updatedNodes);
                      }}
                    >
                      {FIELD_TYPES.map(type => (
                        <Select.Option key={type.value} value={type.value}>
                          {type.label}
                        </Select.Option>
                      ))}
                    </Select>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        const newFields = selectedNode.data.fields.filter((_, i) => i !== index);
                        const updatedNodes = nodes.map(node =>
                          node.id === selectedNode.id
                            ? { ...node, data: { ...node.data, fields: newFields } }
                            : node
                        );
                        setNodes(updatedNodes);
                      }}
                    />
                  </Space>
                ))}
                <Button
                  type="dashed"
                  block
                  icon={<PlusOutlined />}
                  onClick={() => {
                    const newFields = [...(selectedNode.data.fields || []), { name: '', type: 'string' }];
                    const updatedNodes = nodes.map(node =>
                      node.id === selectedNode.id
                        ? { ...node, data: { ...node.data, fields: newFields } }
                        : node
                    );
                    setNodes(updatedNodes);
                  }}
                  style={{ marginTop: 8 }}
                >
                  {t('Add Field')}
                </Button>
              </div>
            )}
            <ColorPicker
              value={selectedNode.data.color}
              onChange={(color) => {
                const updatedNodes = nodes.map(node =>
                  node.id === selectedNode.id
                    ? { ...node, data: { ...node.data, color: color.toHexString() } }
                    : node
                );
                setNodes(updatedNodes);
              }}
            />
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                const updatedNodes = nodes.filter(node => node.id !== selectedNode.id);
                const updatedEdges = edges.filter(
                  edge => edge.source !== selectedNode.id && edge.target !== selectedNode.id
                );
                setNodes(updatedNodes);
                setEdges(updatedEdges);
                setSelectedNode(null);
              }}
            >
              {t('Delete Node')}
            </Button>
          </Space>
        )}
      </Drawer>

      {/* Edge Properties Drawer */}
      <Drawer
        title={t('Edge Properties')}
        placement="right"
        onClose={() => setSelectedEdge(null)}
        open={!!selectedEdge}
        width={320}
      >
        {selectedEdge && (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Select
              style={{ width: '100%' }}
              value={selectedEdge.type || RELATIONSHIP_TYPES.ONE_TO_ONE}
              onChange={(value) => {
                const updatedEdges = edges.map(edge =>
                  edge.id === selectedEdge.id
                    ? { ...edge, type: value }
                    : edge
                );
                setEdges(updatedEdges);
              }}
            >
              {Object.entries(RELATIONSHIP_TYPES).map(([key, value]) => (
                <Select.Option key={key} value={value}>
                  {value}
                </Select.Option>
              ))}
            </Select>
            <Input
              placeholder={t('Label')}
              value={selectedEdge.label}
              onChange={(e) => {
                const updatedEdges = edges.map(edge =>
                  edge.id === selectedEdge.id
                    ? { ...edge, label: e.target.value }
                    : edge
                );
                setEdges(updatedEdges);
              }}
            />
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                const updatedEdges = edges.filter(edge => edge.id !== selectedEdge.id);
                setEdges(updatedEdges);
                setSelectedEdge(null);
              }}
            >
              {t('Delete Edge')}
            </Button>
          </Space>
        )}
      </Drawer>
    </Layout>
    </div>
    </div>
  );
}

export default MindMap;