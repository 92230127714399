import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs, addDoc, doc, updateDoc, deleteDoc, Timestamp, onSnapshot } from 'firebase/firestore';
import { Modal, Input, Button, Form, Select, Table, message, Tag } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { db } from '../../firebase';
import { useTranslation } from 'react-i18next';

const Dealstable = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDeal, setCurrentDeal] = useState(null);
  const [deals, setDeals] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [form] = Form.useForm();
  const { companyId } = useParams();

  useEffect(() => {
    fetchContacts();
  }, [companyId]);

  useEffect(() => {
    fetchDeals();
  }, [companyId]);

  useEffect(() => {
    if (isModalVisible) {
      if (currentDeal) {
        form.setFieldsValue({
          title: currentDeal.title,
          amount: currentDeal.amount,
          stage: currentDeal.stage,
          contactIds: currentDeal.contactIds
        });
      } else {
        form.resetFields();
      }
    }
  }, [currentDeal, isModalVisible, form]);

  function fetchDeals() {
    const dealsRef = collection(db, 'deals');
    const q = query(dealsRef, where('companyId', '==', companyId));
    return onSnapshot(q, (snapshot) => {
      setDeals(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }, (error) => {
      console.error("Error fetching deals: ", error);
      message.error(t("Failed to fetch deals"));
    });
  }

  function fetchContacts() {
    const contactsRef = collection(db, 'contacts');
    const q = query(contactsRef, where('companyId', '==', companyId));
    getDocs(q)
      .then(snapshot => setContacts(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))))
      .catch(error => {
        console.error("Error fetching contacts: ", error);
        message.error(t("Failed to fetch contacts"));
      });
  }

  const showModal = (deal = null) => {
    setCurrentDeal(deal);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentDeal(null);
    form.resetFields();
  };

  const onFormSubmit = async (values) => {
    const collectionRef = collection(db, 'deals');
    const data = {
      ...values,
      companyId: companyId,
      dateCreated: currentDeal ? currentDeal.dateCreated : Timestamp.fromDate(new Date()),
    };
    try {
      if (currentDeal) {
        const docRef = doc(db, 'deals', currentDeal.id);
        await updateDoc(docRef, data);
        message.success(t('Deal updated successfully'));
      } else {
        await addDoc(collectionRef, data);
        message.success(t('Deal added successfully'));
      }
      fetchDeals();
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error('Error saving deal: ', error);
      message.error(t('Failed to save deal'));
    }
  };

  const handleDelete = async (dealId) => {
    try {
      await deleteDoc(doc(db, 'deals', dealId));
      message.success(t('Deal deleted successfully'));
      fetchDeals();
    } catch (error) {
      console.error("Error deleting deal: ", error);
      message.error(t("Error deleting deal"));
    }
  };

  const dealColumns = [
    {
      title: t('Deal Title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('Amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: amount => `$${amount.toLocaleString()}`,
    },
    {
      title: t('Stage'),
      dataIndex: 'stage',
      key: 'stage',
      render: stage => <Tag color={stage === 'Won' ? 'green' : stage === 'Lost' ? 'red' : 'blue'}>{stage}</Tag>,
    },
    {
      title: t('Contacts'),
      dataIndex: 'contactIds',
      key: 'contacts',
      render: contactIds => contactIds?.map(id => {
          const contact = contacts.find(c => c.id === id);
          return contact ? `${contact.name}; ` : 'No contact; ';
        }).join('') || t('Loading contacts...'),
    },
    {
      title: t('Actions'),
      key: 'actions',
      render: (_, record) => (
        <>
          <Button icon={<EditOutlined />} onClick={() => showModal(record)} style={{ marginRight: 8 }} />
          <Button icon={<DeleteOutlined />} onClick={() => handleDelete(record.id)} />
        </>
      ),
    },
  ];

  return (
    <div>
      <Button icon={<PlusOutlined />} onClick={() => showModal()}>{t("Add Deal")}</Button>
      <Table dataSource={deals} columns={dealColumns} rowKey="id" />
      <Modal title={currentDeal ? t("Edit Deal") : t("Add New Deal")} visible={isModalVisible} onCancel={handleCancel} footer={null}>
        <Form onFinish={onFormSubmit} form={form}>
          <Form.Item name="title" label={t("Deal Title")} rules={[{ required: true, message: t('Please input the deal title!') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="amount" label={t("Deal Amount")} rules={[{ required: true, message: t('Please input the deal amount!') }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item name="stage" label={t("Stage")} rules={[{ required: true, message: t('Please select the stage!') }]}>
            <Select>
              <Select.Option value="New">{t("New")}</Select.Option>
              <Select.Option value="Under Review">{t("Under Review")}</Select.Option>
              <Select.Option value="Demo">{t("Demo")}</Select.Option>
              <Select.Option value="Won">{t("Won")}</Select.Option>
              <Select.Option value="Lost">{t("Lost")}</Select.Option>
              <Select.Option value="Unassigned">{t("Unassigned")}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="contactIds" label={t("Contacts")} rules={[{ required: false, message: t('Please select contacts!') }]}>
            <Select mode="multiple">
              {contacts.map(contact => (
                <Select.Option key={contact.id} value={contact.id}>{contact.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button type="primary" htmlType="submit">{currentDeal ? t("Update Deal") : t("Add Deal")}</Button>
        </Form>
      </Modal>
    </div>
  );
};

export default Dealstable;
