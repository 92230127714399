import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Typography, message, Modal } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import { auth, signInWithEmailAndPassword, sendPasswordResetEmail } from '../../firebase';
import logo from './logo.png'; // Make sure to import your logo

const { Title, Text } = Typography;

const Login = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
      if (!userCredential.user.emailVerified) {
        message.error("Please verify your email before logging in.");
        setLoading(false);
        return;
      }
      message.success("Login successful!");
      navigate('/');
    } catch (err) {
      console.error("Error logging in:", err);
      message.error('Invalid email or password.');
    }
    setLoading(false);
  };

  const handleResetPassword = async (values) => {
    try {
      await sendPasswordResetEmail(auth, values.email);
      message.success(`Password reset email sent to ${values.email}`);
      setIsResetModalVisible(false);
    } catch (err) {
      console.error("Error sending password reset email:", err);
      message.error("Failed to send password reset email.");
    }
  };

  return (
    <div style={{
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#f5f5f5',
    }}>
      <div style={{
        width: '100%',
        maxWidth: '360px',
        background: '#ffffff',
        borderRadius: '8px',
        padding: '40px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}>
        <div style={{ textAlign: 'center', marginBottom: '30px' }}>
          <img src={logo} alt="Company Logo" style={{ width: '60px', marginBottom: '16px' }} />
          <Title level={3} style={{ color: '#0077BE', margin: 0 }}>
            Welcome Back
          </Title>
        </div>
        <Form
          form={form}
          name="login"
          onFinish={onFinish}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your Email!' },
              { type: 'email', message: 'Please enter a valid email!' }
            ]}
          >
            <Input 
              prefix={<UserOutlined style={{ color: '#0077BE' }} />} 
              placeholder="Email" 
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: '#0077BE' }} />}
              placeholder="Password"
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <Button type="link" onClick={() => setIsResetModalVisible(true)} style={{ float: 'right', padding: 0, color: '#0077BE' }}>
              Forgot password?
            </Button>
          </Form.Item>
          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              style={{ 
                width: '100%', 
                height: '40px',
                background: '#0077BE',
                borderColor: '#0077BE',
              }} 
              size="large" 
              loading={loading}
            >
              Sign in
            </Button>
          </Form.Item>
        </Form>
        <Text style={{ display: 'block', textAlign: 'center' }}>
          Don't have an account? <Link to="/register" style={{ color: '#0077BE' }}>Sign up here</Link>
        </Text>
      </div>

      <Modal
        title="Reset Password"
        visible={isResetModalVisible}
        onCancel={() => setIsResetModalVisible(false)}
        footer={null}
      >
        <Form onFinish={handleResetPassword}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your Email!' },
              { type: 'email', message: 'Please enter a valid email!' }
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>
          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              style={{ 
                width: '100%',
                background: '#0077BE',
                borderColor: '#0077BE',
              }}
            >
              Send Reset Email
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Login;