import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db, auth } from '../../firebase';
import { collection, query, where, onSnapshot, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Modal, Select, Tooltip, Progress } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Option } = Select;

const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: #f0f2f5;
`;

const Content = styled.div`
  flex: 1;
  padding: 2rem;
  overflow-x: auto;
`;

const BoardContainer = styled.div`
  display: flex;
  gap: 1rem;
  min-height: calc(100vh - 200px);
`;

const Column = styled.div`
  background-color: #f0f0f0;
  border-radius: 8px;
  width: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const ColumnTitle = styled.h3`
  margin-bottom: 1rem;
  color: #1a1a1a;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 0.5rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  cursor: grab;
`;

const CardTitle = styled.h4`
  margin: 0 0 0.5rem 0;
  color: #2c3e50;
`;

const CardMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.8rem;
  color: #7f8c8d;
`;

const ActionButton = styled(Button)`
  margin-left: 0.5rem;
`;

const RoadmapProjects = () => {
  const [projects, setProjects] = useState({
    'To Do': [],
    'In Progress': [],
    'Done': []
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [editingProject, setEditingProject] = useState(null);
  const [newProject, setNewProject] = useState({ name: '', description: '', status: 'To Do', priority: 'Medium' });

  const userId = auth.currentUser.uid;
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, 'projects'), where('userId', '==', userId)),
      (snapshot) => {
        const fetchedProjects = {
          'To Do': [],
          'In Progress': [],
          'Done': []
        };
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          fetchedProjects[data.status].push({
            id: doc.id,
            ...data
          });
        });
        setProjects(fetchedProjects);
      }
    );

    return () => unsubscribe();
  }, [userId]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const startColumn = projects[source.droppableId];
    const finishColumn = projects[destination.droppableId];

    if (startColumn === finishColumn) {
      const newProjects = Array.from(startColumn);
      const [reorderedItem] = newProjects.splice(source.index, 1);
      newProjects.splice(destination.index, 0, reorderedItem);

      const newState = {
        ...projects,
        [source.droppableId]: newProjects,
      };

      setProjects(newState);
    } else {
      const startProjects = Array.from(startColumn);
      const [movedProject] = startProjects.splice(source.index, 1);
      const finishProjects = Array.from(finishColumn);
      finishProjects.splice(destination.index, 0, movedProject);

      const newState = {
        ...projects,
        [source.droppableId]: startProjects,
        [destination.droppableId]: finishProjects,
      };

      setProjects(newState);

      // Update the project status in Firestore
      updateDoc(doc(db, 'projects', draggableId), {
        status: destination.droppableId
      });
    }
  };

  const handleAddProject = async () => {
    await addDoc(collection(db, 'projects'), {
      ...newProject,
      userId,
      createdAt: new Date()
    });
    setModalVisible(false);
    setNewProject({ name: '', description: '', status: 'To Do', priority: 'Medium' });
  };

  const handleEditProject = async () => {
    if (editingProject) {
      await updateDoc(doc(db, 'projects', editingProject.id), newProject);
      setModalVisible(false);
      setEditingProject(null);
      setNewProject({ name: '', description: '', status: 'To Do', priority: 'Medium' });
    }
  };

  const handleDeleteProject = async (projectId) => {
    await deleteDoc(doc(db, 'projects', projectId));
  };

  const openEditModal = (project) => {
    setEditingProject(project);
    setNewProject({ ...project });
    setModalVisible(true);
  };

  return (
    <Container>
      <Content>
        <h1>Project Roadmap</h1>
        <Button 
          type="primary" 
          icon={<PlusOutlined />} 
          onClick={() => setModalVisible(true)}
          style={{ marginBottom: '1rem' }}
        >
          Add New Project
        </Button>
        <DragDropContext onDragEnd={onDragEnd}>
          <BoardContainer>
            {Object.keys(projects).map((columnId) => (
              <Droppable droppableId={columnId} key={columnId}>
                {(provided) => (
                  <Column
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <ColumnTitle>{columnId}</ColumnTitle>
                    {projects[columnId].map((project, index) => (
                      <Draggable key={project.id} draggableId={project.id} index={index}>
                        {(provided) => (
                          <Card
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <CardTitle>{project.name}</CardTitle>
                            <Progress 
                              percent={columnId === 'Done' ? 100 : (columnId === 'In Progress' ? 50 : 0)} 
                              size="small" 
                              status={columnId === 'Done' ? 'success' : 'active'}
                            />
                            <CardMeta>
                              <span>Priority: {project.priority}</span>
                              <div>
                                <Tooltip title="Edit">
                                  <ActionButton 
                                    icon={<EditOutlined />} 
                                    size="small" 
                                    onClick={() => openEditModal(project)} 
                                  />
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <ActionButton 
                                    icon={<DeleteOutlined />} 
                                    size="small" 
                                    onClick={() => handleDeleteProject(project.id)} 
                                  />
                                </Tooltip>
                                <Tooltip title="View Details">
                                  <ActionButton 
                                    icon={<InfoCircleOutlined />} 
                                    size="small"
                                    onClick={() => {/* Implement view details logic */}}
                                  />
                                </Tooltip>
                              </div>
                            </CardMeta>
                          </Card>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Column>
                )}
              </Droppable>
            ))}
          </BoardContainer>
        </DragDropContext>
      </Content>

      <Modal
        title={editingProject ? "Edit Project" : "Add New Project"}
        visible={modalVisible}
        onOk={editingProject ? handleEditProject : handleAddProject}
        onCancel={() => {
          setModalVisible(false);
          setEditingProject(null);
          setNewProject({ name: '', description: '', status: 'To Do', priority: 'Medium' });
        }}
      >
        <Input
          placeholder="Project Name"
          value={newProject.name}
          onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
          style={{ marginBottom: '1rem' }}
        />
        <Input.TextArea
          placeholder="Project Description"
          value={newProject.description}
          onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
          style={{ marginBottom: '1rem' }}
        />
        <Select
          style={{ width: '100%', marginBottom: '1rem' }}
          placeholder="Select Status"
          value={newProject.status}
          onChange={(value) => setNewProject({ ...newProject, status: value })}
        >
          <Option value="To Do">To Do</Option>
          <Option value="In Progress">In Progress</Option>
          <Option value="Done">Done</Option>
        </Select>
        <Select
          style={{ width: '100%' }}
          placeholder="Select Priority"
          value={newProject.priority}
          onChange={(value) => setNewProject({ ...newProject, priority: value })}
        >
          <Option value="Low">Low</Option>
          <Option value="Medium">Medium</Option>
          <Option value="High">High</Option>
        </Select>
      </Modal>
    </Container>
  );
};

export default RoadmapProjects;