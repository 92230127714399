import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { db } from '../../firebase';
import VerticalMenu from '../VerticalMenu';
import { newInstance } from '@jsplumb/browser-ui';
import { v4 as uuidv4 } from 'uuid';
import { 
  Layout, Button, Space, Typography, message, Modal, Form, 
  Input, Select, Tooltip, Switch, Tabs, Card, Col, Row
} from 'antd';
import { 
  ArrowLeftOutlined, SaveOutlined, PlusOutlined, DeleteOutlined,
  UndoOutlined, RedoOutlined, ZoomInOutlined, ZoomOutOutlined,
  LinkOutlined, EditOutlined
} from '@ant-design/icons';
import { SketchPicker } from 'react-color';

const { Header, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const NodeTypes = {
  TASK: 'task',
  DECISION: 'decision',
  DATABASE: 'database',
  START: 'start',
  END: 'end',
};

const nodeColors = {
  [NodeTypes.TASK]: '#3498db',
  [NodeTypes.DECISION]: '#e74c3c',
  [NodeTypes.DATABASE]: '#2ecc71',
  [NodeTypes.START]: '#f39c12',
  [NodeTypes.END]: '#95a5a6',
};

function WhiteboardPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [whiteboard, setWhiteboard] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [jsPlumbInstance, setJsPlumbInstance] = useState(null);
  const [nodeModalVisible, setNodeModalVisible] = useState(false);
  const [currentNode, setCurrentNode] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isGridVisible, setIsGridVisible] = useState(true);
  const canvasRef = useRef(null);
  const nodeRefs = useRef({});
  const [form] = Form.useForm();

  const initializeJsPlumb = useCallback(() => {
    if (canvasRef.current && !jsPlumbInstance) {
      const instance = newInstance({
        container: canvasRef.current,
        connector: { type: 'Flowchart', options: { cornerRadius: 5 } },
        anchor: 'Continuous',
        endpoint: { type: 'Dot', options: { radius: 7 } },
        paintStyle: { stroke: '#6c757d', strokeWidth: 2 },
      });
      setJsPlumbInstance(instance);
    }
  }, [jsPlumbInstance]);

  useEffect(() => {
    initializeJsPlumb();
  }, [initializeJsPlumb]);

  useEffect(() => {
    const fetchWhiteboard = async () => {
      const whiteboardRef = doc(db, 'whiteboards', id);
      const whiteboardSnap = await getDoc(whiteboardRef);
      if (whiteboardSnap.exists()) {
        const data = whiteboardSnap.data();
        setWhiteboard({ id: whiteboardSnap.id, ...data });
        setNodes(data.nodes || []);
      } else {
        message.error(t('Whiteboard not found'));
        navigate('/whiteboards');
      }
    };
    fetchWhiteboard();
  }, [id, navigate, t]);

  useEffect(() => {
    if (jsPlumbInstance) {
      jsPlumbInstance.reset();
      nodes.forEach((node) => {
        if (nodeRefs.current[node.id]) {
          jsPlumbInstance.addEndpoint(nodeRefs.current[node.id], {
            isSource: true,
            isTarget: true,
            connectorStyle: { stroke: '#6c757d', strokeWidth: 2 },
            connectorHoverStyle: { stroke: '#0056b3' },
          });
        }
      });
      jsPlumbInstance.bind('connection', (info) => {
        console.log('Connection made', info);
      });
    }
  }, [jsPlumbInstance, nodes]);

  const handleSave = async () => {
    try {
      const whiteboardRef = doc(db, 'whiteboards', id);
      await updateDoc(whiteboardRef, { 
        nodes,
        connections: jsPlumbInstance.getConnections().map((conn) => ({
          source: conn.source.id,
          target: conn.target.id,
        })),
      });
      message.success(t('Workflow saved successfully'));
    } catch (error) {
      console.error("Error saving workflow: ", error);
      message.error(t('Error saving workflow'));
    }
  };

  const showNodeModal = (node = null) => {
    setCurrentNode(node || { type: NodeTypes.TASK, label: '', color: nodeColors[NodeTypes.TASK] });
    setNodeModalVisible(true);
  };

  const handleNodeModalOk = () => {
    form.validateFields().then((values) => {
      const newNode = {
        id: currentNode.id || `node-${uuidv4()}`,
        ...values,
        left: currentNode.left || 100,
        top: currentNode.top || 100,
      };
      if (currentNode.id) {
        setNodes(nodes.map(node => node.id === currentNode.id ? newNode : node));
      } else {
        setNodes([...nodes, newNode]);
      }
      setNodeModalVisible(false);
      form.resetFields();
    });
  };

  const deleteNode = (id) => {
    setNodes(nodes.filter((node) => node.id !== id));
    if (jsPlumbInstance) {
      jsPlumbInstance.remove(id);
    }
  };

  const handleZoomIn = () => {
    setZoomLevel(Math.min(zoomLevel + 0.1, 2));
  };

  const handleZoomOut = () => {
    setZoomLevel(Math.max(zoomLevel - 0.1, 0.5));
  };

  const toggleGrid = () => {
    setIsGridVisible(!isGridVisible);
  };

  const renderNode = (node) => (
    <div
      key={node.id}
      ref={el => nodeRefs.current[node.id] = el}
      style={{
        position: 'absolute',
        left: node.left,
        top: node.top,
        padding: '10px',
        border: `2px solid ${node.color || nodeColors[node.type]}`,
        borderRadius: '5px',
        background: '#fff',
        cursor: 'move',
      }}
    >
      {node.label}
      <Space style={{ marginLeft: '10px' }}>
        <Button 
          size="small" 
          icon={<EditOutlined />} 
          onClick={() => showNodeModal(node)}
        />
        <Button 
          size="small" 
          icon={<DeleteOutlined />} 
          onClick={() => deleteNode(node.id)}
        />
      </Space>
    </div>
  );

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <VerticalMenu />
      <Layout style={{ minHeight: '100vh', background: '#fff' }}>
        <Header style={{ background: '#fff', padding: '0 24px', boxShadow: '0 2px 8px rgba(0,0,0,0.06)' }}>
          <Space>
            <Button icon={<ArrowLeftOutlined />} onClick={() => navigate('/whiteboards')}>
              {t('Back to Whiteboards')}
            </Button>
            <Title level={3} style={{ margin: 0 }}>{whiteboard?.name}</Title>
          </Space>
        </Header>
        <Content style={{ padding: '24px', display: 'flex', flexDirection: 'column' }}>
          <Space style={{ marginBottom: '16px' }}>
            <Button icon={<PlusOutlined />} onClick={() => showNodeModal()}>{t('Add Node')}</Button>
            <Button icon={<SaveOutlined />} onClick={handleSave} type="primary">{t('Save Workflow')}</Button>
            <Button icon={<ZoomInOutlined />} onClick={handleZoomIn}>{t('Zoom In')}</Button>
            <Button icon={<ZoomOutOutlined />} onClick={handleZoomOut}>{t('Zoom Out')}</Button>
            <Switch 
              checkedChildren={t('Grid On')}
              unCheckedChildren={t('Grid Off')}
              checked={isGridVisible}
              onChange={toggleGrid}
            />
          </Space>
          <div 
            ref={canvasRef}
            style={{ 
              flex: 1,
              border: '1px solid #d9d9d9',
              borderRadius: '8px',
              overflow: 'hidden',
              position: 'relative',
              backgroundImage: isGridVisible ? 'linear-gradient(#ccc 1px, transparent 1px), linear-gradient(90deg, #ccc 1px, transparent 1px)' : 'none',
              backgroundSize: '20px 20px',
              transform: `scale(${zoomLevel})`,
              transformOrigin: '0 0',
            }}
          >
            {nodes.map(renderNode)}
          </div>
        </Content>
      </Layout>
      <Modal
        title={currentNode?.id ? t("Edit Node") : t("Add Node")}
        visible={nodeModalVisible}
        onOk={handleNodeModalOk}
        onCancel={() => setNodeModalVisible(false)}
      >
        <Form form={form} initialValues={currentNode}>
          <Form.Item name="type" label={t("Node Type")} rules={[{ required: true }]}>
            <Select>
              <Option value={NodeTypes.TASK}>{t("Task")}</Option>
              <Option value={NodeTypes.DECISION}>{t("Decision")}</Option>
              <Option value={NodeTypes.DATABASE}>{t("Database")}</Option>
              <Option value={NodeTypes.START}>{t("Start")}</Option>
              <Option value={NodeTypes.END}>{t("End")}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="label" label={t("Label")} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="color" label={t("Color")}>
            <SketchPicker />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default WhiteboardPage;