import React, { useState, useEffect } from 'react';
import { Typography, Button } from 'antd';
import { Link } from 'react-router-dom';
import { HomeFilled, BugFilled } from '@ant-design/icons';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const { Title, Text } = Typography;

const PageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f0f2f5;
  overflow: hidden;
  position: relative;
`;

const GameArea = styled.div`
  width: 300px;
  height: 200px;
  border: 2px solid #1890ff;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
`;

const Bug = styled(motion.div)`
  position: absolute;
  cursor: pointer;
`;

const Score = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  font-weight: bold;
`;

const NotFound = () => {
  const [position, setPosition] = useState({ x: 150, y: 100 });
  const [score, setScore] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition({
        x: Math.random() * 260 + 20,
        y: Math.random() * 160 + 20,
      });
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  const catchBug = () => {
    setScore(score + 1);
  };

  return (
    <PageContainer>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Title style={{ fontSize: '120px', margin: 0, color: '#1890ff' }}>404</Title>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Title level={2} style={{ margin: '0 0 20px 0' }}>Oops! You've caught a bug!</Title>
      </motion.div>
      <Text style={{ fontSize: '18px', marginBottom: '30px' }}>
        While we fix it, why not try to catch some more?
      </Text>
      <GameArea>
        <Score>Score: {score}</Score>
        <Bug
          animate={position}
          transition={{ type: 'spring', damping: 10 }}
          onClick={catchBug}
        >
          <BugFilled style={{ fontSize: '24px', color: '#1890ff' }} />
        </Bug>
      </GameArea>
      <Link to="/">
        <Button type="primary" icon={<HomeFilled />} size="large">
          Back to Home
        </Button>
      </Link>
    </PageContainer>
  );
};

export default NotFound;