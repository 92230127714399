import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, doc, getDoc, addDoc, updateDoc } from 'firebase/firestore';
import { 
  Layout, Typography, Form, Input, Button, DatePicker, InputNumber, 
  Space, message, Row, Col, Card, Divider, Table, Select
} from 'antd';
import { DeleteOutlined, PlusOutlined, DownloadOutlined, SaveOutlined } from '@ant-design/icons';
import VerticalMenu from '../VerticalMenu';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import jsPDF from 'jspdf';

const { Content } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const Invoice = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [invoice, setInvoice] = useState(null);
  const [company, setCompany] = useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchInvoice = async () => {
      if (invoiceId === 'new') {
        const searchParams = new URLSearchParams(location.search);
        const companyId = searchParams.get('companyId');
        if (companyId) {
          const companyDoc = await getDoc(doc(db, 'companies', companyId));
          if (companyDoc.exists()) {
            setCompany({ id: companyDoc.id, ...companyDoc.data() });
          }
        }
      } else {
        const invoiceDoc = await getDoc(doc(db, 'invoices', invoiceId));
        if (invoiceDoc.exists()) {
          const invoiceData = { id: invoiceDoc.id, ...invoiceDoc.data() };
          setInvoice(invoiceData);
          setItems(invoiceData.items || []);
          form.setFieldsValue({
            ...invoiceData,
            date: invoiceData.date ? moment(invoiceData.date.toDate()) : null,
            dueDate: invoiceData.dueDate ? moment(invoiceData.dueDate.toDate()) : null,
          });
          const companyDoc = await getDoc(doc(db, 'companies', invoiceData.companyId));
          if (companyDoc.exists()) {
            setCompany({ id: companyDoc.id, ...companyDoc.data() });
          }
        }
      }
    };

    fetchInvoice();
  }, [invoiceId, location.search, form]);

  const onFinish = async (values) => {
    try {
      const invoiceData = {
        ...values,
        companyId: company.id,
        items: items,
        totalAmount: calculateTotal(items),
        status: 'Unpaid',
        createdAt: new Date(),
        date: values.date?.toDate(),
        dueDate: values.dueDate?.toDate(),
      };

      if (invoiceId === 'new') {
        await addDoc(collection(db, 'invoices'), invoiceData);
        message.success(t('Invoice created successfully'));
      } else {
        await updateDoc(doc(db, 'invoices', invoiceId), invoiceData);
        message.success(t('Invoice updated successfully'));
      }

      navigate(`/companies/${company.id}`);
    } catch (error) {
      console.error('Error saving invoice: ', error);
      message.error(t('Error saving invoice'));
    }
  };

  const calculateTotal = (items) => {
    return items.reduce((sum, item) => sum + (Number(item.quantity || 0) * Number(item.unitPrice || 0)), 0);
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;

    // Add invoice title
    doc.setFontSize(20);
    doc.text(form.getFieldValue('title') || 'INVOICE', pageWidth / 2, 20, { align: 'center' });

    // Add seller's information
    doc.setFontSize(12);
    doc.text('From:', 10, 40);
    doc.text(form.getFieldValue('sellerName') || '', 10, 50);
    doc.text(form.getFieldValue('sellerAddress') || '', 10, 60);
    doc.text(form.getFieldValue('sellerEmail') || '', 10, 70);
    doc.text(form.getFieldValue('sellerPhone') || '', 10, 80);

    // Add buyer's information
    doc.text('Bill To:', pageWidth - 90, 40);
    doc.text(company?.name || '', pageWidth - 90, 50);
    doc.text(company?.address || '', pageWidth - 90, 60);
    doc.text(company?.email || '', pageWidth - 90, 70);
    doc.text(company?.phone || '', pageWidth - 90, 80);

    // Add invoice details
    doc.text(`Invoice Number: ${form.getFieldValue('invoiceNumber') || ''}`, 10, 100);
    doc.text(`Date: ${form.getFieldValue('date')?.format('YYYY-MM-DD') || ''}`, 10, 110);
    doc.text(`Due Date: ${form.getFieldValue('dueDate')?.format('YYYY-MM-DD') || ''}`, 10, 120);
    doc.text(`Payment Terms: ${form.getFieldValue('paymentTerms') || ''}`, 10, 130);

    // Add invoice items
    let yPosition = 150;
    doc.text('Description', 10, yPosition);
    doc.text('Quantity', 100, yPosition);
    doc.text('Unit Price', 130, yPosition);
    doc.text('Total', 170, yPosition);
    yPosition += 10;

    items.forEach((item) => {
      if (item && item.description) {
        doc.text(item.description.toString(), 10, yPosition);
        doc.text((item.quantity || '').toString(), 100, yPosition);
        doc.text(`$${Number(item.unitPrice || 0).toFixed(2)}`, 130, yPosition);
        doc.text(`$${((Number(item.quantity || 0) * Number(item.unitPrice || 0)).toFixed(2))}`, 170, yPosition);
        yPosition += 10;

        if (yPosition > pageHeight - 50) {
          doc.addPage();
          yPosition = 20;
        }
      }
    });

    // Add total
    yPosition += 10;
    doc.text(`Subtotal: $${calculateTotal(items).toFixed(2)}`, 130, yPosition);
    yPosition += 10;
    const taxRate = Number(form.getFieldValue('taxRate') || 0);
    const taxAmount = calculateTotal(items) * (taxRate / 100);
    doc.text(`Tax (${taxRate}%): $${taxAmount.toFixed(2)}`, 130, yPosition);
    yPosition += 10;
    doc.text(`Total: $${(calculateTotal(items) + taxAmount).toFixed(2)}`, 130, yPosition);

    // Add notes
    yPosition += 20;
    doc.text('Notes:', 10, yPosition);
    doc.setFontSize(10);
    const notes = form.getFieldValue('notes') || '';
    doc.text(notes, 10, yPosition + 10, { maxWidth: pageWidth - 20 });

    // Add terms and conditions
    yPosition += 40;
    doc.setFontSize(12);
    doc.text('Terms and Conditions:', 10, yPosition);
    doc.setFontSize(10);
    const terms = form.getFieldValue('termsAndConditions') || '';
    doc.text(terms, 10, yPosition + 10, { maxWidth: pageWidth - 20 });

    // Save the PDF
    doc.save('invoice.pdf');
  };

  const columns = [
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      render: (text, record, index) => (
        <Form.Item
          name={['items', index, 'description']}
          rules={[{ required: true, message: t('Please input the description') }]}
        >
          <TextArea 
            rows={2} 
            style={{ width: 300 }} 
            placeholder={t('Item description')} 
            onChange={(e) => handleItemChange(index, 'description', e.target.value)}
          />
        </Form.Item>
      ),
    },
    {
      title: t('Quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text, record, index) => (
        <Form.Item
          name={['items', index, 'quantity']}
          rules={[{ required: true, message: t('Please input the quantity') }]}
        >
          <InputNumber 
            min={1} 
            placeholder={t('Qty')} 
            onChange={(value) => handleItemChange(index, 'quantity', value)}
          />
        </Form.Item>
      ),
    },
    {
      title: t('Unit Price'),
      dataIndex: 'unitPrice',
      key: 'unitPrice',
      render: (text, record, index) => (
        <Form.Item
          name={['items', index, 'unitPrice']}
          rules={[{ required: true, message: t('Please input the unit price') }]}
        >
          <InputNumber 
            min={0} 
            step={0.01} 
            precision={2} 
            placeholder={t('Price')}
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            onChange={(value) => handleItemChange(index, 'unitPrice', value)}
          />
        </Form.Item>
      ),
    },
    {
      title: t('Total'),
      key: 'total',
      render: (_, record) => {
        return `$${((Number(record.quantity || 0) * Number(record.unitPrice || 0)).toFixed(2))}`;
      },
    },
    {
      title: t('Action'),
      key: 'action',
      render: (_, record, index) => (
        <Button onClick={() => handleRemoveItem(index)} icon={<DeleteOutlined />} />
      ),
    },
  ];

  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index] = { ...newItems[index], [field]: value };
    setItems(newItems);
    form.setFieldsValue({ items: newItems });
  };

  const handleAddItem = () => {
    const newItems = [...items, { description: '', quantity: 1, unitPrice: 0 }];
    setItems(newItems);
    form.setFieldsValue({ items: newItems });
  };

  const handleRemoveItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
    form.setFieldsValue({ items: newItems });
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <VerticalMenu />
      <div style={{ flex: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
        <Layout style={{ minHeight: '100%', background: '#f0f2f5' }}>
          <Content style={{ margin: '24px 16px', overflow: 'initial' }}>
            <Card>
              <Row justify="space-between" align="middle">
                <Col>
                  <Title level={2}>{invoiceId === 'new' ? t('Create New Invoice') : t('Edit Invoice')}</Title>
                </Col>
                <Col>
                  <Space>
                    <Button type="primary" icon={<SaveOutlined />} onClick={() => form.submit()}>
                      {invoiceId === 'new' ? t('Create Invoice') : t('Update Invoice')}
                    </Button>
                    <Button icon={<DownloadOutlined />} onClick={generatePDF}>
                      {t('Download PDF')}
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Divider />
              <Form
                form={form}
                name="invoice"
                onFinish={onFinish}
                initialValues={invoice || {}}
                layout="vertical"
              >
                <Row gutter={24}>
                  <Col span={12}>
                    <Card title={t('Invoice Details')} bordered={false}>
                      <Form.Item name="title" label={t('Invoice Title')} rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name="invoiceNumber" label={t('Invoice Number')} rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name="date" label={t('Invoice Date')} rules={[{ required: true }]}>
                        <DatePicker style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item name="dueDate" label={t('Due Date')} rules={[{ required: true }]}>
                        <DatePicker style={{ width: '100%' }} />
                      </Form.Item>
                      <Form.Item name="paymentTerms" label={t('Payment Terms')} rules={[{ required: true }]}>
                        <Select>
                          <Option value="Due on Receipt">Due on Receipt</Option>
                          <Option value="Net 15">Net 15</Option>
                          <Option value="Net 30">Net 30</Option>
                          <Option value="Net 60">Net 60</Option>
                        </Select>
                      </Form.Item>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card title={t('Your Information')} bordered={false}>
                      <Form.Item name="sellerName" label={t('Your Name/Company Name')} rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name="sellerAddress" label={t('Your Address')} rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name="sellerEmail" label={t('Your Email')} rules={[{ required: true, type: 'email' }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name="sellerPhone" label={t('Your Phone')} rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                    </Card>
                  </Col>
                </Row>
                
                <Card title={t('Client Information')} style={{ marginTop: 24 }} bordered={false}>
                <Text strong>{company?.name}</Text>
                  <br />
                  <Text>{company?.address}</Text>
                  <br />
                  <Text>{company?.email}</Text>
                  <br />
                  <Text>{company?.phone}</Text>
                </Card>
                
                <Card title={t('Invoice Items')} style={{ marginTop: 24 }}>
                  <Table
                    dataSource={items}
                    columns={columns}
                    pagination={false}
                    rowKey={(record, index) => index}
                  />
                  <Button 
                    type="dashed" 
                    onClick={handleAddItem} 
                    block 
                    icon={<PlusOutlined />} 
                    style={{ marginTop: 16 }}
                  >
                    {t('Add Item')}
                  </Button>
                </Card>
                
                <Row gutter={24} style={{ marginTop: 24 }}>
                  <Col span={12}>
                    <Card title={t('Additional Information')} bordered={false}>
                      <Form.Item name="notes" label={t('Notes')}>
                        <TextArea rows={4} />
                      </Form.Item>
                      <Form.Item name="termsAndConditions" label={t('Terms and Conditions')}>
                        <TextArea rows={4} />
                      </Form.Item>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card title={t('Totals')} bordered={false}>
                      <Form.Item name="taxRate" label={t('Tax Rate (%)')}>
                        <InputNumber 
                          min={0} 
                          max={100} 
                          formatter={value => `${value}%`}
                          parser={value => value.replace('%', '')}
                        />
                      </Form.Item>
                      <Row justify="space-between">
                        <Col>
                          <Text strong>{t('Subtotal')}:</Text>
                        </Col>
                        <Col>
                          <Text>${calculateTotal(items).toFixed(2)}</Text>
                        </Col>
                      </Row>
                      <Row justify="space-between">
                        <Col>
                          <Text strong>{t('Tax')}:</Text>
                        </Col>
                        <Col>
                          <Text>${(calculateTotal(items) * (form.getFieldValue('taxRate') || 0) / 100).toFixed(2)}</Text>
                        </Col>
                      </Row>
                      <Divider />
                      <Row justify="space-between">
                        <Col>
                          <Text strong>{t('Total')}:</Text>
                        </Col>
                        <Col>
                          <Text strong>
                            ${(calculateTotal(items) * (1 + (form.getFieldValue('taxRate') || 0) / 100)).toFixed(2)}
                          </Text>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Form.Item style={{ marginTop: 24 }}>
                  <Button type="primary" htmlType="submit" block>
                    {invoiceId === 'new' ? t('Create Invoice') : t('Update Invoice')}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Content>
        </Layout>
      </div>
    </div>
  );
};

export default Invoice;