import { initializeApp } from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserLocalPersistence,
  updateProfile,

  sendEmailVerification,
  sendPasswordResetEmail

} from 'firebase/auth';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBDdrCehDsAVr8wlKwhwTPeWOuEs1swVc8",
  authDomain: "memz-3ad93.firebaseapp.com",
  projectId: "memz-3ad93",
  storageBucket: "memz-3ad93.appspot.com",
  messagingSenderId: "170154628180",
  appId: "1:170154628180:web:ec854a18b0b16265c0c9c1",
  measurementId: "G-SZM3CRHMK3"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Initialize Firestore
const db = getFirestore(app);

export {
  auth,
  db,  // Exporting the Firestore instance
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  sendEmailVerification,
  browserLocalPersistence,
  updateProfile,
  sendPasswordResetEmail,
  
};
