import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, onSnapshot, addDoc, doc, updateDoc, deleteDoc, query, where, setDoc, getDoc, getDocs } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { db, auth } from '../../firebase';
import VerticalMenu from '../VerticalMenu';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Modal, Input, Button, Card, Tag, Space, Typography, Checkbox, 
  Progress, Popover, Row, Col, AutoComplete, Divider, Select, message,
  Layout, Dropdown, Menu
} from 'antd';
import { 
  PlusOutlined, ArrowLeftOutlined, EditOutlined, 
  DeleteOutlined, MoreOutlined, FilterOutlined, SortAscendingOutlined,
  ClockCircleOutlined, CheckCircleOutlined
} from '@ant-design/icons';

const { Text, Title } = Typography;
const { Header, Content } = Layout;
const { Option } = Select;

const LABEL_COLORS = [
  "#FF5733", "#33FF57", "#3357FF", "#F333FF", "#FFBD33", "#33FFBD", "#5733FF", 
  "#FF33A1", "#33D4FF", "#FF8F33", "#33FF6B", "#A133FF", "#FF335E", "#33FFD4", 
  "#338AFF", "#FFD433"
];

const PRIORITY_COLORS = {
  High: '#ff4d4f',
  Normal: '#faad14',
  Low: '#52c41a',
};

const PHASES = ['To Do', 'In Progress', 'Done'];
const PRIORITIES = ['Low', 'Normal', 'High'];

function AllTasksPage() {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [features, setFeatures] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskDetails, setTaskDetails] = useState({
    title: '',
    description: '',
    labels: [],
    priority: 'Normal',
    phase: 'To Do',
    checklist: [],
    featureId: ''
  });
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [allLabels, setAllLabels] = useState([]);
  const [newLabel, setNewLabel] = useState({ text: '', color: LABEL_COLORS[0] });
  const [filterPhase, setFilterPhase] = useState('All');
  const [filterPriority, setFilterPriority] = useState('All');
  const [filterFeature, setFilterFeature] = useState('All');
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const userId = auth.currentUser.uid;

  useEffect(() => {
    const fetchTasks = () => {
      const tasksRef = collection(db, 'tasks');
      const q = query(tasksRef, where('projectId', '==', projectId));
      return onSnapshot(q, snapshot => {
        const fetchedTasks = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTasks(fetchedTasks);
      });
    };

    const fetchFeatures = async () => {
      const featuresRef = collection(db, 'features');
      const q = query(featuresRef, where('projectId', '==', projectId));
      const snapshot = await getDocs(q);
      const fetchedFeatures = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setFeatures(fetchedFeatures);
    };

    const fetchAllLabels = async () => {
      const labelsQuery = query(collection(db, 'labels'), where('userId', '==', userId));
      const labelsSnap = await getDocs(labelsQuery);
      const labels = labelsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAllLabels(labels);
    };

    const unsubscribeTasks = fetchTasks();
    fetchFeatures();
    fetchAllLabels();

    return () => unsubscribeTasks();
  }, [projectId, userId]);

  const resetTaskDetails = () => {
    setTaskDetails({
      title: '',
      description: '',
      labels: [],
      priority: 'Normal',
      phase: 'To Do',
      checklist: [],
      featureId: ''
    });
  };

  const handleAddTask = async () => {
    if (taskDetails.title.trim() && taskDetails.description.trim() && taskDetails.featureId) {
      const newTask = { 
        ...taskDetails, 
        labels: taskDetails.labels || [],
        checklist: taskDetails.checklist || [],
        userId, 
        createdAt: new Date(),
        projectId 
      };
      await addDoc(collection(db, 'tasks'), newTask);
      await logActivity('Task added', `Added ${taskDetails.title}`);
      resetTaskDetails();
      setTaskModalVisible(false);
      message.success(t('Task added successfully'));
    } else {
      message.error(t('Please fill in all required fields'));
    }
  };

  const handleUpdateTask = async () => {
    if (selectedTask && taskDetails.title.trim() && taskDetails.description.trim() && taskDetails.featureId) {
      const taskRef = doc(db, 'tasks', selectedTask.id);
      await updateDoc(taskRef, taskDetails);
      await logActivity('Task updated', `Updated ${taskDetails.title}`);
      setTaskModalVisible(false);
      message.success(t('Task updated successfully'));
    } else {
      message.error(t('Please fill in all required fields'));
    }
  };

  const handleArchiveTask = async (task) => {
    const taskToArchive = task || selectedTask;
    if (taskToArchive) {
      const archiveRef = doc(db, 'archive', taskToArchive.id);
      await setDoc(archiveRef, { ...taskToArchive, archivedAt: new Date().toISOString() });
      await deleteDoc(doc(db, 'tasks', taskToArchive.id));
      await logActivity('Task archived', `Archived ${taskToArchive.title}`);
      setSelectedTask(null);
      setTaskModalVisible(false);
      message.success(t('Task archived successfully'));
    }
  };

  const logActivity = async (type, details) => {
    const activity = {
      type,
      details,
      timestamp: new Date(),
      userId,
      projectId
    };
    await addDoc(collection(db, 'activities'), activity);
  };

  const showTaskModal = (task = null) => {
    setSelectedTask(task);
    if (task) {
      setTaskDetails({
        ...task,
        checklist: task.checklist || []
      });
    } else {
      resetTaskDetails();
    }
    setTaskModalVisible(true);
  };

  const handleTaskDetailsChange = (field, value) => {
    setTaskDetails(prev => ({ ...prev, [field]: value }));
  };

  const handleChecklistChange = (index, field, value) => {
    const newChecklist = [...taskDetails.checklist];
    newChecklist[index] = { ...newChecklist[index], [field]: value };
    handleTaskDetailsChange('checklist', newChecklist);
  };

  const addChecklistItem = () => {
    handleTaskDetailsChange('checklist', [...taskDetails.checklist, { text: '', checked: false }]);
  };

  const removeChecklistItem = (index) => {
    const newChecklist = taskDetails.checklist.filter((_, i) => i !== index);
    handleTaskDetailsChange('checklist', newChecklist);
  };

  const handleAddLabel = async () => {
    if (newLabel.text.trim()) {
      const labelRef = doc(collection(db, 'labels'), newLabel.text);
      await setDoc(labelRef, { ...newLabel, userId });
      setAllLabels(prev => [...prev, { ...newLabel, userId }]);
      handleTaskDetailsChange('labels', [...taskDetails.labels, newLabel]);
      setNewLabel({ text: '', color: LABEL_COLORS[0] });
      message.success(t('Label added successfully'));
    }
  };

  const removeLabel = (labelToRemove) => {
    const updatedLabels = taskDetails.labels.filter(label => label.text !== labelToRemove.text);
    handleTaskDetailsChange('labels', updatedLabels);
  };

  const filteredAndSortedTasks = useMemo(() => {
    return tasks
      .filter(task => 
        (filterPhase === 'All' || task.phase === filterPhase) &&
        (filterPriority === 'All' || task.priority === filterPriority) &&
        (filterFeature === 'All' || task.featureId === filterFeature) &&
        (task.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
         task.description.toLowerCase().includes(searchTerm.toLowerCase()))
      )
      .sort((a, b) => {
        if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
  }, [tasks, filterPhase, filterPriority, filterFeature, searchTerm, sortBy, sortOrder]);

  const taskList = useMemo(() => (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', justifyContent: 'flex-start' }}>
      <AnimatePresence>
        {filteredAndSortedTasks.map(task => (
          <motion.div
            key={task.id}
            layout
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
            style={{ width: 'calc(25% - 12px)', minWidth: '250px', marginBottom: '16px' }}
          >
            <Card
              hoverable
              onClick={() => showTaskModal(task)}
              style={{ 
                width: '100%',
                height: '100%',
                borderRadius: 8, 
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column'
              }}
              bodyStyle={{ 
                padding: 16, 
                flex: 1,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Space direction="vertical" size="small" style={{ width: '100%', height: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text strong style={{ fontSize: 18, maxWidth: 'calc(100% - 32px)' }} ellipsis>{task.title}</Text>
                    <Dropdown overlay={
                      <Menu>
                        <Menu.Item key="1" icon={<EditOutlined />} onClick={(e) => { e.stopPropagation(); showTaskModal(task); }}>
                          {t("Edit")}
                        </Menu.Item>
                        <Menu.Item key="2" icon={<DeleteOutlined />} onClick={(e) => { e.stopPropagation(); handleArchiveTask(task); }}>
                          {t("Archive")}
                        </Menu.Item>
                      </Menu>
                    }>
                      <Button icon={<MoreOutlined />} type="text" onClick={e => e.stopPropagation()} />
                    </Dropdown>
                  </div>
                  <Text type="secondary" ellipsis={{ rows: 2 }}>{task.description}</Text>
                  <div style={{ flex: 1, overflow: 'auto' }}>
                    <Space wrap style={{ marginTop: 8 }}>
                      {task.labels && task.labels.map((label, index) => (
                        <Tag key={index} color={label.color} style={{ borderRadius: 12, margin: '0 4px 4px 0' }}>
                          {label.text}
                        </Tag>
                      ))}
                    </Space>
                  </div>
                  <Space style={{ marginTop: 'auto' }}>
                    <Tag color={PRIORITY_COLORS[task.priority]} style={{ borderRadius: 12 }}>{task.priority}</Tag>
                    <Tag icon={task.phase === 'Done' ? <CheckCircleOutlined /> : <ClockCircleOutlined />} style={{ borderRadius: 12 }}>
                      {task.phase}
                    </Tag>
                    <Tag color="blue" style={{ borderRadius: 12 }}>
                      {features.find(f => f.id === task.featureId)?.name || 'Unknown Feature'}
                    </Tag>
                  </Space>
                  <Progress 
                    percent={Math.round((task.checklist?.filter(item => item.checked).length || 0) / (task.checklist?.length || 1) * 100)}
                    size="small"
                    status="active"
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }}
                  />
                </Space>
              </div>
            </Card>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  ), [filteredAndSortedTasks, features]);

  const labelContent = (
    <div style={{ maxWidth: '300px' }}>
      <AutoComplete
        style={{ width: '100%', marginBottom: '10px' }}
        options={allLabels.map(label => ({ value: label.text }))}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onSelect={(value) => {
          const selectedLabel = allLabels.find(label => label.text === value);
          if (selectedLabel && !taskDetails.labels.some(l => l.text === selectedLabel.text)) {
            handleTaskDetailsChange('labels', [...taskDetails.labels, selectedLabel]);
          }
        }}
        placeholder={t("Search or create new label")}
      />
      <Divider />
      <Input
        placeholder={t("New label name")}
        value={newLabel.text}
        onChange={(e) => setNewLabel(prev => ({ ...prev, text: e.target.value }))}
        style={{ marginBottom: '10px' }}
      />
      <Row gutter={[8, 8]}>
        {LABEL_COLORS.map(color => (
          <Col span={6} key={color}>
            <div
              onClick={() => setNewLabel(prev => ({ ...prev, color }))}
              style={{
                backgroundColor: color,
                height: '20px',
                cursor: 'pointer',
                border: newLabel.color === color ? '2px solid #000' : 'none',
                borderRadius: '4px'
              }}
            />
          </Col>
        ))}
      </Row>
      <Button onClick={handleAddLabel} style={{ marginTop: '10px', width: '100%' }}>
        {t("Add Label")}
      </Button>
    </div>
  );

  return (
    <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f5f5f5' }}>
      <VerticalMenu />
      <div style={{ flex: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
        <Layout style={{ minHeight: '100vh', background: '#f0f2f5' }}>
          <Header style={{ background: '#fff', padding: '0 24px', boxShadow: '0 2px 8px rgba(0,0,0,0.06)' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
              <Space>
                <Button type="link" icon={<ArrowLeftOutlined />} onClick={() => navigate(`/projects/${projectId}/features`)}>
                  {t("Back to Project")}
                </Button>
                <Title level={4} style={{ margin: 0 }}>{t("All Tasks")}</Title>
              </Space>
              <Space>
                <Input.Search
                  placeholder={t("Search tasks")}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ width: 200 }}
                />
                <Select 
                  style={{ width: 120 }} 
                  value={filterPhase}
                  onChange={setFilterPhase}
                  placeholder={t("Filter Phase")}
                >
                  <Option value="All">{t("All Phases")}</Option>
                  {PHASES.map(phase => (
                    <Option key={phase} value={phase}>{t(phase)}</Option>
                  ))}
                </Select>
                <Select 
                  style={{ width: 120 }} 
                  value={filterPriority}
                  onChange={setFilterPriority}
                  placeholder={t("Filter Priority")}
                >
                  <Option value="All">{t("All Priorities")}</Option>
                  {PRIORITIES.map(priority => (
                    <Option key={priority} value={priority}>{t(priority)}</Option>
                  ))}
                </Select>
                <Select 
                  style={{ width: 120 }} 
                  value={filterFeature}
                  onChange={setFilterFeature}
                  placeholder={t("Filter Feature")}
                >
                  <Option value="All">{t("All Features")}</Option>
                  {features.map(feature => (
                    <Option key={feature.id} value={feature.id}>{feature.name}</Option>
                  ))}
                </Select>
                <Select 
                  style={{ width: 120 }} 
                  value={sortBy}
                  onChange={setSortBy}
                  placeholder={t("Sort By")}
                >
                  <Option value="createdAt">{t("Creation Date")}</Option>
                  <Option value="title">{t("Title")}</Option>
                  <Option value="priority">{t("Priority")}</Option>
                </Select>
                <Button 
                  icon={sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortAscendingOutlined rotate={180} />} 
                  onClick={() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}
                />
                <Button type="primary" icon={<PlusOutlined />} onClick={() => showTaskModal()}>
                  {t("Add Task")}
                </Button>
              </Space>
            </div>
          </Header>
          <Content style={{ padding: '24px', maxWidth: '100%', margin: '0 auto' }}>
            {taskList}
          </Content>

          <Modal
            title={selectedTask ? t("Edit Task") : t("Add Task")}
            visible={taskModalVisible}
            onOk={selectedTask ? handleUpdateTask : handleAddTask}
            onCancel={() => setTaskModalVisible(false)}
            width={700}
            bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
          >
            <Space direction="vertical" style={{ width: '100%' }} size="middle">
              <Input
                placeholder={t("Task Title")}
                value={taskDetails.title}
                onChange={(e) => handleTaskDetailsChange('title', e.target.value)}
              />
              <Input.TextArea
                placeholder={t("Task Description")}
                value={taskDetails.description}
                onChange={(e) => handleTaskDetailsChange('description', e.target.value)}
                rows={4}
              />
              <Select
                style={{ width: '100%' }}
                value={taskDetails.priority}
                onChange={(value) => handleTaskDetailsChange('priority', value)}
              >
                {PRIORITIES.map(priority => (
                  <Option key={priority} value={priority}>{t(priority)}</Option>
                ))}
              </Select>
              <Select
                style={{ width: '100%' }}
                value={taskDetails.phase}
                onChange={(value) => handleTaskDetailsChange('phase', value)}
              >
                {PHASES.map(phase => (
                  <Option key={phase} value={phase}>{t(phase)}</Option>
                ))}
              </Select>
              <Select
                style={{ width: '100%' }}
                value={taskDetails.featureId}
                onChange={(value) => handleTaskDetailsChange('featureId', value)}
              >
                {features.map(feature => (
                  <Option key={feature.id} value={feature.id}>{feature.name}</Option>
                ))}
              </Select>

              <Popover
                content={labelContent}
                title={t("Manage Labels")}
                trigger="click"
              >
                <Button type="dashed" style={{ width: '100%' }}>
                  <PlusOutlined /> {t("Manage Labels")}
                </Button>
              </Popover>
              <Space wrap>
                {taskDetails.labels.map((label, index) => (
                  <Tag 
                    key={index} 
                    color={label.color}
                    closable
                    onClose={() => removeLabel(label)}
                    style={{ borderRadius: 12 }}
                  >
                    {label.text}
                  </Tag>
                ))}
              </Space>

              <Title level={5}>{t("Checklist")}</Title>
              <Progress 
                percent={Math.round((taskDetails.checklist?.filter(item => item.checked).length || 0) / (taskDetails.checklist?.length || 1) * 100)}
                size="small"
                status="active"
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
              />
              {taskDetails.checklist.map((item, index) => (
                <Space key={index} style={{ width: '100%' }}>
                  <Checkbox
                    checked={item.checked}
                    onChange={(e) => handleChecklistChange(index, 'checked', e.target.checked)}
                  />
                  <Input
                    value={item.text}
                    onChange={(e) => handleChecklistChange(index, 'text', e.target.value)}
                    style={{ width: '100%' }}
                  />
                  <Button 
                    icon={<DeleteOutlined />} 
                    onClick={() => removeChecklistItem(index)}
                  />
                </Space>
              ))}
              <Button onClick={addChecklistItem} icon={<PlusOutlined />}>
                {t("Add Checklist Item")}
              </Button>

              {selectedTask && (
                <Button danger onClick={() => handleArchiveTask(selectedTask)}>
                  {t("Archive Task")}
                </Button>
              )}
            </Space>
          </Modal>
        </Layout>
      </div>
    </div>
  );
}

export default AllTasksPage;