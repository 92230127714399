import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import DealCard from './DealCard';

function Dealcolumn({ dealcolumnId, title, dealIds, deals }) {
  const { isOver, setNodeRef } = useDroppable({ id: dealcolumnId });

  const totalAmount = dealIds.reduce((sum, id) => {
    const dealAmount = parseFloat(deals[id]?.amount) || 0;
    return sum + dealAmount;
  }, 0);

  const formatAmount = (amount) => {
    if (amount >= 1000000) {
      return `$${(amount / 1000000).toFixed(1)}M`;
    }
    if (amount >= 1000) {
      return `$${(amount / 1000).toFixed(1)}K`;
    }
    return `$${amount.toFixed(0)}`;
  };

  const columnStyle = {
    width: '280px',
    minWidth: '280px',
    height: 'calc(100vh - 180px)',
    margin: '0 8px',
    background: '#f0f2f5',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    transition: 'background-color 0.2s ease',
  };

  const headerStyle = {
    padding: '12px 16px',
    background: '#fff',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    borderBottom: '1px solid #e8e8e8',
  };

  const titleStyle = {
    fontSize: '14px',
    fontWeight: '600',
    color: '#1a1a1a',
    marginBottom: '4px',
  };

  const statsStyle = {
    fontSize: '12px',
    color: '#8c8c8c',
  };

  const listStyle = {
    padding: '8px',
    overflowY: 'auto',
    flex: 1,
  };

  const emptyStateStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: '#8c8c8c',
    fontSize: '14px',
    textAlign: 'center',
    padding: '16px',
  };

  return (
    <div ref={setNodeRef} style={columnStyle}>
      <div style={headerStyle}>
        <div style={titleStyle}>{title}</div>
        <div style={statsStyle}>
          {dealIds.length} deals • {formatAmount(totalAmount)}
        </div>
      </div>
      <div style={listStyle}>
        <SortableContext items={dealIds} strategy={verticalListSortingStrategy}>
          {dealIds.length > 0 ? (
            dealIds.map((dealId) => (
              <DealCard key={dealId} id={dealId} {...deals[dealId]} />
            ))
          ) : (
            <div style={emptyStateStyle}>
              No deals yet.<br />
              Drag and drop a deal here.
            </div>
          )}
        </SortableContext>
      </div>
    </div>
  );
}

export default Dealcolumn;