import React from 'react';
import './LoadingPage.css'; // Import CSS for styling

import logo from '../img/logo.png'; // Adjust the import path as needed


export default function LoadingPage({ loadingPercentage }) {
  return (
    <div className="loading-container">
      <div className="logo-container">


        <img src={logo} alt="Logo" className="floating-logo" />
        <svg className="progress-ring" width="120" height="120">


        </svg>
      </div>
      <p>Loading {loadingPercentage}%</p>
    </div>
  );
}
