import React, { useState, useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { collection, onSnapshot, addDoc, doc, updateDoc, deleteDoc, query, where, Timestamp } from 'firebase/firestore';
import { db, auth } from '../firebase';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import VerticalMenu from './VerticalMenu';

import {
  Layout,
  Button,
  Modal,
  Input,
  DatePicker,
  Switch,
  Typography,
  Space,
  Spin,
  Popconfirm,
  theme,
  ColorPicker
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

// Modern color presets
const MODERN_COLORS = [
  '#FF6B6B', // Coral Red
  '#4ECDC4', // Turquoise
  '#45B7D1', // Sky Blue
  '#96CEB4', // Sage Green
  '#FFEEAD', // Soft Yellow
  '#D4A5A5', // Dusty Rose
  '#9B5DE5', // Purple
  '#F15BB5', // Pink
  '#00BBF9', // Bright Blue
  '#00F5D4', // Mint
];

const StyledLayout = styled(Layout)`
  min-height: 100vh;
  background: ${props => props.theme === 'dark' ? '#141414' : '#f8f9fa'};
`;

const StyledHeader = styled(Header)`
  background: ${props => props.theme === 'dark' ? '#1f1f1f' : '#ffffff'};
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  height: 72px;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const StyledContent = styled(Content)`
  padding: 32px;
  margin: 0;
  min-height: calc(100vh - 72px);
`;

const StyledCalendarWrapper = styled.div`
  .rbc-calendar {
    background: ${props => props.theme === 'dark' ? '#1f1f1f' : '#ffffff'};
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06);
    border: 1px solid ${props => props.theme === 'dark' ? '#303030' : '#eaeaea'};
  }

  .rbc-event {
    border-radius: 8px;
    padding: 6px 12px;
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      transition: all 0.2s ease;
    }
  }

  .rbc-today {
    background-color: ${props => props.theme === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)'};
  }

  .rbc-header {
    padding: 16px 8px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.85em;
    letter-spacing: 0.5px;
    border-bottom: 2px solid ${props => props.theme === 'dark' ? '#303030' : '#f0f0f0'};
  }

  .rbc-toolbar {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid ${props => props.theme === 'dark' ? '#303030' : '#f0f0f0'};
  }

  .rbc-toolbar button {
    color: ${props => props.theme === 'dark' ? '#fff' : '#000'};
    border: 1px solid ${props => props.theme === 'dark' ? '#434343' : '#e6e6e6'};
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 500;
    background: ${props => props.theme === 'dark' ? '#1f1f1f' : '#ffffff'};
    
    &:hover {
      background-color: ${props => props.theme === 'dark' ? '#2d2d2d' : '#f5f5f5'};
      border-color: ${props => props.theme === 'dark' ? '#505050' : '#d9d9d9'};
    }
    
    &:active {
      background-color: ${props => props.theme === 'dark' ? '#3d3d3d' : '#e6e6e6'};
    }
  }

  .rbc-month-view {
    border-radius: 12px;
    border: 1px solid ${props => props.theme === 'dark' ? '#303030' : '#eaeaea'};
  }

  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid ${props => props.theme === 'dark' ? '#303030' : '#eaeaea'};
  }

  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid ${props => props.theme === 'dark' ? '#303030' : '#eaeaea'};
  }

  .rbc-off-range-bg {
    background: ${props => props.theme === 'dark' ? '#1a1a1a' : '#f9f9f9'};
  }

  .rbc-header + .rbc-header {
    border-left: 1px solid ${props => props.theme === 'dark' ? '#303030' : '#eaeaea'};
  }
`;

const AddEventButton = styled(Button)`
  height: 40px;
  padding: 0 24px;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 16px;
    padding: 24px;
  }

  .ant-modal-header {
    padding: 0 0 24px 0;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    padding: 24px 0 0 0;
    margin-top: 24px;
    border-top: 1px solid ${props => props.theme === 'dark' ? '#303030' : '#f0f0f0'};
  }
`;

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: {
    'en-US': require('date-fns/locale/en-US')
  }
});

const CalendarPage = () => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [newEvent, setNewEvent] = useState({
    title: '',
    description: '',
    start: new Date(),
    end: new Date(),
    color: MODERN_COLORS[0],
    allDay: false
  });

  const userId = auth.currentUser?.uid;

  useEffect(() => {
    if (!userId) return;

    const eventsQuery = query(
      collection(db, 'events'),
      where('userId', '==', userId)
    );

    const unsubscribe = onSnapshot(eventsQuery, (snapshot) => {
      try {
        const fetchedEvents = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            title: data.title,
            description: data.description,
            start: data.start.toDate(),
            end: data.end.toDate(),
            color: data.color || MODERN_COLORS[0],
            allDay: data.allDay,
          };
        });
        setEvents(fetchedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [userId]);

  const handleSelectSlot = ({ start, end }) => {
    setSelectedEvent(null);
    setNewEvent({
      title: '',
      description: '',
      start,
      end,
      color: MODERN_COLORS[0],
      allDay: false
    });
    setModalVisible(true);
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setNewEvent({
      ...event,
      start: event.start,
      end: event.end
    });
    setModalVisible(true);
  };

  const handleSave = async () => {
    try {
      const eventData = {
        title: newEvent.title,
        description: newEvent.description,
        start: Timestamp.fromDate(newEvent.start),
        end: Timestamp.fromDate(newEvent.end),
        color: newEvent.color,
        allDay: newEvent.allDay,
        userId
      };

      if (selectedEvent) {
        await updateDoc(doc(db, 'events', selectedEvent.id), eventData);
      } else {
        await addDoc(collection(db, 'events'), eventData);
      }

      setModalVisible(false);
      resetForm();
    } catch (error) {
      console.error("Error saving event:", error);
    }
  };

  const handleDelete = async () => {
    try {
      if (!selectedEvent) return;
      await deleteDoc(doc(db, 'events', selectedEvent.id));
      setModalVisible(false);
      resetForm();
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const resetForm = () => {
    setSelectedEvent(null);
    setNewEvent({
      title: '',
      description: '',
      start: new Date(),
      end: new Date(),
      color: MODERN_COLORS[0],
      allDay: false
    });
  };

  const eventStyleGetter = (event) => {
    return {
      style: {
        backgroundColor: event.color,
        borderRadius: '8px',
        opacity: 0.9,
        color: '#fff',
        border: 'none',
        display: 'block'
      }
    };
  };

  if (loading) {
    return (
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Space direction="vertical" align="center">
          <Spin size="large" />
          <Text>{t('Loading calendar...')}</Text>
        </Space>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <VerticalMenu />
      <div style={{ flex: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
        <StyledLayout theme={token.mode}>
          <StyledHeader theme={token.mode}>
            <Space align="center" size={16}>
              <CalendarOutlined style={{ fontSize: '24px', color: token.colorPrimary }} />
              <Title level={4} style={{ margin: 0 }}>{t('Calendar')}</Title>
            </Space>
            <AddEventButton
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                resetForm();
                setModalVisible(true);
              }}
            >
              {t('New Event')}
            </AddEventButton>
          </StyledHeader>

          <StyledContent>
            <StyledCalendarWrapper theme={token.mode}>
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 'calc(100vh - 150px)' }}
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectEvent}
                selectable
                eventPropGetter={eventStyleGetter}
                views={['month', 'week', 'day', 'agenda']}
                defaultView="month"
              />
            </StyledCalendarWrapper>
          </StyledContent>

          <StyledModal
            title={selectedEvent ? t('Edit Event') : t('New Event')}
            open={modalVisible}
            onCancel={() => {
              setModalVisible(false);
              resetForm();
            }}
            footer={[
              selectedEvent && (
                <Popconfirm
                  key="delete"
                  title={t('Are you sure you want to delete this event?')}
                  onConfirm={handleDelete}
                  okText={t('Yes')}
                  cancelText={t('No')}
                >
                  <Button danger icon={<DeleteOutlined />}>
                    {t('Delete')}
                  </Button>
                </Popconfirm>
              ),
              <Button
                key="cancel"
                onClick={() => {
                  setModalVisible(false);
                  resetForm();
                }}
              >
                {t('Cancel')}
              </Button>,
              <Button key="submit" type="primary" onClick={handleSave}>
                {selectedEvent ? t('Update') : t('Create')}
              </Button>
            ]}
            width={600}
          >
            <Space direction="vertical" size="middle" style={{ width: '100%', marginTop: 16 }}>
              <Input
                placeholder={t('Event title')}
                value={newEvent.title}
                onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                prefix={<EditOutlined />}
              />

              <TextArea
                placeholder={t('Description')}
                value={newEvent.description}
                onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
                rows={4}
              />

              <Space direction="vertical" style={{ width: '100%' }}>
              <Text>{t('Event Color')}</Text>
                <ColorPicker
                  value={newEvent.color}
                  onChange={(color) => setNewEvent({ ...newEvent, color: color.toHexString() })}
                  presets={[
                    {
                      label: t('Modern Colors'),
                      colors: MODERN_COLORS
                    }
                  ]}
                />
              </Space>

              <Space direction="vertical" style={{ width: '100%' }}>
                <Switch
                  checked={newEvent.allDay}
                  onChange={(checked) => setNewEvent({ ...newEvent, allDay: checked })}
                  checkedChildren={t('All day')}
                  unCheckedChildren={t('Timed')}
                />

                {newEvent.allDay ? (
                  <RangePicker
                    style={{ width: '100%' }}
                    value={[dayjs(newEvent.start), dayjs(newEvent.end)]}
                    onChange={(dates) => {
                      if (dates) {
                        setNewEvent({
                          ...newEvent,
                          start: dates[0].toDate(),
                          end: dates[1].toDate()
                        });
                      }
                    }}
                  />
                ) : (
                  <Space direction="vertical" style={{ width: '100%' }}>
                    <DatePicker
                      showTime
                      style={{ width: '100%' }}
                      value={dayjs(newEvent.start)}
                      onChange={(date) => setNewEvent({
                        ...newEvent,
                        start: date ? date.toDate() : new Date()
                      })}
                    />
                    <DatePicker
                      showTime
                      style={{ width: '100%' }}
                      value={dayjs(newEvent.end)}
                      onChange={(date) => setNewEvent({
                        ...newEvent,
                        end: date ? date.toDate() : new Date()
                      })}
                    />
                  </Space>
                )}
              </Space>
            </Space>
          </StyledModal>
        </StyledLayout>
      </div>
    </div>
  );
};

export default CalendarPage;