import React, { useState, useEffect, useRef } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { doc, updateDoc, getDoc, collection, getDocs, deleteDoc, addDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { Card, Modal, Form, Input, Select, DatePicker, Tag, Typography, message, Button } from 'antd';
import { DollarOutlined, CalendarOutlined, UserOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const { Text, Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

function DealCard({ id, title, amount, companyId, closeDate, stage, description, contactId, onUpdate }) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNewContactModalVisible, setIsNewContactModalVisible] = useState(false);
  const [dealDetails, setDealDetails] = useState({ 
    title, amount, companyId, closeDate, stage, description, contactId 
  });
  const [companies, setCompanies] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [form] = Form.useForm();
  const [contactForm] = Form.useForm();
  const [isDragging, setIsDragging] = useState(false);
  const clickStartRef = useRef(0);

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
    data: {
      type: 'DealCard',
      id: id,
      title: title,
    },
    disabled: isModalVisible, // Disable dragging when modal is open
  });

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;

  useEffect(() => {
    const fetchCompaniesAndContacts = async () => {
      const companiesSnapshot = await getDocs(collection(db, 'companies'));
      const companiesData = companiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCompanies(companiesData);

      const contactsSnapshot = await getDocs(collection(db, 'contacts'));
      const contactsData = contactsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setContacts(contactsData);
    };

    fetchCompaniesAndContacts();
  }, []);

  useEffect(() => {
    if (isModalVisible) {
      form.setFieldsValue({
        ...dealDetails,
        closeDate: dealDetails.closeDate ? moment(dealDetails.closeDate.toDate()) : null,
      });
    }
  }, [isModalVisible, dealDetails, form]);

  const handleMouseDown = (e) => {
    if (isModalVisible) return; // Prevent dragging when modal is open
    clickStartRef.current = Date.now();
    setIsDragging(false);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = () => {
    if (isModalVisible) return; // Prevent dragging when modal is open
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    if (isModalVisible) return; // Prevent dragging when modal is open
    const clickDuration = Date.now() - clickStartRef.current;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);

    if (!isDragging && clickDuration < 200) {
      handleCardClick();
    }
  };

  const handleCardClick = () => {
    setIsModalVisible(true);
  };

  const handleUpdate = async (values) => {
    const updatedDeal = {
      ...values,
      closeDate: values.closeDate ? values.closeDate.toDate() : null,
    };

    const dealRef = doc(db, 'deals', id);
    try {
      await updateDoc(dealRef, updatedDeal);
      setDealDetails(updatedDeal);
      setIsModalVisible(false);
      message.success(t('Deal updated successfully'));
      if (onUpdate) onUpdate(id, updatedDeal);
    } catch (error) {
      console.error("Error updating deal:", error);
      message.error(t('Failed to update deal'));
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, 'deals', id));
      message.success(t('Deal deleted successfully'));
      if (onUpdate) onUpdate(id, null);
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error deleting deal:", error);
      message.error(t('Failed to delete deal'));
    }
  };

  const handleCreateContact = async (values) => {
    try {
      const currentUser = auth.currentUser;
      const newContact = {
        ...values,
        userId: currentUser ? currentUser.uid : 'IEOU6amxEYbs4gDXKTHLBwaDF5o1',
        color: '#' + Math.floor(Math.random()*16777215).toString(16) // Random color
      };
      const docRef = await addDoc(collection(db, 'contacts'), newContact);
      newContact.id = docRef.id;
      setContacts(prevContacts => [...prevContacts, newContact]);
      form.setFieldsValue({ contactId: newContact.id });
      setIsNewContactModalVisible(false);
      message.success(t('Contact created successfully'));
    } catch (error) {
      console.error("Error creating contact:", error);
      message.error(t('Failed to create contact'));
    }
  };

  return (
    <div 
      ref={setNodeRef} 
      style={style} 
      {...(isModalVisible ? {} : attributes)} // Only apply drag attributes if modal is closed
      {...(isModalVisible ? {} : listeners)} // Only apply drag listeners if modal is closed
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <Card
        size="small"
        style={{ width: 250, marginBottom: 16, cursor: isModalVisible ? 'default' : 'grab' }}
      >
        <Title level={5}>{dealDetails.title}</Title>
        <div>
          <DollarOutlined style={{ marginRight: 8 }} />
          <Text>{dealDetails.amount ? `$${parseFloat(dealDetails.amount).toLocaleString()}` : t('Not set')}</Text>
        </div>
        <div>
          <CalendarOutlined style={{ marginRight: 8 }} />
          <Text>{dealDetails.closeDate ? moment(dealDetails.closeDate.toDate()).format('MMM D, YYYY') : t('Not set')}</Text>
        </div>
        <Tag color="blue" style={{ marginTop: 8 }}>{dealDetails.stage}</Tag>
      </Card>

      <Modal
        title={t("Deal Details")}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="delete" danger onClick={handleDelete}>
            {t("Delete")}
          </Button>,
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            {t("Cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            {t("Save")}
          </Button>,
        ]}
        width={600}
      >
        <Form 
          form={form} 
          onFinish={handleUpdate} 
          layout="vertical"
          initialValues={{
            ...dealDetails,
            closeDate: dealDetails.closeDate ? moment(dealDetails.closeDate.toDate()) : null,
          }}
        >
          <Form.Item name="title" label={t("Deal Title")} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="amount" label={t("Amount")} rules={[{ required: true }]}>
            <Input prefix="$" type="number" />
          </Form.Item>
          <Form.Item name="companyId" label={t("Company")} rules={[{ required: true }]}>
            <Select>
              {companies.map(company => (
                <Option key={company.id} value={company.id}>{company.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="closeDate" label={t("Close Date")} rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="stage" label={t("Stage")} rules={[{ required: true }]}>
            <Select>
              <Option value="New">{t("New")}</Option>
              <Option value="Under Review">{t("Under Review")}</Option>
              <Option value="Demo">{t("Demo")}</Option>
              <Option value="Won">{t("Won")}</Option>
              <Option value="Lost">{t("Lost")}</Option>
              <Option value="Unassigned">{t("Unassigned")}</Option>
            </Select>
          </Form.Item>
          <Form.Item name="description" label={t("Description")}>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="contactId" label={t("Contact")}>
            <Select
              dropdownRender={menu => (
                <>
                  {menu}
                  <Button 
                    type="link" 
                    icon={<PlusOutlined />} 
                    onClick={() => setIsNewContactModalVisible(true)}
                  >
                    {t("Add New Contact")}
                  </Button>
                </>
              )}
            >
              {contacts.map(contact => (
                <Option key={contact.id} value={contact.id}>{contact.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t("Create New Contact")}
        open={isNewContactModalVisible}
        onCancel={() => setIsNewContactModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsNewContactModalVisible(false)}>
            {t("Cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={() => contactForm.submit()}>
            {t("Create")}
          </Button>,
        ]}
      >
        <Form form={contactForm} onFinish={handleCreateContact} layout="vertical">
          <Form.Item name="name" label={t("Name")} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="title" label={t("Title")}>
            <Input />
          </Form.Item>
          <Form.Item name="companyId" label={t("Company")} rules={[{ required: true }]}>
            <Select>
              {companies.map(company => (
                <Option key={company.id} value={company.id}>{company.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default DealCard;