import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase';
import {
  collection, onSnapshot, addDoc, doc, updateDoc, deleteDoc, getDoc, getDocs, query, where, setDoc
} from 'firebase/firestore';
import VerticalMenu from '../VerticalMenu';
import { 
  Modal, Input, message, Button, List, Card, Space, Typography, 
  Layout, Row, Col, Drawer, Tag, Tooltip, Avatar, Dropdown, Menu, Statistic,
  Progress, Tabs, ColorPicker, Select, Popconfirm, Divider, DatePicker, Empty
} from 'antd';
import { 
  EditOutlined, PlusOutlined, InfoCircleOutlined,
  CheckCircleOutlined, ClockCircleOutlined, UnorderedListOutlined,
  AppstoreOutlined, DownOutlined, SortAscendingOutlined, SettingOutlined,
  DeleteOutlined, CalendarOutlined, QuestionCircleOutlined
} from '@ant-design/icons';
import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { writeBatch } from 'firebase/firestore';
import wizard from '../../img/wizard.png';

const { Title, Text, Paragraph } = Typography;
const { Header, Content } = Layout;
const { Search } = Input;
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

function ProjectDashboard() {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState({ name: '', color: '', tags: [] });
  const [features, setFeatures] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [notes, setNotes] = useState([]);
  const [activities, setActivities] = useState([]);
  const [newFeature, setNewFeature] = useState({ name: '', color: '#1890ff', tags: [] });
  const [newNote, setNewNote] = useState({ title: '', content: '' });
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTags, setSearchTags] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [addFeatureModalVisible, setAddFeatureModalVisible] = useState(false);
  const [addNoteModalVisible, setAddNoteModalVisible] = useState(false);
  const [editedFeature, setEditedFeature] = useState(null);
  const [activitiesDrawerVisible, setActivitiesDrawerVisible] = useState(false);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [viewMode, setViewMode] = useState('grid');
  const [recentColors, setRecentColors] = useState([]);
  const [userTags, setUserTags] = useState([]);
  const [projectSettingsModalVisible, setProjectSettingsModalVisible] = useState(false);
  const [dateRange, setDateRange] = useState(null);

  // Onboarding state
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [highlightedElement, setHighlightedElement] = useState(null);

  // Refs for onboarding
  const addFeatureButtonRef = useRef(null);
  const searchInputRef = useRef(null);
  const filterTagsRef = useRef(null);
  const addnoteRef = useRef(null);
  const sortSelectRef = useRef(null);
  const viewModeRef = useRef(null);
  const featureCardRef = useRef(null);

  const userId = auth.currentUser?.uid;

  useEffect(() => {
    const fetchProjectData = async () => {
      const projectRef = doc(db, 'projects', projectId);
      const docSnap = await getDoc(projectRef);
      if (docSnap.exists()) {
        setProjectData({ id: docSnap.id, ...docSnap.data() });
      } else {
        message.error(t('Project does not exist'));
      }
    };

    const fetchFeatures = () => {
      const featuresRef = collection(db, 'features');
      const q = query(featuresRef, where('projectId', '==', projectId));
      return onSnapshot(q, snapshot => {
        const fetchedFeatures = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          created: doc.data().created?.toDate?.() || new Date(doc.data().created),
          tags: doc.data().tags || []
        }));
        setFeatures(fetchedFeatures);
      });
    };

    const fetchTasks = () => {
      const tasksRef = collection(db, 'tasks');
      const q = query(tasksRef, where('projectId', '==', projectId));
      return onSnapshot(q, snapshot => {
        const fetchedTasks = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          created: doc.data().created?.toDate?.() || new Date(doc.data().created)
        }));
        setTasks(fetchedTasks);
      });
    };

    const fetchNotes = () => {
      const notesRef = collection(db, 'notes');
      const q = query(notesRef, where('projectId', '==', projectId), where('userId', '==', userId));
      return onSnapshot(q, snapshot => {
        const fetchedNotes = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          created: doc.data().created?.toDate?.() || new Date(doc.data().created)
        }));
        setNotes(fetchedNotes);
      });
    };

    const fetchActivities = () => {
      const activitiesRef = collection(db, 'activities');
      const q = query(activitiesRef, where('projectId', '==', projectId));
      return onSnapshot(q, snapshot => {
        const fetchedActivities = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate?.() || new Date(doc.data().timestamp)
        }));
        setActivities(fetchedActivities);
      });
    };

    const fetchUserPreferences = async () => {
      const userPrefRef = doc(db, 'userPreferences', userId);
      const docSnap = await getDoc(userPrefRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setRecentColors(data.recentColors || []);
        setUserTags(data.tags || []);
      } else {
        await setDoc(userPrefRef, { recentColors: [], tags: [] });
      }
    };

    fetchProjectData();
    fetchUserPreferences();
    const unsubscribeFeatures = fetchFeatures();
    const unsubscribeTasks = fetchTasks();
    const unsubscribeNotes = fetchNotes();
    const unsubscribeActivities = fetchActivities();

    const hasCompletedOnboarding = localStorage.getItem('hasCompletedFeaturesOnboarding');
    if (!hasCompletedOnboarding) {
      setShowOnboarding(true);
    }

    return () => {
      unsubscribeFeatures();
      unsubscribeTasks();
      unsubscribeNotes();
      unsubscribeActivities();
    };
  }, [projectId, userId, t]);

  const filteredFeatures = useMemo(() => {
    return features.filter(feature => 
      feature.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (searchTags.length === 0 || searchTags.every(tag => feature.tags.includes(tag))) &&
      (!dateRange || (feature.created >= dateRange[0].startOf('day').toDate() && 
                      feature.created <= dateRange[1].endOf('day').toDate()))
    ).sort((a, b) => {
      if (sortBy === 'name') {
        return sortOrder === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
      } else if (sortBy === 'date') {
        return sortOrder === 'asc' ? a.created - b.created : b.created - a.created;
      }
      return 0;
    });
  }, [features, searchTerm, searchTags, sortBy, sortOrder, dateRange]);

  const updateUserPreferences = async (newColor, newTags) => {
    const userPrefRef = doc(db, 'userPreferences', userId);
    const docSnap = await getDoc(userPrefRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      let updatedColors = [...new Set([newColor, ...data.recentColors])].slice(0, 10);
      let updatedTags = [...new Set([...newTags, ...data.tags])];
      await updateDoc(userPrefRef, { 
        recentColors: updatedColors,
        tags: updatedTags
      });
      setRecentColors(updatedColors);
      setUserTags(updatedTags);
    }
  };

  const handleAddFeature = async () => {
    if (newFeature.name.trim()) {
      await addDoc(collection(db, 'features'), {
        ...newFeature,
        projectId,
        userId,
        created: new Date()
      });
      await updateUserPreferences(newFeature.color, newFeature.tags);
      setNewFeature({ name: '', color: '#1890ff', tags: [] });
      setAddFeatureModalVisible(false);
      message.success(t('Feature added successfully'));
    }
  };

  const handleEditFeature = async () => {
    if (editedFeature) {
      const featureRef = doc(db, 'features', editedFeature.id);
      await updateDoc(featureRef, { 
        name: editedFeature.name,
        color: editedFeature.color,
        tags: editedFeature.tags
      });
      await updateUserPreferences(editedFeature.color, editedFeature.tags);
      setEditModalVisible(false);
      setEditedFeature(null);
      message.success(t('Feature updated successfully'));
    }
  };

  const handleAddNote = async () => {
    if (newNote.title.trim()) {
      await addDoc(collection(db, 'notes'), {
        title: newNote.title,
        content: newNote.content,
        projectId,
        userId,
        created: new Date()
      });
      setNewNote({ title: '', content: '' });
      setAddNoteModalVisible(false);
      message.success(t('Note added successfully'));
    }
  };

  const handleUpdateProject = async () => {
    const projectRef = doc(db, 'projects', projectId);
    await updateDoc(projectRef, {
      name: projectData.name,
      color: projectData.color,
      tags: projectData.tags
    });
    await updateUserPreferences(projectData.color, projectData.tags);
    setProjectSettingsModalVisible(false);
    message.success(t('Project updated successfully'));
  };

  const handleDeleteProject = async () => {
    try {
      const batch = writeBatch(db);
  
      // Delete features
      const featuresRef = collection(db, 'features');
      const featuresQuery = query(featuresRef, where('projectId', '==', projectId));
      const featuresSnapshot = await getDocs(featuresQuery);
      
      featuresSnapshot.forEach((featureDoc) => {
        batch.delete(featureDoc.ref);
      });
  
      // Delete tasks
      const tasksRef = collection(db, 'tasks');
      const tasksQuery = query(tasksRef, where('projectId', '==', projectId));
      const tasksSnapshot = await getDocs(tasksQuery);
      tasksSnapshot.forEach((taskDoc) => {
        batch.delete(taskDoc.ref);
      });
  
      // Delete notes
      const notesRef = collection(db, 'notes');
      const notesQuery = query(notesRef, where('projectId', '==', projectId));
      const notesSnapshot = await getDocs(notesQuery);
      notesSnapshot.forEach((noteDoc) => {
        batch.delete(noteDoc.ref);
      });
  
      // Delete the project
      const projectRef = doc(db, 'projects', projectId);
      batch.delete(projectRef);
      
      await batch.commit();
      message.success(t('Project and associated items deleted successfully'));
      navigate('/');
    } catch (error) {
      console.error("Error deleting project: ", error);
      message.error(t('Error deleting project'));
    }
  };

  const getInitials = (name) => {
    return name.split(' ').slice(0, 2).map(word => word[0]).join('').toUpperCase();
  };

  const onboardingSteps = [
    {
      title: t('Welcome to Features!'),
      content: t('This is your project dashboard, where you can manage features and notes. Let\'s take a quick tour!'),
      target: null,
    },
    {
      title: t('Add a New Feature'),
      content: t('Click this button to create a new feature. You can set a name, color, and tags.'),
      target: addFeatureButtonRef,
    },
    {
      title: t('Search Features'),
      content: t('Use this search bar to find specific features quickly.'),
      target: searchInputRef,
    },
    {
      title: t('Filter Features'),
      content: t('Filter your features by tags to organize them better.'),
      target: filterTagsRef,
    },
    {
      title: t('Sort Features'),
      content: t('Sort your features by name or date to find what you need.'),
      target: sortSelectRef,
    },
    {
      title: t('Change View Mode'),
      content: t('Switch between grid and list view to see your features in different layouts.'),
      target: viewModeRef,
    },
    {
      title: t('Feature Cards'),
      content: t('Each card represents a feature. Click on a card to view its details and tasks.'),
      target: featureCardRef,
    },
    {
      title: t('Add notes'),
      content: t('Click this button to create a new note. Use it as a timebox or client notes'),
      target: addnoteRef,
    },
    {
      title: t('That\'s it!'),
      content: t('You\'re all set to start managing your features. Happy organizing!'),
      target: null,
    },
  ];

  const handleNextStep = () => {
    if (currentStep < onboardingSteps.length - 1) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
      setHighlightedElement(onboardingSteps[nextStep].target ? onboardingSteps[nextStep].target : null);
    } else {
      setShowOnboarding(false);
      setHighlightedElement(null);
      localStorage.setItem('hasCompletedFeaturesOnboarding', 'true');
    }
  };
  
  const handlePrevStep = () => {
    if (currentStep > 0) {
      const prevStep = currentStep - 1;
      setCurrentStep(prevStep);
      setHighlightedElement(onboardingSteps[prevStep].target ? onboardingSteps[prevStep].target : null);
    }
  };
  
  const handleSkipTutorial = () => {
    setShowOnboarding(false);
    setHighlightedElement(null);
    localStorage.setItem('hasCompletedFeaturesOnboarding', 'true');
  };
  
  const startTutorial = () => {
    setCurrentStep(0);
    setShowOnboarding(true);
    setHighlightedElement(onboardingSteps[0].target);
  };

  const renderOnboardingModal = () => (
    <Modal
      visible={showOnboarding}
      footer={null}
      closable={false}
      width={400}
      style={{ top: 20 }}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
    >
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.3 }}
      >
        <motion.img 
          src={wizard} 
          alt="Wizard" 
          style={{ width: 100, marginBottom: 20 }}
          initial={{ rotate: 0 }}
          animate={{ rotate: [0, -10, 10, -10, 0] }}
          transition={{ duration: 2, loop: Infinity, ease: "easeInOut" }}
        />
        <Title level={3}>{onboardingSteps[currentStep].title}</Title>
        <Text>{onboardingSteps[currentStep].content}</Text>
        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
          {currentStep > 0 && (
            <Button onClick={handlePrevStep}>{t('Previous')}</Button>
          )}
          <Button type="primary" onClick={handleNextStep}>
            {currentStep === onboardingSteps.length - 1 ? t('Finish') : t('Next')}
          </Button>
          {currentStep < onboardingSteps.length - 1 && (
            <Button onClick={handleSkipTutorial}>{t('Skip')}</Button>
          )}
        </div>
      </motion.div>
    </Modal>
  );

  const renderHighlight = () => {
    if (!highlightedElement || !highlightedElement.current) {
      return null;
    }
  
    // Use a try-catch block to handle potential errors
    try {
      const rect = highlightedElement.current.getBoundingClientRect();
      const padding = 10;
  
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          style={{
            position: 'fixed',
            top: rect.top - padding,
            left: rect.left - padding,
            width: rect.width + padding * 2,
            height: rect.height + padding * 2,
            border: '2px solid #1890ff',
            borderRadius: '4px',
            boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
            pointerEvents: 'none',
          }}
        />
      );
    } catch (error) {
      console.error('Error rendering highlight:', error);
      return null;
    }
  };

  const renderFeatureCard = (feature) => (
    <motion.div
      key={feature.id}
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      ref={featureCardRef}
    >
      <Card
        hoverable
        style={{ height: '100%' }}
        onClick={() => navigate(`/projects/${projectId}/features/${feature.id}/tasks`)}
      >
        <div style={{ 
          height: 120, 
          background: feature.color,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 16
        }}>
          <Title level={3} style={{ color: '#fff', margin: 0 }}>{getInitials(feature.name)}</Title>
        </div>
        <Card.Meta
          title={feature.name}
          description={
            <Space direction="vertical" style={{ width: '100%' }}>
              <Text type="secondary">{t('Created')}: {formatDistanceToNow(feature.created, { addSuffix: true })}</Text>
              <Progress 
                percent={Math.round((tasks.filter(task => task.featureId === feature.id && task.phase === 'Done').length / tasks.filter(task => task.featureId === feature.id).length) * 100)} 
                size="small" 
              />
              <Space>
                <Tag color="blue">{tasks.filter(task => task.featureId === feature.id).length} {t('Tasks')}</Tag>
                <Tag color="green">{tasks.filter(task => task.featureId === feature.id && task.phase === 'Done').length} {t('Completed')}</Tag>
              </Space>
              <div>
                {feature.tags && feature.tags.map((tag, index) => (
                  <Tag key={index} style={{ marginBottom: 4 }}>{tag}</Tag>
                ))}
              </div>
            </Space>
          }
        />
      </Card>
    </motion.div>
  );

  const renderFeaturesList = () => (
    <List
      dataSource={filteredFeatures}
      renderItem={feature => (
        <List.Item
          onClick={() => navigate(`/projects/${projectId}/features/${feature.id}/tasks`)}
          style={{ cursor: 'pointer' }}
        >
          <List.Item.Meta
            avatar={<Avatar style={{ backgroundColor: feature.color }}>{getInitials(feature.name)}</Avatar>}
            title={feature.name}
            description={
              <Space direction="vertical">
                <Text type="secondary">{formatDistanceToNow(feature.created, { addSuffix: true })}</Text>
                <Progress 
                  percent={Math.round((tasks.filter(task => task.featureId === feature.id && task.phase === 'Done').length / tasks.filter(task => task.featureId === feature.id).length) * 100)} 
                  size="small" 
                />
                <Space>
                  <Tag color="blue">{tasks.filter(task => task.featureId === feature.id).length} {t('Tasks')}</Tag>
                  <Tag color="green">{tasks.filter(task => task.featureId === feature.id && task.phase === 'Done').length} {t('Completed')}</Tag>
                </Space>
                <div>
                  {feature.tags && feature.tags.map((tag, index) => (
                    <Tag key={index} style={{ marginBottom: 4 }}>{tag}</Tag>
                  ))}
                </div>
              </Space>
            }
          />
          <Button icon={<EditOutlined />} onClick={(e) => { e.stopPropagation(); setEditedFeature(feature); setEditModalVisible(true); }} />
        </List.Item>
      )}
    />
  );

  return (
    <div style={{ display: 'flex', height: '100vh', backgroundColor: '#fff' }}>
      <VerticalMenu />
      <Layout style={{ minHeight: '100%', background: '#fff', flex: 1, overflowY: 'auto' }}>
        <Header style={{ background: '#fff', padding: 0 }}>
          <Row justify="space-between" align="middle" style={{ height: '100%', padding: '0 24px' }}>
            <Col xs={24} sm={12}>
              <Space wrap>
                <Avatar style={{ backgroundColor: projectData.color, verticalAlign: 'middle' }} size="large">
                  {getInitials(projectData.name)}
                </Avatar>
                <Title level={4} style={{ margin: 0 }}>{projectData.name}</Title>
                <Tooltip title={t('Project Settings')}>
                  <Button 
                    icon={<SettingOutlined />} 
                    onClick={() => setProjectSettingsModalVisible(true)}
                  />
                </Tooltip>
              </Space>
            </Col>
            <Col xs={24} sm={12} style={{ textAlign: 'right', marginTop: { xs: '16px', sm: 0 } }}>
              <Space wrap>
                <Tooltip title={t('Last update')}>
                  <Button icon={<InfoCircleOutlined />} onClick={() => setActivitiesDrawerVisible(true)}>
                    {activities[0] ? formatDistanceToNow(activities[0].timestamp, { addSuffix: true }) : t('No updates')}
                  </Button>
                </Tooltip>
                <Button onClick={() => navigate(`/projects/${projectId}/MindMap`)}>{t('MindMap')}</Button>
                <Button onClick={() => navigate(`/projects/${projectId}/Roadmap`)}>{t('Roadmap')}</Button>
                <Button onClick={() => navigate(`/projects/${projectId}/AllTask`)}>{t('All Tasks')}</Button>
                <Button 
                  type="primary" 
                  icon={<PlusOutlined />} 
                  onClick={() => setAddFeatureModalVisible(true)}
                  ref={addFeatureButtonRef}
                >
                  {t('Add Feature')}
                </Button>
                <Tooltip title={t('Start Tutorial')}>
                  <Button 
                    icon={<QuestionCircleOutlined />} 
                    onClick={startTutorial}
                  />
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </Header>
        <Content style={{ padding: 24 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6}>
              <Card>
                <Statistic title={t("Total Features")} value={features.length} />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card>
                <Statistic title={t("Total Tasks")} value={tasks.length} />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card>
                <Statistic 
                  title={t("Completed Tasks")} 
                  value={tasks.filter(task => task.phase === 'Done').length} 
                  suffix={`/ ${tasks.length}`}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Card>
                <Statistic title={t("Notes")} value={notes.length} />
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
            <Col xs={24} lg={18}>
              <Card
                title={t("Features")}
                extra={
                  <Space wrap>
                    <Search
                      placeholder={t("Search Features")}
                      allowClear
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={{ width: 200 }}
                      ref={searchInputRef}
                    />
                    <Select
                      mode="multiple"
                      style={{ width: '200px' }}
                      placeholder={t("Filter by tags")}
                      onChange={setSearchTags}
                      options={userTags.map(tag => ({ value: tag, label: tag }))}
                      ref={filterTagsRef}
                    />
                    <RangePicker 
                      onChange={(dates) => setDateRange(dates)}
                      format="YYYY-MM-DD"
                    />
                    <Select
                      style={{ width: 120 }}
                      placeholder={t("Sort by")}
                      onChange={(value) => setSortBy(value)}
                      defaultValue="name"
                      ref={sortSelectRef}
                    >
                      <Option value="name">{t("Name")}</Option>
                      <Option value="date">{t("Date")}</Option>
                    </Select>
                    <Button 
                      icon={sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortAscendingOutlined rotate={180} />} 
                      onClick={() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}
                    />
                  </Space>
                }
              >
                <Tabs defaultActiveKey="grid" onChange={setViewMode} ref={viewModeRef}>
                  <TabPane tab={<span><AppstoreOutlined />{t('Grid')}</span>} key="grid">
                    <Row gutter={[16, 16]}>
                      <AnimatePresence>
                        {filteredFeatures.map(feature => (
                          <Col xs={24} sm={12} md={8} lg={6} key={feature.id}>
                            {renderFeatureCard(feature)}
                          </Col>
                        ))}
                      </AnimatePresence>
                    </Row>
                  </TabPane>
                  <TabPane tab={<span><UnorderedListOutlined />{t('List')}</span>} key="list">
                    {renderFeaturesList()}
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
            <Col xs={24} lg={6}>
              <Card 
                title={t('Notes')} 
                extra={<Button type="primary" onClick={() => setAddNoteModalVisible(true)} ref={addnoteRef}>{t('Add Note')}</Button>}
                style={{ height: '100%', overflowY: 'auto' }}
              >
                {notes.length > 0 ? (
                  <List
                    dataSource={notes}
                    renderItem={note => (
                      <List.Item>
                        <List.Item.Meta
                          title={
                            <a onClick={() => navigate(`/note-content/${note.id}`)}>
                              {note.title}
                            </a>
                          }
                          description={formatDistanceToNow(note.created, { addSuffix: true })}
                        />
                      </List.Item>
                    )}
                  />
                ) : (
                  <Empty description={t('No notes yet')} />
                )}
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>

      <Modal
        title={t("Add New Feature")}
        visible={addFeatureModalVisible}
        onOk={handleAddFeature}
        onCancel={() => setAddFeatureModalVisible(false)}
      >
        <Input
          placeholder={t("Enter feature name")}
          value={newFeature.name}
          onChange={(e) => setNewFeature({ ...newFeature, name: e.target.value })}
          style={{ marginBottom: 16 }}
        />
        <Space direction="vertical" style={{ width: '100%', marginBottom: 16 }}>
          <Text>{t("Select color")}</Text>
          <ColorPicker
            value={newFeature.color}
            onChange={(color) => setNewFeature({ ...newFeature, color: color.toHexString() })}
            presets={[
              {
                label: t('Recent Colors'),
                colors: recentColors
              }
            ]}
          />
        </Space>
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder={t("Enter tags")}
          onChange={(tags) => setNewFeature({ ...newFeature, tags })}
          options={userTags.map(tag => ({ value: tag, label: tag }))}
        />
      </Modal>

      <Modal
        title={t("Edit Feature")}
        visible={editModalVisible}
        onOk={handleEditFeature}
        onCancel={() => setEditModalVisible(false)}
      >
        <Input
          value={editedFeature?.name}
          onChange={(e) => setEditedFeature({ ...editedFeature, name: e.target.value })}
          style={{ marginBottom: 16 }}
        />
        <Space direction="vertical" style={{ width: '100%', marginBottom: 16 }}>
          <Text>{t("Select color")}</Text>
          <ColorPicker
            value={editedFeature?.color}
            onChange={(color) => setEditedFeature({ ...editedFeature, color: color.toHexString() })}
            presets={[
              {
                label: t('Recent Colors'),
                colors: recentColors
              }
            ]}
          />
        </Space>
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder={t("Enter tags")}
          value={editedFeature?.tags}
          onChange={(tags) => setEditedFeature({ ...editedFeature, tags })}
          options={userTags.map(tag => ({ value: tag, label: tag }))}
        />
      </Modal>

      <Modal
        title={t("Add a New Note")}
        visible={addNoteModalVisible}
        onOk={handleAddNote}
        onCancel={() => setAddNoteModalVisible(false)}
      >
        <Input
          placeholder={t("Title")}
          value={newNote.title}
          onChange={(e) => setNewNote({ ...newNote, title: e.target.value })}
          style={{ marginBottom: 16 }}
        />
        <Input.TextArea
          placeholder={t("Content")}
          value={newNote.content}
          onChange={(e) => setNewNote({ ...newNote, content: e.target.value })}
          rows={4}
        />
      </Modal>

      <Modal
        title={t("Project Settings")}
        visible={projectSettingsModalVisible}
        onOk={handleUpdateProject}
        onCancel={() => setProjectSettingsModalVisible(false)}
        width={500}
      >
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <Input
            placeholder={t("Project Name")}
            value={projectData.name}
            onChange={(e) => setProjectData({ ...projectData, name: e.target.value })}
          />
          <div>
            <Text>{t("Project Color")}</Text>
            <ColorPicker
              value={projectData.color}
              onChange={(color) => setProjectData({ ...projectData, color: color.toHexString() })}
              presets={[
                {
                  label: t('Recent Colors'),
                  colors: recentColors
                }
              ]}
            />
          </div>
          <div>
            <Text>{t("Project Tags")}</Text>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder={t("Enter tags")}
              value={projectData.tags}
              onChange={(tags) => setProjectData({ ...projectData, tags })}
              options={userTags.map(tag => ({ value: tag, label: tag }))}
            />
          </div>
          <Divider />
          <div>
            <Title level={5} style={{ color: '#ff4d4f' }}>{t("Danger Zone")}</Title>
            <Popconfirm
              title={t("Are you sure you want to delete this project? This action cannot be undone.")}
              onConfirm={handleDeleteProject}
              okText={t("Yes, delete project")}
              cancelText={t("Cancel")}
              okButtonProps={{ danger: true }}
            >
              <Button danger icon={<DeleteOutlined />}>
                {t("Delete Project")}
              </Button>
            </Popconfirm>
          </div>
        </Space>
      </Modal>

      <Drawer
        title={t("Latest Activities")}
        placement="right"
        onClose={() => setActivitiesDrawerVisible(false)}
        visible={activitiesDrawerVisible}
        width={320}
      >
        <List
          itemLayout="horizontal"
          dataSource={activities.slice(0, 10)}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={<Text strong>{item.type}</Text>}
                description={
                  <Space direction="vertical">
                    <Text>{item.details}</Text>
                    <Text type="secondary">
                      {formatDistanceToNow(item.timestamp, { addSuffix: true })}
                    </Text>
                  </Space>
                }
              />
            </List.Item>
          )}
        />
      </Drawer>

      <AnimatePresence>
        {showOnboarding && renderOnboardingModal()}
        {showOnboarding && renderHighlight()}
      </AnimatePresence>
    </div>
  );
}

export default ProjectDashboard;