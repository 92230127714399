import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, onSnapshot, addDoc, doc, updateDoc, deleteDoc, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import VerticalMenu from '../VerticalMenu';
import { Modal, Input, Button, Card } from 'antd';
import { EditOutlined, DeleteOutlined, FolderOpenOutlined } from '@ant-design/icons';

function NotesPage() {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const [folders, setFolders] = useState([]);
  const [newFolderName, setNewFolderName] = useState('');
  const [editFolderName, setEditFolderName] = useState('');
  const [activeFolder, setActiveFolder] = useState(null);
  const [isAddFolderModalVisible, setIsAddFolderModalVisible] = useState(false);
  const [isEditFolderModalVisible, setIsEditFolderModalVisible] = useState(false);

  useEffect(() => {
    const foldersRef = collection(db, 'folders');
    const q = query(foldersRef, where('userId', '==', currentUser.uid));
    const unsubscribe = onSnapshot(q, snapshot => {
      const fetchedFolders = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setFolders(fetchedFolders);
    });

    return () => unsubscribe();
  }, [currentUser]);

  const showAddFolderModal = () => {
    setIsAddFolderModalVisible(true);
  };

  const handleAddFolder = async () => {
    if (newFolderName.trim()) {
      await addDoc(collection(db, 'folders'), { name: newFolderName, userId: currentUser.uid });
      setNewFolderName('');
      setIsAddFolderModalVisible(false);
    }
  };

  const showEditFolderModal = (folder) => {
    setEditFolderName(folder.name);
    setActiveFolder(folder);
    setIsEditFolderModalVisible(true);
  };

  const handleEditFolder = async () => {
    if (activeFolder && editFolderName.trim()) {
      const folderRef = doc(db, 'folders', activeFolder.id);
      await updateDoc(folderRef, { name: editFolderName });
      setIsEditFolderModalVisible(false);
      setActiveFolder(null);
    }
  };

  const handleDeleteFolder = async (folderId) => {
    const folderRef = doc(db, 'folders', folderId);
    await deleteDoc(folderRef);
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <VerticalMenu />
      <div style={{ flex: 1,  flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '100px' }}>
        <h1>Notes</h1>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '90%', marginBottom: '20px' }}>
          <Button type="primary" onClick={showAddFolderModal}>
            Add Folder
          </Button>
        </div>
        <div style={{ width: '90%', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px' }}>
          {folders.map(folder => (
            <Card
              key={folder.id}
              style={{ borderRadius: '10px', border: '1px solid #f0f0f0', overflow: 'hidden', cursor: 'pointer' }}
              onClick={() => window.location.href = `/notes/${folder.id}`}
              actions={[
                <EditOutlined key="edit" onClick={(e) => { e.stopPropagation(); showEditFolderModal(folder); }} />,
                <DeleteOutlined key="delete" onClick={(e) => { e.stopPropagation(); handleDeleteFolder(folder.id); }} style={{ color: 'red' }} />,
              ]}
            >
              <Card.Meta
                
                title={<span style={{ textDecoration: 'none', color: 'black' }}>{folder.name}</span>}
              />
            </Card>
          ))}
        </div>
      </div>
      <Modal
        title="Add New Folder"
        visible={isAddFolderModalVisible}
        onOk={handleAddFolder}
        onCancel={() => setIsAddFolderModalVisible(false)}
      >
        <Input
          placeholder="Folder Name"
          value={newFolderName}
          onChange={e => setNewFolderName(e.target.value)}
        />
      </Modal>
      <Modal
        title="Edit Folder Name"
        visible={isEditFolderModalVisible}
        onOk={handleEditFolder}
        onCancel={() => setIsEditFolderModalVisible(false)}
      >
        <Input
          placeholder="Folder Name"
          value={editFolderName}
          onChange={e => setEditFolderName(e.target.value)}
        />
      </Modal>
      <Modal
        title="Delete Folder"
        visible={!!activeFolder && !isEditFolderModalVisible}
        onOk={() => handleDeleteFolder(activeFolder.id)}
        onCancel={() => setActiveFolder(null)}
      >
        <p>Are you sure you want to delete this folder?</p>
      </Modal>
    </div>
  );
  
}

export default NotesPage;
