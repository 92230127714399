import React, { useState, useEffect } from 'react';
import { Typography, Button, Input, message } from 'antd';
import { Link } from 'react-router-dom';
import { HomeFilled, LockFilled, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const { Title, Text } = Typography;

const PageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f0f2f5;
  overflow: hidden;
  position: relative;
`;

const GameArea = styled.div`
  width: 300px;
  border: 2px solid #ff4d4f;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  background: white;
`;

const PasswordDisplay = styled.div`
  font-family: monospace;
  font-size: 24px;
  margin-bottom: 10px;
  letter-spacing: 4px;
`;

const Attempts = styled.div`
  margin-top: 10px;
  font-size: 16px;
`;

const generatePassword = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return Array(6).fill().map(() => chars[Math.floor(Math.random() * chars.length)]).join('');
};

const Unauthorized = () => {
  const [password, setPassword] = useState(generatePassword());
  const [guess, setGuess] = useState('');
  const [maskedPassword, setMaskedPassword] = useState('******');
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    setMaskedPassword('******');
  }, [password]);

  const handleGuess = () => {
    setAttempts(attempts + 1);
    let newMasked = '';
    for (let i = 0; i < password.length; i++) {
      if (guess[i] === password[i]) {
        newMasked += password[i];
      } else {
        newMasked += '*';
      }
    }
    setMaskedPassword(newMasked);

    if (guess === password) {
      message.success('Congratulations! You cracked the password!');
      setPassword(generatePassword());
      setAttempts(0);
    } else {
      message.error('Access Denied. Try again!');
    }
    setGuess('');
  };

  return (
    <PageContainer>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Title style={{ fontSize: '80px', margin: 0, color: '#ff4d4f' }}>401</Title>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Title level={2} style={{ margin: '0 0 20px 0' }}>Unauthorized Access!</Title>
      </motion.div>
      <Text style={{ fontSize: '18px', marginBottom: '30px' }}>
        Think you can crack our security? Give it a try!
      </Text>
      <GameArea>
        <Title level={4}>Password Cracker</Title>
        <PasswordDisplay>{maskedPassword}</PasswordDisplay>
        <Input 
          prefix={<LockFilled />}
          value={guess}
          onChange={(e) => setGuess(e.target.value)}
          maxLength={6}
          style={{ marginBottom: '10px' }}
        />
        <Button type="primary" onClick={handleGuess} style={{ width: '100%' }}>
          Attempt Hack
        </Button>
        <Attempts>Attempts: {attempts}</Attempts>
      </GameArea>
      <Link to="/">
        <Button type="primary" icon={<HomeFilled />} size="large">
          Back to Home
        </Button>
      </Link>
    </PageContainer>
  );
};

export default Unauthorized;