import React, { useState, useEffect } from 'react';
import { Layout, Menu, Card, Switch, Input, Button, Form, Typography, Row, Col, Tabs, Select, message } from 'antd';
import { UserOutlined, LockOutlined, GlobalOutlined, BugOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { auth, signOut, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, query, getDocs, doc, updateDoc } from 'firebase/firestore';
import { sendPasswordResetEmail, updateProfile } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import VerticalMenu from './VerticalMenu';


const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const SettingsPage = () => {
  const { t } = useTranslation();
  const user = auth.currentUser;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') || 'en');
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  useEffect(() => {
    if (user) {
      const [fName, lName] = user.displayName ? user.displayName.split(' ') : ['', ''];
      setFirstName(fName);
      setLastName(lName);
      form.setFieldsValue({ firstName: fName, lastName: lName, email: user.email });
    }
  }, [user, form]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error("Error signing out:", error);
      message.error(t('Error signing out'));
    }
  };

  const handlePasswordReset = async () => {
    if (user && user.email) {
      try {
        await sendPasswordResetEmail(auth, user.email);
        message.success(t('Password reset email sent successfully'));
      } catch (error) {
        console.error('Error sending password reset email:', error);
        message.error(t('Error sending password reset email'));
      }
    }
  };

  const handleProfileUpdate = async (values) => {
    setLoading(true);
    try {
      await updateProfile(user, {
        displayName: `${values.firstName} ${values.lastName}`,
      });
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        firstName: values.firstName,
        lastName: values.lastName,
      });
      message.success(t('Profile updated successfully'));
      setFirstName(values.firstName);
      setLastName(values.lastName);
    } catch (error) {
      console.error('Error updating profile:', error);
      message.error(t('Error updating profile'));
    } finally {
      setLoading(false);
    }
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
    message.success(t('Language changed successfully'));
  };

  const handleThemeChange = (checked) => {
    const newTheme = checked ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.body.className = newTheme === 'dark' ? 'dark-theme' : 'light-theme';
    message.success(t('Theme changed successfully'));
  };

  const handleBugReport = async (values) => {
    setLoading(true);
    try {
      await addDoc(collection(db, 'bugs'), {
        email: user.email,
        category: values.category,
        message: values.message,
        reportedAt: new Date(),
      });
      message.success(t('Bug report submitted successfully'));
      form.resetFields(['category', 'message']);
    } catch (error) {
      console.error('Error submitting bug report:', error);
      message.error(t('Error submitting bug report'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f0f2f5' }}>
    <VerticalMenu />
    <div style={{ flex: 1, marginLeft: '10px', padding: '20px', overflowY: 'auto', marginTop: '50px' }}>
      
    <Layout className="site-layout">
      <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
        <Title level={2}>{t('Settings')}</Title>
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={<span><UserOutlined />{t('Profile')}</span>}
            key="1"
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={handleProfileUpdate}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="firstName"
                    label={t('First Name')}
                    rules={[{ required: true, message: t('Please input your first name!') }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastName"
                    label={t('Last Name')}
                    rules={[{ required: true, message: t('Please input your last name!') }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="email"
                label={t('Email')}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t('Update Profile')}
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane
            tab={<span><LockOutlined />{t('Security')}</span>}
            key="2"
          >
            <Button onClick={handlePasswordReset}>{t('Change Password')}</Button>
          </TabPane>
          <TabPane
            tab={<span><GlobalOutlined />{t('Preferences')}</span>}
            key="3"
          >
            <Form layout="vertical">
              <Form.Item label={t('Language')}>
                <Select value={language} onChange={handleLanguageChange}>
                  <Option value="en">English</Option>
                  <Option value="fr">Français</Option>
                  <Option value="es">Español</Option>
                  <Option value="de">Deutsch</Option>
                </Select>
              </Form.Item>
              <Form.Item label={t('Theme')}>
                <Switch
                  checked={theme === 'dark'}
                  onChange={handleThemeChange}
                  checkedChildren={t('Dark')}
                  unCheckedChildren={t('Light')}
                />
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane
            tab={<span><BugOutlined />{t('Report Bug')}</span>}
            key="4"
          >
            <Form layout="vertical" onFinish={handleBugReport}>
              <Form.Item
                name="category"
                label={t('Category')}
                rules={[{ required: true, message: t('Please select a category!') }]}
              >
                <Select placeholder={t('Select a category')}>
                  <Option value="Dashboard">{t('Dashboard')}</Option>
                  <Option value="Projects">{t('Projects')}</Option>
                  <Option value="Objectives">{t('Objectives')}</Option>
                  <Option value="Roadmap">{t('Roadmap')}</Option>
                  <Option value="Task">{t('Task')}</Option>
                  <Option value="Notes">{t('Notes')}</Option>
                  <Option value="Other">{t('Other')}</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="message"
                label={t('Message')}
                rules={[{ required: true, message: t('Please input your message!') }]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t('Send Bug Report')}
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane
            tab={<span><InfoCircleOutlined />{t('About')}</span>}
            key="5"
          >
            <Text>{t('SoloMage Version')} 1.0.0</Text>
            <br />
            <Text>{t('© 2024 SoloMage. All rights reserved.')}</Text>
          </TabPane>
        </Tabs>
        <Button onClick={handleSignOut} style={{ marginTop: 20 }} danger>
          {t('Sign Out')}
        </Button>
      </Content>
    </Layout>
    </div>
    </div>
  );
};

export default SettingsPage;