import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase';
import { collection, query, where, getDocs, getDoc, doc, addDoc, deleteDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { 
  Layout, Typography, Avatar, Space, Button, message, Table, Popconfirm, Card, 
  Statistic, Row, Col, Input, Modal, Form, Select, Tabs, Tag, Tooltip, Dropdown, Menu
} from 'antd';
import { 
  EditOutlined, DeleteOutlined, PlusOutlined, MailOutlined, 
  InfoCircleOutlined, TeamOutlined, DollarOutlined, FileTextOutlined,
  FileDoneOutlined, MoreOutlined, SaveOutlined, GlobalOutlined
} from '@ant-design/icons';
import VerticalMenu from '../VerticalMenu';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const Company = () => {
  const { t } = useTranslation();
  const [company, setCompany] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [deals, setDeals] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  const [isDealModalVisible, setIsDealModalVisible] = useState(false);
  const [isCompanyInfoModalVisible, setIsCompanyInfoModalVisible] = useState(false);
  const [currentDeal, setCurrentDeal] = useState(null);
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [contactForm] = Form.useForm();
  const [dealForm] = Form.useForm();
  const [companyForm] = Form.useForm();
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!userId) return;

    const fetchCompany = async () => {
      const companyRef = doc(db, 'companies', companyId);
      const companySnap = await getDoc(companyRef);
      if (companySnap.exists()) {
        setCompany({ id: companySnap.id, ...companySnap.data() });
      } else {
        message.error(t('Company not found'));
        navigate('/companies');
      }
    };

    fetchCompany();
    const unsubscribeContacts = fetchContacts();
    const unsubscribeDeals = fetchDeals();
    const unsubscribeInvoices = fetchInvoices();
    fetchIndustries();

    return () => {
      unsubscribeContacts();
      unsubscribeDeals();
      unsubscribeInvoices();
    };
  }, [companyId, navigate, t, userId]);

  const fetchContacts = () => {
    const contactsRef = collection(db, 'contacts');
    const q = query(contactsRef, where('companyId', '==', companyId), where('userId', '==', userId));
    return onSnapshot(q, (snapshot) => {
      setContacts(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }, (error) => {
      console.error("Error fetching contacts: ", error);
      message.error(t("Failed to fetch contacts"));
    });
  };

  const fetchDeals = () => {
    const dealsRef = collection(db, 'deals');
    const q = query(dealsRef, where('companyId', '==', companyId));
    return onSnapshot(q, (snapshot) => {
      setDeals(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }, (error) => {
      console.error("Error fetching deals: ", error);
      message.error(t("Failed to fetch deals"));
    });
  };

  const fetchInvoices = () => {
    const invoicesRef = collection(db, 'invoices');
    const q = query(invoicesRef, where('companyId', '==', companyId));
    return onSnapshot(q, (snapshot) => {
      setInvoices(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }, (error) => {
      console.error("Error fetching invoices: ", error);
      message.error(t("Failed to fetch invoices"));
    });
  };

  const fetchIndustries = async () => {
    try {
      const industriesRef = collection(db, 'industries');
      const industriesSnap = await getDocs(industriesRef);
      setIndustries(industriesSnap.docs.map(doc => doc.data().name));
    } catch (error) {
      console.error("Error fetching industries: ", error);
      message.error(t("Failed to fetch industries"));
    }
  };

  const handleAddContact = async (values) => {
    try {
      await addDoc(collection(db, 'contacts'), {
        ...values,
        companyId: companyId,
        companyName: company.name,
        userId: userId,
      });
      message.success(t('Contact added successfully'));
      setIsContactModalVisible(false);
      contactForm.resetFields();
    } catch (error) {
      console.error('Error adding contact: ', error);
      message.error(t('Error adding contact'));
    }
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await deleteDoc(doc(db, 'contacts', contactId));
      message.success(t('Contact deleted successfully'));
    } catch (error) {
      console.error("Error deleting contact: ", error);
      message.error(t("Error deleting contact"));
    }
  };

  const handleAddOrUpdateDeal = async (values) => {
    try {
      if (currentDeal) {
        await updateDoc(doc(db, 'deals', currentDeal.id), values);
        message.success(t('Deal updated successfully'));
      } else {
        await addDoc(collection(db, 'deals'), {
          ...values,
          companyId: companyId,
          userId: userId,
        });
        message.success(t('Deal added successfully'));
      }
      setIsDealModalVisible(false);
      setCurrentDeal(null);
      dealForm.resetFields();
    } catch (error) {
      console.error('Error adding/updating deal: ', error);
      message.error(t('Error adding/updating deal'));
    }
  };

  const handleDeleteDeal = async (dealId) => {
    try {
      await deleteDoc(doc(db, 'deals', dealId));
      message.success(t('Deal deleted successfully'));
    } catch (error) {
      console.error("Error deleting deal: ", error);
      message.error(t("Error deleting deal"));
    }
  };

  const handleUpdateCompanyInfo = async (values) => {
    try {
      const updatedValues = Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v !== undefined)
      );
      await updateDoc(doc(db, 'companies', companyId), updatedValues);
      setCompany({ ...company, ...updatedValues });
      message.success(t('Company info updated successfully'));
      setIsCompanyInfoModalVisible(false);
    } catch (error) {
      console.error('Error updating company info: ', error);
      message.error(t('Error updating company info'));
    }
  };

  const handleQuickEditInvoice = async (invoiceId, field, value) => {
    try {
      await updateDoc(doc(db, 'invoices', invoiceId), { [field]: value });
      message.success(t('Invoice updated successfully'));
      setEditingInvoice(null);
    } catch (error) {
      console.error('Error updating invoice: ', error);
      message.error(t('Error updating invoice'));
    }
  };

  const statusOptions = [
    { label: t('New'), value: 'New', color: 'blue' },
    { label: t('Contacted'), value: 'Contacted', color: 'orange' },
    { label: t('Interested'), value: 'Interested', color: 'cyan' },
    { label: t('Qualified'), value: 'Qualified', color: 'geekblue' },
    { label: t('Negotiation'), value: 'Negotiation', color: 'purple' },
    { label: t('Won'), value: 'Won', color: 'green' },
  ];

  const dealStages = [
    { label: t('New'), value: 'New' },
    { label: t('Qualified'), value: 'Qualified' },
    { label: t('Proposal'), value: 'Proposal' },
    { label: t('Negotiation'), value: 'Negotiation' },
    { label: t('Closed Won'), value: 'Closed Won' },
    { label: t('Closed Lost'), value: 'Closed Lost' },
  ];

  const contactColumns = [
    {
      title: t('Contact'),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Avatar style={{ backgroundColor: record.color || '#f56a00' }}>{text[0].toUpperCase()}</Avatar>
      ),
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        const status = statusOptions.find(s => s.value === text);
        return <Tag color={status?.color || 'default'}>{status?.label || text}</Tag>;
      },
    },
    {
      title: t('Actions'),
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Tooltip title={t('Send Email')}>
            <Button icon={<MailOutlined />} href={`mailto:${record.email}`} />
          </Tooltip>
          <Tooltip title={t('Delete')}>
            <Popconfirm
              title={t('Are you sure you want to delete this contact?')}
              onConfirm={() => handleDeleteContact(record.id)}
              okText={t('Yes')}
              cancelText={t('No')}
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const dealColumns = [
    {
      title: t('Deal Title'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('Amount'),
      dataIndex: 'amount',
      key: 'amount',
      render: amount => `$${Number(amount).toLocaleString()}`,
    },
    {
      title: t('Stage'),
      dataIndex: 'stage',
      key: 'stage',
      render: stage => {
        const stageOption = dealStages.find(s => s.value === stage);
        return <Tag color={stage === 'Closed Won' ? 'green' : stage === 'Closed Lost' ? 'red' : 'blue'}>
          {stageOption ? stageOption.label : stage}
        </Tag>;
      },
    },
    {
      title: t('Actions'),
      key: 'actions',
      render: (_, record) => (
        <Dropdown overlay={
          <Menu>
            <Menu.Item key="edit" onClick={() => {
              setCurrentDeal(record);
              dealForm.setFieldsValue(record);
              setIsDealModalVisible(true);
            }}>
              <EditOutlined /> {t('Edit')}
            </Menu.Item>
            <Menu.Item key="delete" onClick={() => handleDeleteDeal(record.id)}>
              <DeleteOutlined /> {t('Delete')}
            </Menu.Item>
          </Menu>
        }>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const invoiceColumns = [
    {
      title: t('Invoice Number'),
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      render: (text, record) => (
        editingInvoice === record.id ? (
          <Input
            defaultValue={text}
            onPressEnter={(e) => handleQuickEditInvoice(record.id, 'invoiceNumber', e.target.value)}
            onBlur={(e) => handleQuickEditInvoice(record.id, 'invoiceNumber', e.target.value)}
          />
        ) : (
          <span>{text}</span>
        )
      ),
    },
    {
      title: t('Date'),
      dataIndex: 'date',
      key: 'date',
      render: date => new Date(date.seconds * 1000).toLocaleDateString(),
    },
    {
      title: t('Amount'),
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: amount => `$${Number(amount).toLocaleString()}`,
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        editingInvoice === record.id ? (
          <Select
            defaultValue={status}
            style={{ width: 120 }}
            onChange={(value) => handleQuickEditInvoice(record.id, 'status', value)}
          >
            <Option value="Paid">{t('Paid')}</Option>
            <Option value="Unpaid">{t('Unpaid')}</Option>
            <Option value="Overdue">{t('Overdue')}</Option>
          </Select>
        ) : (
          <Tag color={status === 'Paid' ? 'green' : status === 'Overdue' ? 'red' : 'orange'}>{status}</Tag>
        )
      ),
    },
    {
      title: t('Actions'),
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button onClick={() => navigate(`/invoices/${record.id}`)}>{t('View')}</Button>
          <Button 
           icon={editingInvoice === record.id ? <SaveOutlined /> : <EditOutlined />} 
           onClick={() => setEditingInvoice(editingInvoice === record.id ? null : record.id)}
         />
       </Space>
     ),
   },
 ];

 const totalDealAmount = useMemo(() => {
   return deals.reduce((sum, deal) => sum + (Number(deal.amount) || 0), 0);
 }, [deals]);

 const getInitials = (name) => {
   return name?.split(' ').map(n => n[0]).join('').toUpperCase() || '';
 };

 return (
   <div style={{ display: 'flex', height: '100vh' }}>
   <VerticalMenu />
   <div style={{ flex: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
    
   <Layout style={{ minHeight: '100vh' }}>
    
     <Layout>
       <Header style={{ background: '#fff', padding: 0, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' }}>
         <Row justify="space-between" align="middle" style={{ height: '100%', padding: '0 24px' }}>
           <Col>
             <Space size="large">
               <Avatar size={64} style={{ backgroundColor: company?.color || '#f56a00', fontSize: 32 }}>
                 {company ? getInitials(company.name) : ''}
               </Avatar>
               <Title level={2} style={{ margin: 0 }}>{company?.name}</Title>
               {company?.website && (
                 <Tooltip title={t('Visit Website')}>
                   <Button 
                     icon={<GlobalOutlined />} 
                     href={company.website.startsWith('http') ? company.website : `http://${company.website}`} 
                     target="_blank"
                     rel="noopener noreferrer"
                   />
                 </Tooltip>
               )}
             </Space>
           </Col>
           <Col>
             <Space>
               <Tooltip title={t('Edit Company Info')}>
                 <Button icon={<InfoCircleOutlined />} onClick={() => {
                   companyForm.setFieldsValue(company);
                   setIsCompanyInfoModalVisible(true);
                 }} />
               </Tooltip>
               <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsContactModalVisible(true)}>
                 {t('Add Contact')}
               </Button>
               <Button type="primary" icon={<PlusOutlined />} onClick={() => {
                 setCurrentDeal(null);
                 dealForm.resetFields();
                 setIsDealModalVisible(true);
               }}>
                 {t('Add Deal')}
               </Button>
             </Space>
           </Col>
         </Row>
       </Header>
       <Content style={{ margin: '24px 16px 0' }}>
         <div style={{ padding: 24, minHeight: 360, background: '#fff' }}>
           <Row gutter={[16, 16]}>
             <Col xs={24} sm={12} md={8} lg={6}>
               <Card>
                 <Statistic
                   title={t("Total Contacts")}
                   value={contacts.length}
                   prefix={<TeamOutlined />}
                 />
               </Card>
             </Col>
             <Col xs={24} sm={12} md={8} lg={6}>
               <Card>
                 <Statistic
                   title={t("Total Deals")}
                   value={deals.length}
                   prefix={<FileTextOutlined />}
                 />
               </Card>
             </Col>
             <Col xs={24} sm={12} md={8} lg={6}>
               <Card>
                 <Statistic
                   title={t("Total Deal Amount")}
                   value={totalDealAmount}
                   prefix={<DollarOutlined />}
                   precision={2}
                 />
               </Card>
             </Col>
             <Col xs={24} sm={12} md={8} lg={6}>
               <Card>
                 <Statistic
                   title={t("Win Rate")}
                   value={(deals.filter(d => d.stage === 'Closed Won').length / deals.length) * 100}
                   suffix="%"
                   precision={1}
                 />
               </Card>
             </Col>
           </Row>
           <Tabs defaultActiveKey="1" style={{ marginTop: 16 }}>
             <TabPane tab={<span><TeamOutlined />{t('Contacts')}</span>} key="1">
               <Table 
                 dataSource={contacts} 
                 columns={contactColumns} 
                 rowKey="id"
                 components={{
                   body: {
                     row: motion.tr,
                     cell: motion.td
                   }
                 }}
               />
             </TabPane>
             <TabPane tab={<span><DollarOutlined />{t('Deals')}</span>} key="2">
               <Table 
                 dataSource={deals} 
                 columns={dealColumns} 
                 rowKey="id"
                 components={{
                   body: {
                     row: motion.tr,
                     cell: motion.td
                   }
                 }}
               />
             </TabPane>
             <TabPane tab={<span><FileDoneOutlined />{t('Invoices')}</span>} key="3">
               <Space style={{ marginBottom: 16 }}>
                 <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate(`/invoices/new?companyId=${companyId}`)}>
                   {t('Create Invoice')}
                 </Button>
               </Space>
               <Table 
                 dataSource={invoices} 
                 columns={invoiceColumns} 
                 rowKey="id"
                 components={{
                   body: {
                     row: motion.tr,
                     cell: motion.td
                   }
                 }}
               />
             </TabPane>
           </Tabs>
         </div>
       </Content>
     </Layout>
     
     {/* Contact Modal */}
     <Modal
       title={t("Add New Contact")}
       visible={isContactModalVisible}
       onCancel={() => setIsContactModalVisible(false)}
       footer={null}
     >
       <Form form={contactForm} onFinish={handleAddContact} layout="vertical">
         <Form.Item name="name" label={t("Name")} rules={[{ required: true }]}>
           <Input />
         </Form.Item>
         <Form.Item name="email" label={t("Email")} rules={[{ required: true, type: 'email' }]}>
           <Input />
         </Form.Item>
         <Form.Item name="title" label={t("Title")}>
           <Input />
         </Form.Item>
         <Form.Item name="status" label={t("Status")} initialValue="New">
           <Select>
             {statusOptions.map(option => (
               <Option key={option.value} value={option.value}>{option.label}</Option>
             ))}
           </Select>
         </Form.Item>
         <Form.Item>
           <Button type="primary" htmlType="submit">{t("Add Contact")}</Button>
         </Form.Item>
       </Form>
     </Modal>

     {/* Deal Modal */}
     <Modal
       title={currentDeal ? t("Edit Deal") : t("Add New Deal")}
       visible={isDealModalVisible}
       onCancel={() => {
         setIsDealModalVisible(false);
         setCurrentDeal(null);
         dealForm.resetFields();
       }}
       footer={null}
     >
       <Form form={dealForm} onFinish={handleAddOrUpdateDeal} layout="vertical">
         <Form.Item name="title" label={t("Deal Title")} rules={[{ required: true }]}>
           <Input />
         </Form.Item>
         <Form.Item name="amount" label={t("Amount")} rules={[{ required: true }]}>
           <Input type="number" prefix="$" />
         </Form.Item>
         <Form.Item name="stage" label={t("Stage")} rules={[{ required: true }]}>
           <Select>
             {dealStages.map(stage => (
               <Option key={stage.value} value={stage.value}>{stage.label}</Option>
             ))}
           </Select>
         </Form.Item>
         <Form.Item name="description" label={t("Description")}>
           <Input.TextArea />
         </Form.Item>
         <Form.Item>
           <Button type="primary" htmlType="submit">
             {currentDeal ? t("Update Deal") : t("Add Deal")}
           </Button>
         </Form.Item>
       </Form>
     </Modal>

     {/* Company Info Modal */}
     <Modal
       title={t("Edit Company Info")}
       visible={isCompanyInfoModalVisible}
       onCancel={() => setIsCompanyInfoModalVisible(false)}
       footer={null}
     >
       <Form form={companyForm} onFinish={handleUpdateCompanyInfo} layout="vertical">
         <Form.Item name="name" label={t("Company Name")} rules={[{ required: true }]}>
           <Input />
         </Form.Item>
         <Form.Item name="industry" label={t("Industry")}>
           <Select showSearch>
             {industries.map(industry => (
               <Option key={industry} value={industry}>{industry}</Option>
             ))}
           </Select>
         </Form.Item>
         <Form.Item name="website" label={t("Website")}>
           <Input />
         </Form.Item>
         <Form.Item name="description" label={t("Description")}>
           <Input.TextArea />
         </Form.Item>
         <Form.Item>
           <Button type="primary" htmlType="submit">
             {t("Update Company Info")}
           </Button>
         </Form.Item>
       </Form>
     </Modal>
   </Layout>
   </div>
   </div>
 );
};

export default Company;