import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, onSnapshot, addDoc, doc, updateDoc, deleteDoc, query, where, getDocs } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { db, auth } from '../../firebase';
import VerticalMenu from '../VerticalMenu';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Modal, Input, Button, Card, Tag, Space, Typography, 
  Layout, Row, Col, Tooltip, Avatar, Dropdown, Menu,
  Progress, ColorPicker, Select, Popconfirm, message, Empty, Drawer, Spin
} from 'antd';
import { 
  PlusOutlined, EditOutlined, DeleteOutlined, 
  SearchOutlined, SortAscendingOutlined, FilterOutlined, MenuOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { formatDistanceToNow } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import wizard from '../../img/wizard.png';

const { Text, Title } = Typography;
const { Header, Content } = Layout;
const { Option } = Select;

const COLORS = [
  '#FF5733', '#33FF57', '#3357FF', '#F333FF', '#FFBD33', '#33FFBD', '#5733FF', 
  '#FF33A1', '#33D4FF', '#FF8F33', '#33FF6B', '#A133FF', '#FF335E', '#33FFD4', 
  '#338AFF', '#FFD433'
];

function ProjectsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [addProjectModalVisible, setAddProjectModalVisible] = useState(false);
  const [editProjectModalVisible, setEditProjectModalVisible] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);
  const [newProject, setNewProject] = useState({ name: '', color: COLORS[0], tags: [] });
  const [allTags, setAllTags] = useState([]);
  const [recentColors, setRecentColors] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filterTag, setFilterTag] = useState('All');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const userId = auth.currentUser.uid;
  const [isLoading, setIsLoading] = useState(true);


  const isMobile = useMediaQuery({ maxWidth: 767 });

  // Onboarding state
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [highlightedElement, setHighlightedElement] = useState(null);

  // Refs for onboarding
  const addButtonRef = useRef(null);
  const searchInputRef = useRef(null);
  const filterSelectRef = useRef(null);
  const sortSelectRef = useRef(null);
  const projectCardRef = useRef(null);

  useEffect(() => {
    const projectsRef = collection(db, 'projects');
    const q = query(projectsRef, where('userId', '==', userId));
    const unsubscribe = onSnapshot(q, snapshot => {
      const fetchedProjects = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProjects(fetchedProjects);
      
      const tags = new Set();
      const colors = new Set();
      fetchedProjects.forEach(project => {
        if (project.tags) {
          project.tags.forEach(tag => tags.add(tag));
        }
        if (project.color) {
          colors.add(project.color);
        }
      });
      setAllTags(['All', ...Array.from(tags)]);
      setRecentColors(Array.from(colors).slice(0, 10));
      
      // Set loading to false after data is fetched
      setIsLoading(false);
    });

    const hasCompletedOnboarding = localStorage.getItem('hasCompletedProjectsOnboarding');
    if (!hasCompletedOnboarding) {
      setShowOnboarding(true);
    }

    return () => unsubscribe();
  }, [userId]);

  const filteredAndSortedProjects = useMemo(() => {
    return projects
      .filter(project => 
        project.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (filterTag === 'All' || (project.tags && project.tags.includes(filterTag)))
      )
      .sort((a, b) => {
        if (sortBy === 'name') {
          return sortOrder === 'asc' 
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        } else if (sortBy === 'date') {
          return sortOrder === 'asc'
            ? new Date(a.createdAt) - new Date(b.createdAt)
            : new Date(b.createdAt) - new Date(a.createdAt);
        }
        return 0;
      });
  }, [projects, searchTerm, filterTag, sortBy, sortOrder]);

  const handleAddProject = async () => {
    if (newProject.name.trim()) {
      try {
        await addDoc(collection(db, 'projects'), { 
          ...newProject,
          userId, 
          createdAt: new Date().toISOString()
        });
        setNewProject({ name: '', color: COLORS[0], tags: [] });
        setAddProjectModalVisible(false);
        message.success(t('Project added successfully'));
      } catch (error) {
        console.error("Error adding project: ", error);
        message.error(t('Error adding project'));
      }
    }
  };

  const handleEditProject = async () => {
    if (currentProject) {
      const projectRef = doc(db, 'projects', currentProject.id);
      await updateDoc(projectRef, {
        name: currentProject.name,
        color: currentProject.color,
        tags: currentProject.tags
      });
      setEditProjectModalVisible(false);
      setCurrentProject(null);
      message.success(t('Project updated successfully'));
    }
  };

  const handleDeleteProject = async (project) => {
    try {
      const featuresRef = collection(db, 'features');
      const featuresQuery = query(featuresRef, where('projectId', '==', project.id));
      const featuresSnapshot = await getDocs(featuresQuery);
      
      const batch = db.batch();
      featuresSnapshot.forEach((featureDoc) => {
        batch.delete(featureDoc.ref);
        
        const tasksRef = collection(db, 'tasks');
        const tasksQuery = query(tasksRef, where('featureId', '==', featureDoc.id));
        getDocs(tasksQuery).then(tasksSnapshot => {
          tasksSnapshot.forEach(taskDoc => {
            batch.delete(taskDoc.ref);
          });
        });
      });
      
      batch.delete(doc(db, 'projects', project.id));
      
      await batch.commit();
      message.success(t('Project and associated items deleted successfully'));
    } catch (error) {
      console.error("Error deleting project: ", error);
      message.error(t('Error deleting project'));
    }
  };

  const getInitials = (name) => {
    return name.split(' ').slice(0, 2).map(word => word[0]).join('').toUpperCase();
  };

  const formatDate = (dateString) => {
    if (!dateString) return t('No date');
    try {
      return formatDistanceToNow(new Date(dateString), { addSuffix: true });
    } catch (error) {
      console.error('Error formatting date:', error);
      return t('Invalid date');
    }
  };

  const onboardingSteps = [
    {
      title: t('Welcome to Projects!'),
      content: t('Here you can manage all your projects. Let\'s take a quick tour!'),
      target: null,
    },
    {
      title: t('Add a New Project'),
      content: t('Click this button to create a new project. You can set a name, color, and tags.'),
      target: addButtonRef,
    },
    {
      title: t('Search Projects'),
      content: t('Use this search bar to find specific projects quickly.'),
      target: searchInputRef,
    },
    {
      title: t('Filter Projects'),
      content: t('Filter your projects by tags to organize them better.'),
      target: filterSelectRef,
    },
    {
      title: t('Sort Projects'),
      content: t('Sort your projects by name or date to find what you need.'),
      target: sortSelectRef,
    },
    {
      title: t('Project Cards'),
      content: t('Each card represents a project. Click on a card to view its details and features.'),
      target: projectCardRef,
    },
    {
      title: t('That\'s it!'),
      content: t('You\'re all set to start managing your projects. Happy organizing!'),
      target: null,
    },
  ];

  const handleNextStep = () => {
    if (currentStep < onboardingSteps.length - 1) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
      setHighlightedElement(onboardingSteps[nextStep].target);
    } else {
      setShowOnboarding(false);
      setHighlightedElement(null);
      localStorage.setItem('hasCompletedProjectsOnboarding', 'true');
    }
  };
  
  const handlePrevStep = () => {
    if (currentStep > 0) {
      const prevStep = currentStep - 1;
      setCurrentStep(prevStep);
      setHighlightedElement(onboardingSteps[prevStep].target);
    }
  };
  
  const handleSkipTutorial = () => {
    setShowOnboarding(false);
    setHighlightedElement(null);
    localStorage.setItem('hasCompletedProjectsOnboarding', 'true');
  };
  
  const startTutorial = () => {
    setCurrentStep(0);
    setShowOnboarding(true);
    setHighlightedElement(onboardingSteps[0].target);
  };

  const renderOnboardingModal = () => (
    <Modal
      visible={showOnboarding}
      footer={null}
      closable={false}
      width={400}
      style={{ top: 20 }}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
    >
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.3 }}
      >
        <motion.img 
          src={wizard} 
          alt="Wizard" 
          style={{ width: 100, marginBottom: 20 }}
          initial={{ rotate: 0 }}
          animate={{ rotate: [0, -10, 10, -10, 0] }}
          transition={{ duration: 2, loop: Infinity, ease: "easeInOut" }}
        />
        <Title level={3}>{onboardingSteps[currentStep].title}</Title>
        <Text>{onboardingSteps[currentStep].content}</Text>
        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'space-between' }}>
          {currentStep > 0 && (
            <Button onClick={handlePrevStep}>{t('Previous')}</Button>
          )}
          <Button type="primary" onClick={handleNextStep}>
            {currentStep === onboardingSteps.length - 1 ? t('Finish') : t('Next')}
          </Button>
          {currentStep < onboardingSteps.length - 1 && (
            <Button onClick={handleSkipTutorial}>{t('Skip')}</Button>
          )}
        </div>
      </motion.div>
    </Modal>
  );

  const renderHighlight = () => {
    if (!highlightedElement || !highlightedElement.current || typeof highlightedElement.current.getBoundingClientRect !== 'function') {
      return null;
    }
  
    const rect = highlightedElement.current.getBoundingClientRect();
    const padding = 10;
  
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        style={{
          position: 'fixed',
          top: rect.top - padding,
          left: rect.left - padding,
          width: rect.width + padding * 2,
          height: rect.height + padding * 2,
          border: '2px solid #1890ff',
          borderRadius: '4px',
          boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
          pointerEvents: 'none',
        }}
      />
    );
  };

  const renderProjectCard = (project) => (
    <motion.div
      key={project.id}
      layout
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
      className="project-card"
      ref={projectCardRef}
    >
      <Card
        hoverable
        style={{ 
          height: '100%',
          borderTop: `3px solid ${project.color}`,
          borderRadius: '8px',
          overflow: 'hidden'
        }}
        onClick={() => navigate(`/projects/${project.id}/features`)}
      >
        <div style={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}>
          <Avatar size={isMobile ? 48 : 64} style={{ backgroundColor: project.color, fontSize: isMobile ? '18px' : '24px' }}>
            {getInitials(project.name)}
          </Avatar>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1" icon={<EditOutlined />} onClick={(e) => {
                  e.stopPropagation();
                  setCurrentProject(project);
                  setEditProjectModalVisible(true);
                }}>
                  {t('Edit')}
                </Menu.Item>
                <Menu.Item key="2" icon={<DeleteOutlined />} onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteProject(project);
                }}>
                  {t('Delete')}
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button size="small" icon={<EditOutlined />} onClick={e => e.stopPropagation()} />
          </Dropdown>
        </div>
        <Title level={isMobile ? 5 : 4} style={{ marginBottom: '8px' }}>{project.name}</Title>
        <Text type="secondary" style={{ display: 'block', marginBottom: '16px', fontSize: isMobile ? '12px' : '14px' }}>
          {t('Created')}: {formatDate(project.createdAt)}
        </Text>
        <Space wrap>
          {project.tags && project.tags.map((tag, index) => (
            <Tag key={index} style={{ marginBottom: 4, fontSize: isMobile ? '10px' : '12px' }}>{tag}</Tag>
          ))}
        </Space>
      </Card>
    </motion.div>
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <Spin size="large" tip={t('Loading projects...')} />
        </div>
      );
    }

    if (projects.length === 0) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('No projects found')}
        >
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setAddProjectModalVisible(true)}>
            {t('Add Project')}
          </Button>
        </Empty>
      );
    }

    return (
      <Row gutter={[24, 24]}>
        {filteredAndSortedProjects.map(project => (
          <Col xs={24} sm={12} md={8} lg={6} key={project.id}>
            {renderProjectCard(project)}
          </Col>
        ))}
      </Row>
    );
  };

  const renderHeader = () => (
    <Header style={{ background: '#fff', padding: '0 24px', boxShadow: '0 2px 8px rgba(0,0,0,0.06)' }}>
      <Row justify="space-between" align="middle" style={{ height: '100%' }}>
        <Col>
          <Title level={3} style={{ margin: 0 }}>{t('Projects')}</Title>
        </Col>
        <Col>
          {isMobile ? (
            <Space size="large">
              <Button icon={<MenuOutlined />} onClick={() => setDrawerVisible(true)} />
              <Button 
                type="primary" 
                icon={<PlusOutlined />} 
                onClick={() => setAddProjectModalVisible(true)} 
                className="add-project-button"
                ref={addButtonRef}
              />
            </Space>
          ) : (
            <Space size="large" className="search-filter-area">
              <Input
                placeholder={t("Search projects")}
                prefix={<SearchOutlined />}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: 200 }}
                ref={searchInputRef}
              />
              <Select
                style={{ width: 120 }}
                placeholder={t("Filter by tag")}
                onChange={(value) => setFilterTag(value)}
                defaultValue="All"
                ref={filterSelectRef}
              >
                {allTags.map(tag => (
                  <Option key={tag} value={tag}>{tag}</Option>
                ))}
              </Select>
              <Select
                style={{ width: 120 }}
                placeholder={t("Sort by")}
                onChange={(value) => setSortBy(value)}
                defaultValue="name"
                ref={sortSelectRef}
              >
                <Option value="name">{t("Name")}</Option>
                <Option value="date">{t("Date")}</Option>
              </Select>
              <Button 
                icon={sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortAscendingOutlined rotate={180} />} 
                onClick={() => setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc')}
              />
              <Button 
                type="primary" 
                icon={<PlusOutlined />} 
                onClick={() => setAddProjectModalVisible(true)} 
                className="add-project-button"
                ref={addButtonRef}
              >
                {t('Add Project')}
              </Button>
            </Space>
          )}
          <Tooltip title={t('Start Tutorial')}>
            <Button 
              icon={<QuestionCircleOutlined />} 
              onClick={startTutorial}
              style={{ marginLeft: 8 }}
            />
          </Tooltip>
        </Col>
      </Row>
    </Header>
  );

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <VerticalMenu />
      <div style={{ flex: 1, padding: '20px', overflowY: 'auto'}}>
        <Layout style={{ minHeight: '100vh', background: '#fff' }}>
          {renderHeader()}
          <Content style={{ padding: '24px', overflowY: 'auto' }}>
            {isMobile && (
              <Input
                placeholder={t("Search projects")}
                prefix={<SearchOutlined />}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: '100%', marginBottom: '16px' }}
                ref={searchInputRef}
              />
            )}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              {renderContent()}
            </motion.div>
          </Content>

          <Modal
            title={t("Add New Project")}
            visible={addProjectModalVisible}
            onOk={handleAddProject}
            onCancel={() => setAddProjectModalVisible(false)}
            width={isMobile ? 320 : 500}
          >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <Input
                placeholder={t("Project Name")}
                value={newProject.name}
                onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
              />
              <div>
                <Text>{t("Project Color")}</Text>
                <ColorPicker
                  value={newProject.color}
                  onChange={(color) => setNewProject({ ...newProject, color: color.toHexString() })}
                  presets={[
                    {
                      label: t('Recent Colors'),
                      colors: recentColors
                    },
                    {
                      label: t('Recommended'),
                      colors: COLORS
                    }
                  ]}
                />
              </div>
              <div>
                <Text>{t("Project Tags")}</Text>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder={t("Add tags")}
                  onChange={(tags) => setNewProject({ ...newProject, tags })}
                  options={allTags.filter(tag => tag !== 'All').map(tag => ({ value: tag, label: tag }))}
                />
              </div>
            </Space>
          </Modal>

          <Modal
            title={t("Edit Project")}
            visible={editProjectModalVisible}
            onOk={handleEditProject}
            onCancel={() => setEditProjectModalVisible(false)}
            width={isMobile ? 320 : 500}
          >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <Input
                placeholder={t("Project Name")}
                value={currentProject?.name}
                onChange={(e) => setCurrentProject({ ...currentProject, name: e.target.value })}
              />
              <div>
                <Text>{t("Project Color")}</Text>
                <ColorPicker
                  value={currentProject?.color}
                  onChange={(color) => setCurrentProject({ ...currentProject, color: color.toHexString() })}
                  presets={[
                    {
                      label: t('Recent Colors'),
                      colors: recentColors
                    },
                    {
                      label: t('Recommended'),
                      colors: COLORS
                    }
                  ]}
                />
              </div>
              <div>
                <Text>{t("Project Tags")}</Text>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder={t("Add tags")}
                  value={currentProject?.tags}
                  onChange={(tags) => setCurrentProject({ ...currentProject, tags })}
                  options={allTags.filter(tag => tag !== 'All').map(tag => ({ value: tag, label: tag }))}
                />
              </div>
            </Space>
          </Modal>

          <Drawer
            title={t("Menu")}
            placement="right"
            onClose={() => setDrawerVisible(false)}
            visible={drawerVisible}
          >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <Button type="primary" icon={<PlusOutlined />} onClick={() => {
                setAddProjectModalVisible(true);
                setDrawerVisible(false);
              }} block>
                {t('Add Project')}
              </Button>
              <Select
                style={{ width: '100%' }}
                placeholder={t("Filter by tag")}
                onChange={(value) => {
                  setFilterTag(value);
                  setDrawerVisible(false);
                }}
                defaultValue="All"
              >
                {allTags.map(tag => (
                  <Option key={tag} value={tag}>{tag}</Option>
                ))}
              </Select>
              <Select
                style={{ width: '100%' }}
                placeholder={t("Sort by")}
                onChange={(value) => {
                  setSortBy(value);
                  setDrawerVisible(false);
                }}
                defaultValue="name"
              >
                <Option value="name">{t("Name")}</Option>
                <Option value="date">{t("Date")}</Option>
              </Select>
              <Button 
                icon={sortOrder === 'asc' ? <SortAscendingOutlined /> : <SortAscendingOutlined rotate={180} />} 
                onClick={() => {
                  setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
                  setDrawerVisible(false);
                }}
                block
              >
                {sortOrder === 'asc' ? t('Ascending') : t('Descending')}
              </Button>
            </Space>
          </Drawer>

          <AnimatePresence>
            {showOnboarding && renderOnboardingModal()}
            {showOnboarding && renderHighlight()}
          </AnimatePresence>
        </Layout>
      </div>
    </div>
  );
}

export default ProjectsPage;