import React, { useState, useEffect } from 'react';


import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, onSnapshot, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import VerticalMenu from './VerticalMenu';
import { Modal, Input, Button, Card, List } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

function FolderPage() {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const { folderId } = useParams();
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState({ title: '', content: '' });
  const [activeNote, setActiveNote] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const navigate = useNavigate();
  const { projectId } = useParams(); // Assume projectId is passed in the URL


  useEffect(() => {
    const notesRef = collection(db, 'notes');
    const q = query(notesRef, where('folderId', '==', folderId), where('userId', '==', currentUser.uid));
    const unsubscribe = onSnapshot(q, snapshot => {
      const fetchedNotes = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setNotes(fetchedNotes);
    });

    return () => unsubscribe();
  }, [folderId, currentUser]);

  const showAddModal = () => {
    setIsModalVisible(true);
  };

  const handleAddNote = async () => {
    if (newNote.title.trim() && newNote.content.trim()) {
      await addDoc(collection(db, 'notes'), {
        title: newNote.title,
        content: newNote.content,
        folderId,
        userId: currentUser.uid
      });
      setNewNote({ title: '', content: '' });
      setIsModalVisible(false);
    }
  };

  const showEditModal = (note) => {
    setActiveNote(note);
    setIsEditModalVisible(true);
  };

  const handleEditNote = async () => {
    if (activeNote && activeNote.title.trim() && activeNote.content.trim()) {
      const noteRef = doc(db, 'notes', activeNote.id);
      await updateDoc(noteRef, { title: activeNote.title, content: activeNote.content });
      setIsEditModalVisible(false);
      setActiveNote(null);
    }
  };
  const renderContentWithLinks = (content) => {
   return content.split(' ').map(part =>
     part.startsWith('http') ? <a href={part} target="_blank" rel="noopener noreferrer">{part}</a> : part
   ).reduce((prev, curr) => [prev, ' ', curr]);
 };

  const handleDeleteNote = async (noteId) => {
    const noteRef = doc(db, 'notes', noteId);
    await deleteDoc(noteRef);
  };

  const handleNoteClick = (noteId) => {
    navigate(`/note-content/${noteId}`);
};

return (
  <div style={{ display: 'flex', height: '100vh', padding: '20px', backgroundColor: '#f5f5f7' }}>
    <div style={{ width: '250px', marginRight: '20px' }}>
      <VerticalMenu />
    </div>
    <div style={{ flex: 1, overflow: 'auto', backgroundColor: '#ffffff', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}>
      <Button type="primary" onClick={showAddModal} style={{ marginBottom: '20px' }}>
        Add Note
      </Button>
      <List
        itemLayout="horizontal"
        dataSource={notes}
        renderItem={note => (
          <List.Item
            actions={[
              <EditOutlined key="edit" onClick={() => showEditModal(note)} />,
              <DeleteOutlined key="delete" onClick={() => handleDeleteNote(note.id)} />
            ]}
            style={{ background: '#f9f9f9', borderRadius: '5px', margin: '10px 0', padding: '15px' }}
          >
            <List.Item.Meta
              title={<a onClick={() => handleNoteClick(note.id)} style={{ fontWeight: '500' }}>{note.title}</a>}
              description={note.content.length > 100 ? note.content.substring(0, 100) + '...' : note.content}
            />
          </List.Item>
        )}
      />
    </div>

    <Modal title="Add a New Note" visible={isModalVisible} onOk={handleAddNote} onCancel={() => setIsModalVisible(false)}>
      <Input placeholder="Title" value={newNote.title} onChange={e => setNewNote({ ...newNote, title: e.target.value })} />
      <Input.TextArea placeholder="Content" value={newNote.content} onChange={e => setNewNote({ ...newNote, content: e.target.value })} style={{ marginTop: '10px' }} />
    </Modal>

    <Modal title="Edit Note" visible={isEditModalVisible} onOk={handleEditNote} onCancel={() => setIsEditModalVisible(false)}>
      <Input placeholder="Title" value={activeNote?.title} onChange={e => setActiveNote({ ...activeNote, title: e.target.value })} />
      <Input.TextArea placeholder="Content" value={activeNote?.content} onChange={e => setActiveNote({ ...activeNote, content: e.target.value })} style={{ marginTop: '10px' }} />
    </Modal>
  </div>
);

};

export default FolderPage;
