import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, onSnapshot, addDoc, doc, updateDoc, deleteDoc, query, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { db, auth } from '../../firebase';
import VerticalMenu from '../VerticalMenu';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Modal, Input, Button, Card, Space, Typography, 
  Layout, Row, Col, Dropdown, Menu, Empty, Drawer, message,
} from 'antd';
import { 
  PlusOutlined, EditOutlined, DeleteOutlined, 
  SearchOutlined, MenuOutlined
} from '@ant-design/icons';
import { formatDistanceToNow } from 'date-fns';
import { useMediaQuery } from 'react-responsive';

const { Text, Title } = Typography;
const { Header, Content } = Layout;

function WhiteboardsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [whiteboards, setWhiteboards] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [addWhiteboardModalVisible, setAddWhiteboardModalVisible] = useState(false);
  const [editWhiteboardModalVisible, setEditWhiteboardModalVisible] = useState(false);
  const [currentWhiteboard, setCurrentWhiteboard] = useState(null);
  const [newWhiteboard, setNewWhiteboard] = useState({ name: '' });
  const [drawerVisible, setDrawerVisible] = useState(false);
  const userId = auth.currentUser.uid;

  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    const whiteboardsRef = collection(db, 'whiteboards');
    const q = query(whiteboardsRef, where('userId', '==', userId));
    const unsubscribe = onSnapshot(q, snapshot => {
      const fetchedWhiteboards = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setWhiteboards(fetchedWhiteboards);
    });
    return () => unsubscribe();
  }, [userId]);

  const filteredWhiteboards = useMemo(() => {
    return whiteboards.filter(whiteboard => 
      whiteboard.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [whiteboards, searchTerm]);

  const handleAddWhiteboard = async () => {
    if (newWhiteboard.name.trim()) {
      try {
        await addDoc(collection(db, 'whiteboards'), { 
          ...newWhiteboard,
          userId, 
          createdAt: new Date().toISOString()
        });
        setNewWhiteboard({ name: '' });
        setAddWhiteboardModalVisible(false);
        message.success(t('Whiteboard added successfully'));
      } catch (error) {
        console.error("Error adding whiteboard: ", error);
        message.error(t('Error adding whiteboard'));
      }
    }
  };

  const handleEditWhiteboard = async () => {
    if (currentWhiteboard) {
      const whiteboardRef = doc(db, 'whiteboards', currentWhiteboard.id);
      await updateDoc(whiteboardRef, {
        name: currentWhiteboard.name
      });
      setEditWhiteboardModalVisible(false);
      setCurrentWhiteboard(null);
      message.success(t('Whiteboard updated successfully'));
    }
  };

  const handleDeleteWhiteboard = async (whiteboard) => {
    try {
      await deleteDoc(doc(db, 'whiteboards', whiteboard.id));
      message.success(t('Whiteboard deleted successfully'));
    } catch (error) {
      console.error("Error deleting whiteboard: ", error);
      message.error(t('Error deleting whiteboard'));
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return t('No date');
    try {
      return formatDistanceToNow(new Date(dateString), { addSuffix: true });
    } catch (error) {
      console.error('Error formatting date:', error);
      return t('Invalid date');
    }
  };

  const renderWhiteboardCard = (whiteboard) => (
    <motion.div
      key={whiteboard.id}
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Card
        hoverable
        style={{ 
          height: '100%',
          borderRadius: '8px',
          overflow: 'hidden'
        }}
        onClick={() => navigate(`/whiteboards/${whiteboard.id}`)}
      >
        <div style={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}>
          <Title level={isMobile ? 5 : 4} style={{ marginBottom: '8px' }}>{whiteboard.name}</Title>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1" icon={<EditOutlined />} onClick={(e) => {
                  e.stopPropagation();
                  setCurrentWhiteboard(whiteboard);
                  setEditWhiteboardModalVisible(true);
                }}>
                  {t('Edit')}
                </Menu.Item>
                <Menu.Item key="2" icon={<DeleteOutlined />} onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteWhiteboard(whiteboard);
                }}>
                  {t('Delete')}
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button size="small" icon={<EditOutlined />} onClick={e => e.stopPropagation()} />
          </Dropdown>
        </div>
        <Text type="secondary" style={{ display: 'block', marginBottom: '16px', fontSize: isMobile ? '12px' : '14px' }}>
          {t('Created')}: {formatDate(whiteboard.createdAt)}
        </Text>
      </Card>
    </motion.div>
  );

  const renderContent = () => {
    if (whiteboards.length === 0) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t('No whiteboards found')}
        >
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setAddWhiteboardModalVisible(true)}>
            {t('Add Whiteboard')}
          </Button>
        </Empty>
      );
    }

    return (
      <Row gutter={[24, 24]}>
        {filteredWhiteboards.map(whiteboard => (
          <Col xs={24} sm={12} md={8} lg={6} key={whiteboard.id}>
            {renderWhiteboardCard(whiteboard)}
          </Col>
        ))}
      </Row>
    );
  };

  const renderHeader = () => (
    <Header style={{ background: '#fff', padding: '0 24px', boxShadow: '0 2px 8px rgba(0,0,0,0.06)' }}>
      <Row justify="space-between" align="middle" style={{ height: '100%' }}>
        <Col>
          <Title level={3} style={{ margin: 0 }}>{t('Whiteboards')}</Title>
        </Col>
        <Col>
          {isMobile ? (
            <Space size="large">
              <Button icon={<MenuOutlined />} onClick={() => setDrawerVisible(true)} />
              <Button type="primary" icon={<PlusOutlined />} onClick={() => setAddWhiteboardModalVisible(true)} />
            </Space>
          ) : (
            <Space size="large">
              <Input
                placeholder={t("Search whiteboards")}
                prefix={<SearchOutlined />}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: 200 }}
              />
              <Button type="primary" icon={<PlusOutlined />} onClick={() => setAddWhiteboardModalVisible(true)}>
                {t('Add Whiteboard')}
              </Button>
            </Space>
          )}
        </Col>
      </Row>
    </Header>
  );

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <VerticalMenu />
      <Layout style={{ minHeight: '100vh', background: '#fff' }}>
        {renderHeader()}
        <Content style={{ padding: '24px', overflowY: 'auto' }}>
          {isMobile && (
            <Input
              placeholder={t("Search whiteboards")}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: '100%', marginBottom: '16px' }}
            />
          )}
          {renderContent()}
        </Content>

        <Modal
          title={t("Add New Whiteboard")}
          visible={addWhiteboardModalVisible}
          onOk={handleAddWhiteboard}
          onCancel={() => setAddWhiteboardModalVisible(false)}
          width={isMobile ? 320 : 500}
        >
          <Input
            placeholder={t("Whiteboard Name")}
            value={newWhiteboard.name}
            onChange={(e) => setNewWhiteboard({ ...newWhiteboard, name: e.target.value })}
          />
        </Modal>

        <Modal
          title={t("Edit Whiteboard")}
          visible={editWhiteboardModalVisible}
          onOk={handleEditWhiteboard}
          onCancel={() => setEditWhiteboardModalVisible(false)}
          width={isMobile ? 320 : 500}
        >
          <Input
            placeholder={t("Whiteboard Name")}
            value={currentWhiteboard?.name}
            onChange={(e) => setCurrentWhiteboard({ ...currentWhiteboard, name: e.target.value })}
          />
        </Modal>

        <Drawer
          title={t("Menu")}
          placement="right"
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
        >
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Button type="primary" icon={<PlusOutlined />} onClick={() => {
              setAddWhiteboardModalVisible(true);
              setDrawerVisible(false);
            }} block>
              {t('Add Whiteboard')}
            </Button>
          </Space>
        </Drawer>
      </Layout>
    </div>
  );
}

export default WhiteboardsPage;