import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where, doc, updateDoc, onSnapshot, addDoc, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { DndContext, DragOverlay, closestCorners } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import VerticalMenu from '../VerticalMenu';
import Column from './Column';
import TaskCard from './TaskCard';
import { Modal, Select, Button, Input, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;
const { Title } = Typography;

function TaskBoard() {
  const [tasks, setTasks] = useState({});
  const [columns, setColumns] = useState({});
  const [columnOrder, setColumnOrder] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('all');
  const [isTaskModalVisible, setIsTaskModalVisible] = useState(false);
  const [isColumnModalVisible, setIsColumnModalVisible] = useState(false);
  const [isMoveTasksModalVisible, setIsMoveTasksModalVisible] = useState(false);
  const [newTaskTitle, setNewTaskTitle] = useState('');
  const [newColumnTitle, setNewColumnTitle] = useState('');
  const [columnToRemove, setColumnToRemove] = useState('');
  const [columnToMoveTo, setColumnToMoveTo] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProjects = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.error('No user is signed in.');
        return;
      }
      const userId = currentUser.uid;

      const querySnapshot = await getDocs(query(collection(db, "projects"), where("userId", "==", userId)));
      const fetchedProjects = [];
      querySnapshot.forEach((doc) => {
        fetchedProjects.push({ id: doc.id, ...doc.data() });
      });
      setProjects(fetchedProjects);
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchColumns = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.error('No user is signed in.');
        return;
      }
      const userId = currentUser.uid;

      const columnsSnapshot = await getDocs(query(collection(db, "columns"), where("userId", "==", userId)));
      let fetchedColumns = {};
      columnsSnapshot.forEach((doc) => {
        fetchedColumns[doc.id] = { id: doc.id, ...doc.data(), taskIds: [] };
      });

      // Create default columns if they don't exist
      const defaultColumns = ['To Do', 'In Progress', 'Done'];
      for (let title of defaultColumns) {
        if (!Object.values(fetchedColumns).some(col => col.title === title)) {
          const newColumnId = uuidv4();
          const newColumn = { id: newColumnId, title: title, userId: userId, taskIds: [] };
          await addDoc(collection(db, 'columns'), newColumn);
          fetchedColumns[newColumnId] = newColumn;
        }
      }

      setColumns(fetchedColumns);
      setColumnOrder(Object.keys(fetchedColumns));
    };

    fetchColumns();
  }, []);

  useEffect(() => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('No user is signed in.');
      return;
    }
    const userId = currentUser.uid;

    let tasksQuery = collection(db, "tasks");
    let queryConstraints = [where("userId", "==", userId)];
    
    if (selectedProject !== 'all') {
      queryConstraints.push(where("projectId", "==", selectedProject));
    }

    const q = query(tasksQuery, ...queryConstraints);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedTasks = {};
      const columnTasks = {};

      Object.keys(columns).forEach(columnId => {
        columnTasks[columnId] = [];
      });

      querySnapshot.forEach((doc) => {
        const taskData = doc.data();
        fetchedTasks[doc.id] = { ...taskData, id: doc.id };
        
        const columnId = taskData.columnId || Object.keys(columns)[0];
        if (columnTasks[columnId]) {
          columnTasks[columnId].push(doc.id);
        } else {
          console.warn(`Column ${columnId} not found for task ${doc.id}`);
        }
      });

      setTasks(fetchedTasks);
      setColumns(prev => {
        const updated = { ...prev };
        Object.keys(columnTasks).forEach(columnId => {
          if (updated[columnId]) {
            updated[columnId] = { ...updated[columnId], taskIds: columnTasks[columnId] };
          }
        });
        return updated;
      });
    }, (error) => {
      console.error("Failed to subscribe to task updates:", error);
    });

    return () => unsubscribe();
  }, [selectedProject, columns]);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;
  
    if (!over) {
      setActiveId(null);
      return;
    }
  
    if (active.id !== over.id) {
      const isColumn = active.id.toString().includes('column');

      if (isColumn) {
        setColumnOrder((items) => {
          const oldIndex = items.indexOf(active.id);
          const newIndex = items.indexOf(over.id);
          return arrayMove(items, oldIndex, newIndex);
        });
      } else {
        const fromColumnId = Object.keys(columns).find(columnId => columns[columnId].taskIds.includes(active.id));
        const toColumnId = over.id.toString().includes('column') ? over.id : over.data.current.sortable.containerId;

        if (fromColumnId !== toColumnId) {
          setColumns(prev => {
            const updatedColumns = { ...prev };
            updatedColumns[fromColumnId].taskIds = prev[fromColumnId].taskIds.filter(id => id !== active.id);
            updatedColumns[toColumnId].taskIds = [...prev[toColumnId].taskIds, active.id];
            return updatedColumns;
          });

          // Update the task in Firebase
          const taskRef = doc(db, "tasks", active.id);
          try {
            await updateDoc(taskRef, {
              columnId: toColumnId,
              phase: columns[toColumnId].title
            });
            console.log(`Task ${active.id} moved to ${toColumnId} and phase updated to ${columns[toColumnId].title}.`);
          } catch (error) {
            console.error("Error updating task column and phase: ", error);
          }
        }
      }
    }
  
    setActiveId(null);
  };

  const addNewTask = async () => {
    if (newTaskTitle.trim() === '') return;

    const firstColumnId = columnOrder[0];
    const newTask = {
      title: newTaskTitle,
      description: '',
      columnId: firstColumnId,
      phase: columns[firstColumnId].title,
      userId: auth.currentUser.uid,
      projectId: selectedProject === 'all' ? null : selectedProject,
      createdAt: new Date(),
    };

    try {
      const docRef = await addDoc(collection(db, 'tasks'), newTask);
      console.log("New task added with ID: ", docRef.id);
      setNewTaskTitle('');
      setIsTaskModalVisible(false);
    } catch (error) {
      console.error("Error adding new task: ", error);
    }
  };

  const addNewColumn = async () => {
    if (newColumnTitle.trim() === '') return;

    const newColumnId = uuidv4();
    const newColumn = { id: newColumnId, title: newColumnTitle, userId: auth.currentUser.uid, taskIds: [] };

    try {
      await addDoc(collection(db, 'columns'), newColumn);
      setColumns(prev => ({
        ...prev,
        [newColumnId]: newColumn
      }));
      setColumnOrder(prev => [...prev, newColumnId]);
      setNewColumnTitle('');
      setIsColumnModalVisible(false);
      message.success(t('New column added successfully'));
    } catch (error) {
      console.error("Error adding new column: ", error);
      message.error(t('Failed to add new column'));
    }
  };

  const editColumn = async (columnId, newTitle) => {
    try {
      await updateDoc(doc(db, 'columns', columnId), { title: newTitle });
      setColumns(prev => ({
        ...prev,
        [columnId]: { ...prev[columnId], title: newTitle }
      }));
      message.success(t('Column updated successfully'));
    } catch (error) {
      console.error("Error updating column: ", error);
      message.error(t('Failed to update column'));
    }
  };

  const removeColumn = async () => {
    if (!columnToRemove || !columnToMoveTo) return;

    try {
      // Move tasks to the new column
      const tasksToMove = columns[columnToRemove].taskIds;
      for (let taskId of tasksToMove) {
        const taskRef = doc(db, "tasks", taskId);
        await updateDoc(taskRef, {
          columnId: columnToMoveTo,
          phase: columns[columnToMoveTo].title
        });
      }

      // Delete the column
      await deleteDoc(doc(db, 'columns', columnToRemove));

      // Update local state
      setColumns(prev => {
        const updated = { ...prev };
        delete updated[columnToRemove];
        updated[columnToMoveTo].taskIds = [...updated[columnToMoveTo].taskIds, ...tasksToMove];
        return updated;
      });

      setColumnOrder(prev => prev.filter(id => id !== columnToRemove));

      setIsMoveTasksModalVisible(false);
      message.success(t('Column removed and tasks moved successfully'));
    } catch (error) {
      console.error("Error removing column and moving tasks: ", error);
      message.error(t('Failed to remove column and move tasks'));
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f0f2f5' }}>
      <VerticalMenu />
      <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
        <Title level={2} style={{ marginBottom: '20px' }}>{t('Task Board')}</Title>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <Select
            value={selectedProject}
            onChange={(value) => setSelectedProject(value)}
            style={{ width: 200 }}
          >
            <Option value="all">{t('All Projects')}</Option>
            {projects.map(project => (
              <Option key={project.id} value={project.id}>{project.name}</Option>
            ))}
          </Select>
          <div>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsTaskModalVisible(true)} style={{ marginRight: '10px' }}>
              {t('Add Task')}
            </Button>
            <Button type="default" icon={<PlusOutlined />} onClick={() => setIsColumnModalVisible(true)}>
              {t('Add Column')}
            </Button>
          </div>
        </div>

        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
          <SortableContext items={columnOrder}>
            <div style={{ display: 'flex', gap: '20px', overflowX: 'auto', padding: '20px 0' }}>
              {columnOrder.map((columnId) => (
                <Column
                  key={columnId}
                  column={columns[columnId]}
                  tasks={tasks}
                  onEdit={editColumn}
                  onRemove={(columnId) => {
                    setColumnToRemove(columnId);
                    setIsMoveTasksModalVisible(true);
                  }}
                />
              ))}
            </div>
          </SortableContext>
          <DragOverlay>
            {activeId && columns[activeId] ? (
              <Column
                column={columns[activeId]}
                tasks={tasks}
                onEdit={editColumn}
                onRemove={() => {}}
              />
            ) : activeId ? (
              <TaskCard key={activeId} id={activeId} task={tasks[activeId]} />
            ) : null}
          </DragOverlay>
        </DndContext>

        <Modal
          title={t("Add New Task")}
          visible={isTaskModalVisible}
          onOk={addNewTask}
          onCancel={() => setIsTaskModalVisible(false)}
        >
          <Input
            placeholder={t("Task Title")}
            value={newTaskTitle}
            onChange={(e) => setNewTaskTitle(e.target.value)}
          />
        </Modal>

        <Modal
          title={t("Add New Column")}
          visible={isColumnModalVisible}
          onOk={addNewColumn}
          onCancel={() => setIsColumnModalVisible(false)}
        >
          <Input
            placeholder={t("Column Title")}
            value={newColumnTitle}
            onChange={(e) => setNewColumnTitle(e.target.value)}
          />
        </Modal>

        <Modal
          title={t("Remove Column and Move Tasks")}
          visible={isMoveTasksModalVisible}
          onOk={removeColumn}
          onCancel={() => setIsMoveTasksModalVisible(false)}
        >
          <p>{t("Select a column to move tasks to:")}</p>
          <Select
            style={{ width: '100%' }}
            value={columnToMoveTo}
            onChange={(value) => setColumnToMoveTo(value)}
          >
            {Object.values(columns)
              .filter(col => col.id !== columnToRemove)
              .map(col => (
                <Option key={col.id} value={col.id}>{col.title}</Option>
              ))
            }
          </Select>
        </Modal>
      </div>
    </div>
  );
}

export default TaskBoard;