import React, { useEffect, useState, useMemo } from 'react';
import { collection, query, where, onSnapshot, updateDoc, doc, getDocs, addDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import Dealcolumn from './Dealcolumn';
import DealCard from './DealCard';
import VerticalMenu from '../VerticalMenu';
import { Select, Button, Modal, Form, Input, DatePicker, message, Typography, Statistic, Row, Col, Card } from 'antd';
import { PlusOutlined, DollarOutlined, TeamOutlined, FunnelPlotOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const { Option } = Select;
const { Title } = Typography;

function DealBoard() {
  const { t } = useTranslation();
  const [deals, setDeals] = useState({});
  const [columns, setColumns] = useState({
    new: [],
    underReview: [],
    demo: [],
    won: [],
    lost: [],
    unassigned: [],
  });

  const columnPhaseMapping = {
    new: 'New',
    underReview: 'Under Review',
    demo: 'Demo',
    won: 'Won',
    lost: 'Lost',
    unassigned: 'Unassigned',
  };

  const [activeId, setActiveId] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('all');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');

  useEffect(() => {
    const fetchCompanies = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.error('No user is signed in.');
        return;
      }

      const userId = currentUser.uid;
      const querySnapshot = await getDocs(query(collection(db, "companies"), where("userId", "==", userId)));
      const fetchedCompanies = [];
      querySnapshot.forEach((doc) => {
        fetchedCompanies.push({ id: doc.id, ...doc.data() });
      });
      setCompanies(fetchedCompanies);
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const fetchDeals = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.error('No user is signed in.');
        return;
      }
      
      // Simplify query for debugging
      const q = query(collection(db, "deals"));
      console.log("Executing query: ", q); // Debug log

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const fetchedDeals = {};
        const columnDeals = {
          new: [],
          underReview: [],
          demo: [],
          won: [],
          lost: [],
          unassigned: [],
         
        };

        querySnapshot.forEach((doc) => {
          const dealData = doc.data();
          console.log("Deal data: ", dealData); // Debug log
          fetchedDeals[doc.id] = { ...dealData, id: doc.id };

          
          switch (dealData.stage) {
            case 'New':
              columnDeals.new.push(doc.id);
              break;
            case 'Under Review':
              columnDeals.underReview.push(doc.id);
              break;
            case 'Demo':
              columnDeals.demo.push(doc.id);
              break;
            case 'Won':
              columnDeals.won.push(doc.id);
              break;
            case 'Lost':
              columnDeals.lost.push(doc.id);
              break;
            case 'Unassigned':
              columnDeals.unassigned.push(doc.id);
              break;
            default:
              break;
          }
        });

        console.log("Fetched deals: ", fetchedDeals); // Debug log
        console.log("Column deals: ", columnDeals); // Debug log

        setDeals(fetchedDeals);
        setColumns(columnDeals);
      }, (error) => {
        console.error("Failed to subscribe to deal updates:", error);
      });

      return () => unsubscribe();
    };

    fetchDeals();
  }, [selectedCompany]);

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over) {
      setActiveId(null);
      return;
    }

    const fromdealcolumnId = findColumn(active.id);
    const todealcolumnId = over.id;
    const newStage = columnPhaseMapping[todealcolumnId];

    if (fromdealcolumnId !== todealcolumnId && newStage) {
      setColumns((prev) => {
        const fromDeals = prev[fromdealcolumnId].filter((dealId) => dealId !== active.id);
        const toDeals = [...prev[todealcolumnId], active.id];

        const dealRef = doc(db, "deals", active.id);
        updateDoc(dealRef, {
          stage: newStage
        }).then(() => {
          console.log(`Deal ${active.id} moved to ${newStage}.`);
        }).catch((error) => {
          console.error("Error updating deal stage: ", error);
        });

        return {
          ...prev,
          [fromdealcolumnId]: fromDeals,
          [todealcolumnId]: toDeals,
        };
      });
    }

    setActiveId(null);
  };

  const findColumn = (dealId) => {
    return Object.keys(columns).find(dealcolumnId => columns[dealcolumnId].includes(dealId));
  };

  const handleAddDeal = async (values) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      message.error("You must be signed in to add a deal.");
      return;
    }

    const newDeal = {
      ...values,
      userId: currentUser.uid,
      createdAt: new Date(),
      stage: 'New',
      closeDate: values.closeDate.toDate(),
    };

    try {
      await addDoc(collection(db, 'deals'), newDeal);
      message.success("New deal added successfully.");
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Error adding new deal: ", error);
      message.error("Failed to add new deal.");
    }
  };

  const filteredDeals = useMemo(() => {
    return Object.values(deals).filter(deal => 
      deal.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      deal.amount.toString().includes(searchTerm)
    );
  }, [deals, searchTerm]);

  const sortedDeals = useMemo(() => {
    return [...filteredDeals].sort((a, b) => {
      if (sortBy === 'amount') {
        return sortOrder === 'asc' ? a.amount - b.amount : b.amount - a.amount;
      } else {
        return sortOrder === 'asc' 
          ? new Date(a[sortBy]) - new Date(b[sortBy])
          : new Date(b[sortBy]) - new Date(a[sortBy]);
      }
    });
  }, [filteredDeals, sortBy, sortOrder]);

  const totalValue = useMemo(() => {
    return Object.values(deals).reduce((sum, deal) => sum + parseFloat(deal.amount || 0), 0);
  }, [deals]);

  const averageDealSize = useMemo(() => {
    const dealCount = Object.values(deals).length;
    return dealCount > 0 ? totalValue / dealCount : 0;
  }, [deals, totalValue]);

  const winRate = useMemo(() => {
    const wonDeals = columns.won.length;
    const lostDeals = columns.lost.length;
    const totalClosedDeals = wonDeals + lostDeals;
    return totalClosedDeals > 0 ? (wonDeals / totalClosedDeals) * 100 : 0;
  }, [columns]);

  return (
    <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f0f2f5' }}>
      <VerticalMenu />
      <div style={{ flex: 1, marginLeft: '10px', padding: '20px', overflowY: 'auto', marginTop: '50px' }}>
        <Title level={2}>{t("Deal Board")}</Title>
        <Row gutter={16} style={{ marginBottom: '20px' }}>
          <Col span={6}>
            <Card>
              <Statistic
                title={t("Total Pipeline Value")}
                value={totalValue}
                precision={2}
                prefix={<DollarOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title={t("Average Deal Size")}
                value={averageDealSize}
                precision={2}
                prefix={<DollarOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title={t("Win Rate")}
                value={winRate}
                precision={2}
                suffix="%"
                prefix={<FunnelPlotOutlined />}
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title={t("Total Deals")}
                value={Object.keys(deals).length}
                prefix={<TeamOutlined />}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginBottom: '20px' }}>
          <Col span={6}>
            <Select
              value={selectedCompany}
              onChange={(value) => setSelectedCompany(value)}
              style={{ width: '100%' }}
            >
              <Option value="all">{t("All Companies")}</Option>
              {companies.map(company => (
                <Option key={company.id} value={company.id}>{company.name}</Option>
              ))}
            </Select>
          </Col>
          <Col span={6}>
            <Input
              placeholder={t("Search deals...")}
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
          </Col>
          <Col span={6}>
            <Select
              value={sortBy}
              onChange={(value) => setSortBy(value)}
              style={{ width: '100%' }}
            >
              <Option value="createdAt">{t("Created Date")}</Option>
              <Option value="closeDate">{t("Close Date")}</Option>
              <Option value="amount">{t("Amount")}</Option>
            </Select>
          </Col>
          <Col span={6}>
            <Select
              value={sortOrder}
              onChange={(value) => setSortOrder(value)}
              style={{ width: '100%' }}
            >
              <Option value="asc">{t("Ascending")}</Option>
              <Option value="desc">{t("Descending")}</Option>
            </Select>
          </Col>
        </Row>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)} style={{ marginBottom: '20px' }}>
          {t("Add Deal")}
        </Button>
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <div style={{
            display: 'flex',
            overflowX: 'auto',
            padding: '20px',
            height: 'calc(100vh - 300px)',
            alignItems: 'flex-start',
          }}>
            {Object.entries(columns).map(([dealcolumnId, dealIds]) => (
              <Dealcolumn
                key={dealcolumnId}
                dealcolumnId={dealcolumnId}
                title={columnPhaseMapping[dealcolumnId]}
                dealIds={dealIds.filter(id => sortedDeals.some(deal => deal.id === id))}
                deals={deals}
              />
            ))}
          </div>
          <DragOverlay>
            {activeId ? <DealCard key={activeId} id={activeId} {...deals[activeId]} /> : null}
          </DragOverlay>
        </DndContext>
      </div>
      <Modal
        title={t("Add New Deal")}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form form={form} onFinish={handleAddDeal} layout="vertical">
          <Form.Item name="title" label={t("Deal Title")} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="amount" label={t("Deal Amount")} rules={[{ required: true }]}>
            <Input type="number" prefix="$" />
          </Form.Item>
          <Form.Item name="companyId" label={t("Company")} rules={[{ required: true }]}>
            <Select>
              {companies.map(company => (
                <Option key={company.id} value={company.id}>{company.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="closeDate" label={t("Expected Close Date")} rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("Add Deal")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default DealBoard;