import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Card, Input, Select, message, Spin, Modal, Drawer } from 'antd';
import { ArrowLeftOutlined, SaveOutlined, EyeOutlined, DownloadOutlined, MenuOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { doc, getDoc, updateDoc, getFirestore } from 'firebase/firestore';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import VerticalMenu from '../VerticalMenu';

const { Option } = Select;

const NoteContent = () => {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const { noteId } = useParams();
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const fetchNote = async () => {
      try {
        const noteRef = doc(db, 'notes', noteId);
        const noteSnap = await getDoc(noteRef);
        if (noteSnap.exists()) {
          const { title: noteTitle, content: noteContent } = noteSnap.data();
          setTitle(noteTitle || '');
          setContent(noteContent || '');
          document.title = noteTitle || 'Untitled Note';
        } else {
          message.error('Note not found');
          navigate('/');
        }
      } catch (error) {
        console.error("Error fetching note:", error);
        message.error('Error fetching note');
      } finally {
        setIsLoading(false);
      }
    };

    fetchNote();
  }, [noteId, navigate, db]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const saveContent = async () => {
    setIsSaving(true);
    try {
      await updateDoc(doc(db, 'notes', noteId), { content, title });
      message.success('Note saved successfully');
    } catch (error) {
      console.error("Error saving note:", error);
      message.error('Error saving note');
    }
    setIsSaving(false);
  };

  const templates = [
    {
      id: 'meeting',
      name: 'Meeting Notes',
      content: `
        <h2>Meeting Notes</h2>
        <p><strong>Date:</strong> ${new Date().toLocaleDateString()}</p>
        <p><strong>Time:</strong> ${new Date().toLocaleTimeString()}</p>
        <h3>Attendees</h3>
        <ul>
          <li></li>
        </ul>
        <h3>Agenda</h3>
        <ol>
          <li></li>
        </ol>
        <h3>Action Items</h3>
        <ul>
          <li></li>
        </ul>
      `
    },
    {
      id: 'timeBox',
      name: 'Time Box',
      content: `
        <h2>Time Box</h2>
        <table>
          <tr>
            <th>Time</th>
            <th>Task</th>
          </tr>
          <tr>
            <td>9:00 - 10:00</td>
            <td></td>
          </tr>
          <tr>
            <td>10:00 - 11:00</td>
            <td></td>
          </tr>
          <tr>
            <td>11:00 - 12:00</td>
            <td></td>
          </tr>
        </table>
      `
    }
  ];

  const handleTemplateSelect = (templateId) => {
    const selected = templates.find(t => t.id === templateId);
    if (selected) {
      setContent(selected.content);
      message.success('Template applied');
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'],
      ['link', 'image']
    ]
  };

  const handlePreview = () => {
    setIsPreviewVisible(true);
  };

  const handleDownload = () => {
    const doc = new jsPDF();
    
    // Set font
    doc.setFont("helvetica", "normal");
    
    // Add title
    doc.setFontSize(16);
    doc.text(title, 20, 20);
    
    // Parse and add content
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(content, 'text/html');
    let yOffset = 30;
    
    const parseElement = (element, indent = 0) => {
      Array.from(element.childNodes).forEach(node => {
        if (node.nodeType === Node.TEXT_NODE && node.textContent.trim() !== '') {
          doc.setFontSize(10);
          const textLines = doc.splitTextToSize(node.textContent.trim(), 170 - indent);
          textLines.forEach(line => {
            if (yOffset > 280) {
              doc.addPage();
              yOffset = 20;
            }
            doc.text(20 + indent, yOffset, line);
            yOffset += 5;
          });
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          switch (node.tagName.toLowerCase()) {
            case 'h1':
            case 'h2':
            case 'h3':
            case 'h4':
            case 'h5':
            case 'h6':
              const fontSize = 16 - (parseInt(node.tagName[1]) - 1) * 1.5;
              doc.setFontSize(fontSize);
              doc.setFont("helvetica", "bold");
              if (yOffset > 280) {
                doc.addPage();
                yOffset = 20;
              }
              doc.text(20, yOffset, node.textContent.trim());
              yOffset += fontSize / 2 + 3;
              doc.setFontSize(10);
              doc.setFont("helvetica", "normal");
              break;
            case 'p':
              if (yOffset > 270) {
                doc.addPage();
                yOffset = 20;
              }
              parseElement(node, indent);
              yOffset += 5;
              break;
            case 'ul':
            case 'ol':
              parseElement(node, indent + 5);
              yOffset += 3;
              break;
            case 'li':
              if (yOffset > 280) {
                doc.addPage();
                yOffset = 20;
              }
              doc.text(15 + indent, yOffset, '•');
              parseElement(node, indent + 5);
              break;
            case 'table':
              const headers = Array.from(node.querySelectorAll('th')).map(th => th.textContent.trim());
              const data = Array.from(node.querySelectorAll('tr')).slice(1).map(tr => 
                Array.from(tr.querySelectorAll('td')).map(td => td.textContent.trim())
              );
              doc.autoTable({
                head: [headers],
                body: data,
                startY: yOffset,
                margin: { left: 20, right: 20 },
                styles: { fontSize: 8 },
                headStyles: { fillColor: [200, 200, 200], textColor: 20 },
              });
              yOffset = doc.lastAutoTable.finalY + 10;
              break;
            default:
              parseElement(node, indent);
          }
        }
      });
    };
    
    parseElement(htmlDoc.body);
    
    // Save the PDF
    doc.save(`${title || 'note'}.pdf`);
  };

  const renderActions = () => (
    <>
      <Select
        style={{ width: '100%', marginBottom: '8px' }}
        placeholder="Select template"
        onChange={handleTemplateSelect}
      >
        {templates.map(template => (
          <Option key={template.id} value={template.id}>{template.name}</Option>
        ))}
      </Select>
      <Button icon={<SaveOutlined />} onClick={saveContent} loading={isSaving} style={{ width: '100%', marginBottom: '8px' }}>
        Save
      </Button>
      <Button icon={<EyeOutlined />} onClick={handlePreview} style={{ width: '100%', marginBottom: '8px' }}>
        Preview
      </Button>
      <Button icon={<DownloadOutlined />} onClick={handleDownload} style={{ width: '100%' }}>
        Download PDF
      </Button>
    </>
  );

  if (isLoading) {
    return <Spin size="large" />;
  }

  return (
    <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f5f5f5' }}>
      <div className="desktop-menu" style={{ display: 'none' }}>
        <VerticalMenu />
      </div>
      <div style={{ flex: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: '#f0f2f5' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px', backgroundColor: '#fff', boxShadow: '0 2px 8px rgba(0,0,0,0.15)' }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              <Button icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)} style={{ marginRight: '16px' }} />
              <Input 
                value={title} 
                onChange={handleTitleChange} 
                placeholder="Enter title" 
                style={{ flex: 1 }} 
              />
            </div>
            <div className="desktop-actions" style={{ display: 'none', marginLeft: '16px' }}>
              {renderActions()}
            </div>
            <Button className="mobile-menu-button" icon={<MenuOutlined />} onClick={() => setIsMenuVisible(true)} style={{ marginLeft: '16px' }} />
          </div>
          <div style={{ flex: 1, padding: '16px', overflow: 'auto' }}>
            <Card style={{ height: '100%' }}>
              <ReactQuill
                theme="snow"
                value={content}
                onChange={handleContentChange}
                modules={modules}
                style={{ height: 'calc(100% - 42px)' }}
              />
            </Card>
          </div>
          <Modal
            title="Preview"
            visible={isPreviewVisible}
            onCancel={() => setIsPreviewVisible(false)}
            footer={null}
            width="90%"
          >
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </Modal>
          <Drawer
            title="Menu"
            placement="right"
            closable={true}
            onClose={() => setIsMenuVisible(false)}
            visible={isMenuVisible}
            width={300}
          >
            <VerticalMenu />
            <div style={{ marginTop: '20px' }}>
              {renderActions()}
            </div>
          </Drawer>
        </div>
      </div>
      <style jsx>{`
        @media (max-width: 768px) {
          .desktop-menu, .desktop-actions {
            display: none !important;
          }
          .mobile-menu-button {
            display: inline-block !important;
          }
        }
        @media (min-width: 769px) {
          .desktop-menu, .desktop-actions {
            display: flex !important;
          }
          .mobile-menu-button {
            display: none !important;
          }
        }
      `}</style>
    </div>
  );
};

export default NoteContent;