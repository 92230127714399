import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Layout, Button, Card, Tag, Modal, Form, Input, Select, DatePicker, Popconfirm, message, Row, Col, Timeline, Radio, Dropdown, Menu, Typography, Switch, InputNumber, Progress } from 'antd';
import { ArrowLeftOutlined, PlusOutlined, EditOutlined, DeleteOutlined, CalendarOutlined, UnorderedListOutlined, AppstoreOutlined, FieldTimeOutlined, FilterOutlined, SortAscendingOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from 'styled-components';
import VerticalMenu from '../VerticalMenu';
import { collection, query, where, onSnapshot, addDoc, updateDoc, deleteDoc, doc, Timestamp, getDocs } from 'firebase/firestore';
import { db, auth } from '../../firebase';


const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

const StyledContent = styled(Content)`
  padding: 24px;
  background: #fff;
  min-height: calc(100vh - 48px);
`;

const StyledCard = styled(Card)`
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
`;

const RoadmapPage = () => {
  const { projectId } = useParams();
  const [items, setItems] = useState([]);
  const [columns] = useState(['To Do', 'In Progress', 'Done']);
  const [viewMode, setViewMode] = useState('kanban');
  const [modalVisible, setModalVisible] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [form] = Form.useForm();
  const [filterCriteria, setFilterCriteria] = useState({ status: 'all', priority: 'all', dateRange: null });
  const [sortCriteria, setSortCriteria] = useState('dueDate');

  const navigate = useNavigate();


  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      message.error('User not authenticated');
      return;
    }

    const fetchFeaturesAndTasks = async () => {
      const featuresQuery = query(
        collection(db, 'features'),
        where('userId', '==', user.uid),
        where('projectId', '==', projectId)
      );

      const unsubscribe = onSnapshot(featuresQuery, async (querySnapshot) => {
        const fetchedItems = [];
        for (const doc of querySnapshot.docs) {
          const data = doc.data();
          const tasksQuery = query(
            collection(db, 'tasks'),
            where('featureId', '==', doc.id)
          );
          const tasksSnapshot = await getDocs(tasksQuery);
          const tasks = tasksSnapshot.docs.map(taskDoc => ({
            id: taskDoc.id,
            ...taskDoc.data()
          }));
          
          fetchedItems.push({
            id: doc.id,
            name: data.name || '',
            description: data.description || '',
            priority: data.priority || 'Medium',
            status: data.status || 'To Do',
            dueDate: data.dueDate ? data.dueDate.toDate().toISOString().split('T')[0] : null,
            budget: data.budget || 0,
            tasks: tasks,
            tags: data.tags || [],
            timeSpent: data.timeSpent || 0,
            created: data.created && typeof data.created.toDate === 'function' ? data.created.toDate().toISOString() : null,
          });
        }
        setItems(fetchedItems);
      }, (error) => {
        console.error("Error fetching features: ", error);
        message.error('Failed to fetch features');
      });

      return () => unsubscribe();
    };

    fetchFeaturesAndTasks();
  }, [projectId]);

  const calculateProgress = (tasks) => {
    if (!tasks || tasks.length === 0) return 0;
    const completedTasks = tasks.filter(task => task.phase === 'Done').length;
    return Math.round((completedTasks / tasks.length) * 100);
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const itemId = result.draggableId;
    const newStatus = destination.droppableId;

    try {
      const updatedItem = items.find(item => item.id === itemId);
      updatedItem.status = newStatus;
      await updateDoc(doc(db, 'features', itemId), { status: newStatus });
      message.success('Feature moved successfully');
    } catch (error) {
      console.error("Error updating feature: ", error);
      message.error('Failed to move feature');
    }
  };

  const showModal = (item = null) => {
    setEditingItem(item);
    if (item) {
      form.setFieldsValue({
        ...item,
        dueDate: item.dueDate ? moment(item.dueDate) : null,
        tags: item.tags ? item.tags.join(', ') : '',
      });
    } else {
      form.resetFields();
    }
    setModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const user = auth.currentUser;
      if (!user) {
        message.error('User not authenticated');
        return;
      }

      const itemData = {
        ...values,
        dueDate: values.dueDate ? Timestamp.fromDate(values.dueDate.toDate()) : null,
        tags: values.tags ? values.tags.split(',').map(tag => tag.trim()) : [],
        userId: user.uid,
        projectId: projectId,
        created: editingItem ? editingItem.created : Timestamp.now(),
        budget: values.budget || 0,
        timeSpent: values.timeSpent || 0,
      };
      
      if (editingItem) {
        await updateDoc(doc(db, 'features', editingItem.id), itemData);
        message.success('Feature updated successfully');
      } else {
        await addDoc(collection(db, 'features'), itemData);
        message.success('Feature added successfully');
      }
      
      setModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Error saving feature: ", error);
      message.error('Failed to save feature');
    }
  };

  const handleDelete = async (itemToDelete) => {
    try {
      await deleteDoc(doc(db, 'features', itemToDelete.id));
      message.success('Feature deleted successfully');
    } catch (error) {
      console.error("Error deleting feature: ", error);
      message.error('Failed to delete feature');
    }
  };

  const filteredItems = items.filter(item => {
    const statusMatch = filterCriteria.status === 'all' || item.status === filterCriteria.status;
    const priorityMatch = filterCriteria.priority === 'all' || item.priority === filterCriteria.priority;
    const dateMatch = !filterCriteria.dateRange || (
      item.dueDate &&
      moment(item.dueDate).isSameOrAfter(filterCriteria.dateRange[0], 'day') &&
      moment(item.dueDate).isSameOrBefore(filterCriteria.dateRange[1], 'day')
    );
    return statusMatch && priorityMatch && dateMatch;
  }).sort((a, b) => {
    if (sortCriteria === 'dueDate') {
      return moment(a.dueDate || '9999-12-31').diff(moment(b.dueDate || '9999-12-31'));
    } else if (sortCriteria === 'priority') {
      const priorityOrder = { 'High': 0, 'Medium': 1, 'Low': 2 };
      return (priorityOrder[a.priority] || 1) - (priorityOrder[b.priority] || 1);
    }
    return 0;
  });

  const groupedItems = columns.reduce((acc, column) => {
    acc[column] = filteredItems.filter(item => item.status === column);
    return acc;
  }, {});

  const renderKanbanView = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Row gutter={16}>
        {columns.map((columnId) => (
          <Col key={columnId} xs={24} sm={12} md={8}>
            <div style={{ background: '#f0f2f5', padding: '16px', borderRadius: '8px', height: '100%' }}>
              <h3>{columnId}</h3>
              <Droppable droppableId={columnId}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      minHeight: 300,
                      background: snapshot.isDraggingOver ? '#e6f7ff' : 'transparent',
                      transition: 'background-color 0.2s ease',
                      padding: 4
                    }}
                  >
                    {groupedItems[columnId] && groupedItems[columnId].map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          <StyledCard
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              opacity: snapshot.isDragging ? 0.9 : 1
                            }}
                          >
                            <Card.Meta
                              title={item.name}
                              description={
                                <>
                                  <p>{item.description}</p>
                                  <Tag color={item.priority === 'High' ? 'red' : item.priority === 'Medium' ? 'orange' : 'green'}>{item.priority}</Tag>
                                  {item.dueDate && <p><CalendarOutlined /> {item.dueDate}</p>}
                                  <Progress percent={calculateProgress(item.tasks)} status={calculateProgress(item.tasks) === 100 ? "success" : "active"} />
                                  <p>Budget: ${item.budget}</p>
                                  <div>
                                    {item.tags && item.tags.map(tag => (
                                      <Tag key={tag} color="blue">{tag}</Tag>
                                    ))}
                                  </div>
                                </>
                              }
                            />
                            <Button icon={<EditOutlined />} onClick={() => showModal(item)} type="text" />
                            <Popconfirm title="Are you sure to delete this item?" onConfirm={() => handleDelete(item)}>
                              <Button icon={<DeleteOutlined />} type="text" danger />
                            </Popconfirm>
                          </StyledCard>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </Col>
        ))}
      </Row>
    </DragDropContext>
  );

  const renderListView = () => (
    <div>
      {filteredItems.map(item => (
        <StyledCard key={item.id}>
          <Card.Meta
            title={item.name}
            description={
              <>
                <p>{item.description}</p>
                <Tag color={item.priority === 'High' ? 'red' : item.priority === 'Medium' ? 'orange' : 'green'}>{item.priority}</Tag>
                <Tag color={item.status === 'To Do' ? 'default' : item.status === 'In Progress' ? 'processing' : 'success'}>{item.status}</Tag>
                {item.dueDate && <p><CalendarOutlined /> {item.dueDate}</p>}
                <Progress percent={calculateProgress(item.tasks)} status={calculateProgress(item.tasks) === 100 ? "success" : "active"} />
                <p>Budget: ${item.budget}</p>
                <div>
                  {item.tags && item.tags.map(tag => (
                    <Tag key={tag} color="blue">{tag}</Tag>
                  ))}
                </div>
              </>
            }
          />
          <Button icon={<EditOutlined />} onClick={() => showModal(item)} type="text" />
          <Popconfirm title="Are you sure to delete this item?" onConfirm={() => handleDelete(item)}>
            <Button icon={<DeleteOutlined />} type="text" danger />
          </Popconfirm>
        </StyledCard>
      ))}
    </div>
  );

  const renderTimelineView = () => (
    <Timeline mode="left">
      {filteredItems.map(item => (
        <Timeline.Item key={item.id} color={item.priority === 'High' ? 'red' : item.priority === 'Medium' ? 'orange' : 'green'} label={item.dueDate}>
          <StyledCard>
            <Card.Meta
              title={item.name}
              description={
                <>
                  <p>{item.description}</p>
                  <Tag color={item.priority === 'High' ? 'red' : item.priority === 'Medium' ? 'orange' : 'green'}>{item.priority}</Tag>
                  <Tag color={item.status === 'To Do' ? 'default' : item.status === 'In Progress' ? 'processing' : 'success'}>{item.status}</Tag>
                  <Progress percent={calculateProgress(item.tasks)} status={calculateProgress(item.tasks) === 100 ? "success" : "active"} />
                  <p>Budget: ${item.budget}</p>
                  <div>
                    {item.tags && item.tags.map(tag => (
                      <Tag key={tag} color="blue">{tag}</Tag>
                    ))}
                  </div>
                </>
              }
            />
            <Button icon={<EditOutlined />} onClick={() => showModal(item)} type="text" />
            <Popconfirm title="Are you sure to delete this item?" onConfirm={() => handleDelete(item)}>
              <Button icon={<DeleteOutlined />} type="text" danger />
            </Popconfirm>
          </StyledCard>
        </Timeline.Item>))}
    </Timeline>
  );

  const filterMenu = (
    <Menu>
      <Menu.SubMenu key="status" title="Status">
        <Menu.Item key="all" onClick={() => setFilterCriteria({...filterCriteria, status: 'all'})}>All</Menu.Item>
        {columns.map(column => (
          <Menu.Item key={column} onClick={() => setFilterCriteria({...filterCriteria, status: column})}>{column}</Menu.Item>
        ))}
      </Menu.SubMenu>
      <Menu.SubMenu key="priority" title="Priority">
        <Menu.Item key="all" onClick={() => setFilterCriteria({...filterCriteria, priority: 'all'})}>All</Menu.Item>
        <Menu.Item key="High" onClick={() => setFilterCriteria({...filterCriteria, priority: 'High'})}>High</Menu.Item>
        <Menu.Item key="Medium" onClick={() => setFilterCriteria({...filterCriteria, priority: 'Medium'})}>Medium</Menu.Item>
        <Menu.Item key="Low" onClick={() => setFilterCriteria({...filterCriteria, priority: 'Low'})}>Low</Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key="dateRange">
        <RangePicker onChange={(dates) => setFilterCriteria({...filterCriteria, dateRange: dates})} />
      </Menu.Item>
    </Menu>
  );

  const sortMenu = (
    <Menu>
      <Menu.Item key="dueDate" onClick={() => setSortCriteria('dueDate')}>Due Date</Menu.Item>
      <Menu.Item key="priority" onClick={() => setSortCriteria('priority')}>Priority</Menu.Item>
    </Menu>
  );

  return (
    <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f0f2f5' }}>
      <VerticalMenu />
      <div style={{ flex: 1, padding: '30px', overflowY: 'auto' }}>
        <StyledContent>
        <Button type="link" icon={<ArrowLeftOutlined />} onClick={() => navigate(`/projects/${projectId}/features`)}>
            {"Back"}
          </Button>
          <Row justify="space-between" style={{ marginBottom: 16 }}>
            <Col>
              <Button onClick={() => showModal()} type="primary" icon={<PlusOutlined />}>Add Feature</Button>
            </Col>
            <Col>
              <Radio.Group value={viewMode} onChange={(e) => setViewMode(e.target.value)} style={{ marginRight: 8 }}>
                <Radio.Button value="kanban"><AppstoreOutlined /> Kanban</Radio.Button>
                <Radio.Button value="list"><UnorderedListOutlined /> List</Radio.Button>
                <Radio.Button value="timeline"><FieldTimeOutlined /> Timeline</Radio.Button>
              </Radio.Group>
              <Dropdown overlay={filterMenu}>
                <Button icon={<FilterOutlined />}>Filter</Button>
              </Dropdown>
              <Dropdown overlay={sortMenu}>
                <Button icon={<SortAscendingOutlined />}>Sort</Button>
              </Dropdown>
            </Col>
          </Row>
          {viewMode === 'kanban' && renderKanbanView()}
          {viewMode === 'list' && renderListView()}
          {viewMode === 'timeline' && renderTimelineView()}
        </StyledContent>

        <Modal
          title={editingItem ? "Edit Feature" : "Add Feature"}
          visible={modalVisible}
          onOk={handleOk}
          onCancel={() => setModalVisible(false)}
        >
          <Form form={form} layout="vertical">
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Input.TextArea />
            </Form.Item>
            <Form.Item name="priority" label="Priority" rules={[{ required: true }]}>
              <Select>
                <Option value="High">High</Option>
                <Option value="Medium">Medium</Option>
                <Option value="Low">Low</Option>
              </Select>
            </Form.Item>
            <Form.Item name="status" label="Status" rules={[{ required: true }]}>
              <Select>
                {columns.map(column => (
                  <Option key={column} value={column}>{column}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="dueDate" label="Due Date">
              <DatePicker />
            </Form.Item>
            <Form.Item name="budget" label="Budget">
              <InputNumber prefix="$" />
            </Form.Item>
            <Form.Item name="tags" label="Tags">
              <Input placeholder="Enter tags separated by commas" />
            </Form.Item>
            <Form.Item name="timeSpent" label="Time Spent">
              <InputNumber suffix="hours" />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default RoadmapPage;