import React, { useState, useEffect, useRef } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { doc, updateDoc, getDoc, collection, getDocs, addDoc, deleteDoc, query, where, writeBatch } from 'firebase/firestore';
import { db } from '../../firebase';
import { Modal, Input, Button, Space, Tag, Typography, Checkbox, Progress, Select, DatePicker, Popconfirm, ColorPicker, message, Divider } from 'antd';
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const { Text, Title } = Typography;
const { Option } = Select;

const PRIORITY_COLORS = {
  High: '#ff4d4f',
  Normal: '#faad14',
  Low: '#52c41a',
};

const PHASES = ['To Do', 'In Progress', 'Done'];
const PRIORITIES = ['Low', 'Normal', 'High'];

function TaskCard({ id, task, onUpdate }) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [taskDetails, setTaskDetails] = useState(task);
  const [allLabels, setAllLabels] = useState([]);
  const [newLabel, setNewLabel] = useState({ text: '', color: '#000000' });
  const [isDragging, setIsDragging] = useState(false);
  const clickStartRef = useRef(0);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
  } = useDraggable({
    id,
    data: {
      type: 'TaskCard',
    },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    touchAction: 'none',
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '12px',
    margin: '0 0 8px 0',
    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
    cursor: isDragging ? 'grabbing' : 'grab',
    width: '100%',
    minHeight: '100px',
    maxHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  };

  useEffect(() => {
    const fetchTask = async () => {
      const taskRef = doc(db, 'tasks', id);
      const taskSnap = await getDoc(taskRef);

      if (taskSnap.exists()) {
        setTaskDetails({ ...taskSnap.data(), id: taskSnap.id });
      } else {
        console.log("No such document!");
      }
    };

    const fetchAllLabels = async () => {
      const labelsCollection = collection(db, 'labels');
      const labelsSnapshot = await getDocs(labelsCollection);
      const labelsList = labelsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setAllLabels(labelsList);
    };

    fetchTask();
    fetchAllLabels();
  }, [id]);

  const handleUpdateTask = async () => {
    const taskRef = doc(db, 'tasks', id);
    try {
      await updateDoc(taskRef, {
        ...taskDetails,
        labels: taskDetails.labels || [],
        checklist: taskDetails.checklist || [],
      });
      setIsModalVisible(false);
      onUpdate(id, taskDetails);
    } catch (error) {
      console.error("Error updating task: ", error);
    }
  };

  const handleArchiveTask = async () => {
    // Implement your logic to archive the task here
    console.log("Archive task:", id);
  };

  const getCompletionPercentage = (checklist) => {
    if (!Array.isArray(checklist) || checklist.length === 0) return 0;
    const checkedItems = checklist.filter(item => item.checked).length;
    return (checkedItems / checklist.length) * 100;
  };

  const addChecklistItem = () => {
    setTaskDetails(prev => ({
      ...prev,
      checklist: [...(prev.checklist || []), { text: '', checked: false }]
    }));
  };

  const updateChecklistItem = (index, field, value) => {
    setTaskDetails(prev => {
      const newChecklist = [...prev.checklist];
      newChecklist[index] = { ...newChecklist[index], [field]: value };
      return { ...prev, checklist: newChecklist };
    });
  };

  const removeChecklistItem = (index) => {
    setTaskDetails(prev => ({
      ...prev,
      checklist: prev.checklist.filter((_, i) => i !== index)
    }));
  };

  const handleAddLabel = async (label) => {
    setTaskDetails(prev => ({
      ...prev,
      labels: [...(prev.labels || []), label]
    }));
  };

  const handleAddNewLabel = async () => {
    if (newLabel.text.trim()) {
      const labelsCollection = collection(db, 'labels');
      const docRef = await addDoc(labelsCollection, newLabel);
      const newLabelWithId = { ...newLabel, id: docRef.id };
      setAllLabels(prev => [...prev, newLabelWithId]);
      handleAddLabel(newLabelWithId);
      setNewLabel({ text: '', color: '#000000' });
    }
  };

  const removeLabel = (labelToRemove) => {
    setTaskDetails(prev => ({
      ...prev,
      labels: prev.labels.filter(label => label.id !== labelToRemove.id)
    }));
  };

  const deleteLabelGlobally = async (labelToDelete) => {
    try {
      await deleteDoc(doc(db, 'labels', labelToDelete.id));

      const tasksCollection = collection(db, 'tasks');
      const taskQuery = query(tasksCollection, where('labels', 'array-contains', labelToDelete));
      const taskSnapshot = await getDocs(taskQuery);

      const batch = writeBatch(db);
      taskSnapshot.docs.forEach((taskDoc) => {
        const taskRef = doc(db, 'tasks', taskDoc.id);
        const updatedLabels = taskDoc.data().labels.filter(label => label.id !== labelToDelete.id);
        batch.update(taskRef, { labels: updatedLabels });
      });

      await batch.commit();

      setAllLabels(prev => prev.filter(label => label.id !== labelToDelete.id));
      setTaskDetails(prev => ({
        ...prev,
        labels: prev.labels.filter(label => label.id !== labelToDelete.id)
      }));

      message.success(t('Label deleted successfully'));
    } catch (error) {
      console.error("Error deleting label: ", error);
      message.error(t('Failed to delete label'));
    }
  };

  const handleDueDateChange = (date) => {
    setTaskDetails(prev => ({
      ...prev,
      dueDate: date ? date.toDate() : null
    }));
  };

  const handleMouseDown = (e) => {
    clickStartRef.current = Date.now();
    setIsDragging(false);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    const clickDuration = Date.now() - clickStartRef.current;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);

    if (!isDragging && clickDuration < 200) {
      setIsModalVisible(true);
    }
    setIsDragging(false);
  };

  const renderLabelDropdown = (menu) => (
    <div>
      {menu}
      <Divider style={{ margin: '8px 0' }} />
      <Space style={{ padding: '0 8px 4px' }}>
        <Input
          placeholder={t("New label name")}
          value={newLabel.text}
          onChange={(e) => setNewLabel(prev => ({ ...prev, text: e.target.value }))}
          style={{ width: '120px' }}
        />
        <ColorPicker
          value={newLabel.color}
          onChange={(color) => setNewLabel(prev => ({ ...prev, color: color.toHexString() }))}
        />
        <Button type="text" icon={<PlusOutlined />} onClick={handleAddNewLabel}>
          {t("Add")}
        </Button>
      </Space>
    </div>
  );

  return (
    <>
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <div>
            <Text strong style={{ fontSize: 14, maxWidth: 'calc(100% - 24px)' }} ellipsis>{taskDetails.title}</Text>
            <Text type="secondary" style={{ fontSize: 12 }} ellipsis={{ rows: 2 }}>{taskDetails.description}</Text>
          </div>
          <div style={{ marginTop: 8 }}>
            <Space wrap style={{ marginBottom: 4 }}>
              {taskDetails.labels && taskDetails.labels.slice(0, 3).map((label, index) => (
                <Tag key={index} color={label.color} style={{ borderRadius: 12, margin: '0 4px 4px 0', fontSize: 10 }}>
                  {label.text}
                </Tag>
              ))}
              {taskDetails.labels && taskDetails.labels.length > 3 && (
                <Tag style={{ borderRadius: 12, fontSize: 10 }}>+{taskDetails.labels.length - 3}</Tag>
              )}
            </Space>
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <Space>
                <Tag color={PRIORITY_COLORS[taskDetails.priority]} style={{ borderRadius: 12, fontSize: 10 }}>{taskDetails.priority}</Tag>
                <Tag style={{ borderRadius: 12, fontSize: 10 }}>{taskDetails.phase}</Tag>
              </Space>
              <Progress 
                percent={Math.round(getCompletionPercentage(taskDetails.checklist || []))}
                size="small"
                status="active"
                strokeColor={{
                  '0%': '#108ee9',
                  '100%': '#87d068',
                }}
                style={{ width: '50%' }}
              />
            </Space>
          </div>
        </div>
      </div>

      <Modal
        title={t("Edit Task")}
        visible={isModalVisible}
        onOk={handleUpdateTask}
        onCancel={() => setIsModalVisible(false)}
        width={800}
        bodyStyle={{ maxHeight: '80vh', overflowY: 'auto' }}
      >
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          <div>
            <Title level={5}>{t("Task Title")}</Title>
            <Input
              value={taskDetails.title}
              onChange={(e) => setTaskDetails(prev => ({ ...prev, title: e.target.value }))}
            />
          </div>
          <div>
            <Title level={5}>{t("Task Description")}</Title>
            <Input.TextArea
              value={taskDetails.description}
              onChange={(e) => setTaskDetails(prev => ({ ...prev, description: e.target.value }))}
              rows={4}
            />
          </div>
          <div>
            <Title level={5}>{t("Priority")}</Title>
            <Select
              style={{ width: '100%' }}
              value={taskDetails.priority}
              onChange={(value) => setTaskDetails(prev => ({ ...prev, priority: value }))}
            >
              {PRIORITIES.map(priority => (
                <Option key={priority} value={priority}>{t(priority)}</Option>
              ))}
            </Select>
          </div>
          <div>
            <Title level={5}>{t("Phase")}</Title>
            <Select
              style={{ width: '100%' }}
              value={taskDetails.phase}
              onChange={(value) => setTaskDetails(prev => ({ ...prev, phase: value }))}
            >
              {PHASES.map(phase => (
                <Option key={phase} value={phase}>{t(phase)}</Option>
              ))}
            </Select>
          </div>
          <div>
            <Title level={5}>{t("Due Date")}</Title>
            <DatePicker
              style={{ width: '100%' }}
              value={taskDetails.dueDate ? moment(taskDetails.dueDate) : null}
              onChange={handleDueDateChange}
            />
          </div>
          <div>
            <Title level={5}>{t("Labels")}</Title>
            <Space wrap style={{ marginBottom: 8 }}>
              {taskDetails.labels && taskDetails.labels.map((label) => (
                <Tag
                  key={label.id}
                  color={label.color}
                  closable
                  onClose={() => removeLabel(label)}
                  style={{ borderRadius: 12, padding: '4px 8px' }}
                >
                  {label.text}
                </Tag>
              ))}
            </Space>
            <Select
              style={{ width: '100%' }}
              placeholder={t("Select or create a label")}
              onChange={(value) => {
                const selectedLabel = allLabels.find(label => label.id === value);
                if (selectedLabel) handleAddLabel(selectedLabel);
              }}
              value={undefined}
              dropdownRender={renderLabelDropdown}
            >
              {allLabels.map(label => (
                <Option key={label.id} value={label.id}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Tag color={label.color}>{label.text}</Tag>
                    <Popconfirm
                      title={t("Are you sure you want to delete this label?")}
                      onConfirm={() => deleteLabelGlobally(label)}
                      okText={t("Yes")}
                      cancelText={t("No")}
                    >
                      <Button type="text" icon={<DeleteOutlined />} size="small" onClick={(e) => e.stopPropagation()} />
                    </Popconfirm>
                  </div>
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <Title level={5}>{t("Checklist")}</Title>
            <Progress 
              percent={Math.round(getCompletionPercentage(taskDetails.checklist || []))}
              size="small"
              status="active"
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#108ee9',
                '100%': '#87d068',
              }}
            />
            {taskDetails.checklist && taskDetails.checklist.map((item, index) => (
              <Space key={index} style={{ width: '100%', marginTop: 8 }}>
                <Checkbox
                  checked={item.checked}
                  onChange={(e) => updateChecklistItem(index, 'checked', e.target.checked)}
                />
                <Input
                  value={item.text}
                  onChange={(e) => updateChecklistItem(index, 'text', e.target.value)}
                  style={{ width: '100%' }}
                />
                <Button 
                  icon={<DeleteOutlined />} 
                  onClick={() => removeChecklistItem(index)}
                />
              </Space>
            ))}
            <Button onClick={addChecklistItem} icon={<PlusOutlined />} style={{ marginTop: 8 }}>
              {t("Add Checklist Item")}
            </Button>
          </div>
          <div>
            <Popconfirm
              title={t("Are you sure you want to archive this task?")}
              onConfirm={handleArchiveTask}
              okText={t("Yes")}
              cancelText={t("No")}
              okButtonProps={{ danger: true }}
            >
              <Button danger icon={<DeleteOutlined />}>
                {t("Archive Task")}
              </Button>
            </Popconfirm>
          </div>
        </Space>
      </Modal>
    </>
  );
}

export default TaskCard;