import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import VerticalMenu from './VerticalMenu';
import './dash.css';
import { query, where, onSnapshot, collection, doc, setDoc, getDoc } from 'firebase/firestore';
import Chart from 'react-apexcharts';
import { Table, Card, Row, Col, Statistic, Button, Modal, Empty, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { ArrowUpOutlined, ArrowDownOutlined, PlusOutlined, EditOutlined, DragOutlined, CloseOutlined } from '@ant-design/icons';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import SortableItem from './SortableItem'; // Assuming you have a SortableItem component

const widgetsData = [
  { id: 'tasksStats', title: 'Tasks Stats', span: 12 },
  { id: 'dealsStats', title: 'Deals Stats', span: 12 },
  { id: 'projectsFeatures', title: 'Projects with Most Features', span: 24 },
  { id: 'taskCompletionRate', title: 'Task Completion Rate', span: 12 },
  { id: 'upcomingDeadlines', title: 'Upcoming Deadlines', span: 24 },
  { id: 'tasksStage', title: 'Tasks by Stage', span: 12 },
];

const loadWidgetsFromFirestore = async (userId) => {
  const docRef = doc(db, 'dashboards', userId);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? docSnap.data().widgets : [];
};

const saveWidgetsToFirestore = async (userId, widgets) => {
  const docRef = doc(db, 'dashboards', userId);
  await setDoc(docRef, { widgets });
};

function Dashboard() {
  const user = auth.currentUser;
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [highPriorityTasks, setHighPriorityTasks] = useState([]);
  const [totalTasks, setTotalTasks] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [dealsWon, setDealsWon] = useState(0);
  const [dealsLost, setDealsLost] = useState(0);
  const [tasksByStage, setTasksByStage] = useState({ toDo: 0, inProgress: 0, done: 0 });
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    const fetchData = async () => {
      const userWidgets = await loadWidgetsFromFirestore(user.uid);
      setSelectedWidgets(userWidgets);
      setLoading(false);
    };

    fetchData();

    const projectsRef = collection(db, 'projects');
    const projectsQuery = query(projectsRef, where('userId', '==', user.uid));
    const unsubscribeProjects = onSnapshot(projectsQuery, snapshot => {
      const fetchedProjects = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProjects(fetchedProjects);
      setTotalProjects(fetchedProjects.length);
    });

    const tasksRef = collection(db, 'tasks');
    const highPriorityTasksQuery = query(tasksRef, where('userId', '==', user.uid), where('priority', '==', 'High'));
    const unsubscribeHighPriorityTasks = onSnapshot(highPriorityTasksQuery, snapshot => {
      const fetchedTasks = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setHighPriorityTasks(fetchedTasks);
    });

    const tasksQuery = query(tasksRef, where('userId', '==', user.uid));     
    const unsubscribeTasks = onSnapshot(tasksQuery, snapshot => {
      const fetchedTasks = snapshot.docs.map(doc => doc.data());
      setTotalTasks(fetchedTasks.length);

      const stageCount = { toDo: 0, inProgress: 0, done: 0 };
      fetchedTasks.forEach(task => {
        if (task.phase === 'To Do') stageCount.toDo++;
        if (task.phase === 'In Progress') stageCount.inProgress++;
        if (task.phase === 'Done') stageCount.done++;
      });
      setTasksByStage(stageCount);
    });

    const dealsRef = collection(db, 'deals');
    const dealsQuery = query(dealsRef, where('userId', '==', user.uid));
    const unsubscribeDeals = onSnapshot(dealsQuery, snapshot => {
      let won = 0;
      let lost = 0;
      snapshot.docs.forEach(doc => {
        const deal = doc.data();
        if (deal.stage === 'Won') won++;
        if (deal.stage === 'Lost') lost++;
      });
      setDealsWon(won);
      setDealsLost(lost);
    });

    return () => {
      unsubscribeProjects();
      unsubscribeHighPriorityTasks();
      unsubscribeTasks();
      unsubscribeDeals();
    };
  }, [user, navigate]);

  const getTopFeatureProjects = () => {
    return [...projects].sort((a, b) => (b.features?.length || 0) - (a.features?.length || 0)).slice(0, 5);
  };

  const topFeatureProjects = getTopFeatureProjects().map(project => ({
    key: project.id,
    name: project.name,
    features: project.features?.length || 0,
  }));

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <Link to={`/projects/${record.key}/features`}>{text}</Link>,
    },
    {
      title: 'Features',
      dataIndex: 'features',
      key: 'features',
    },
  ];

  const dealsChartOptions = {
    chart: {
      type: 'pie',
    },
    labels: ['Won', 'Lost'],
  };

  const dealsChartSeries = [dealsWon, dealsLost];

  const tasksStageChartOptions = {
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: ['To Do', 'In Progress', 'Done'],
    },
  };

  const tasksStageChartSeries = [{
    name: 'Tasks',
    data: [tasksByStage.toDo, tasksByStage.inProgress, tasksByStage.done],
  }];

  const addWidget = async (widgetId) => {
    const widgetToAdd = widgetsData.find(widget => widget.id === widgetId);
    if (widgetToAdd && !selectedWidgets.some(widget => widget.id === widgetToAdd.id)) {
      const updatedWidgets = [...selectedWidgets, widgetToAdd];
      setSelectedWidgets(updatedWidgets);
      await saveWidgetsToFirestore(user.uid, updatedWidgets);
    }
    setIsModalVisible(false);
  };

  const removeWidget = async (widgetId) => {
    const updatedWidgets = selectedWidgets.filter(widget => widget.id !== widgetId);
    setSelectedWidgets(updatedWidgets);
    await saveWidgetsToFirestore(user.uid, updatedWidgets);
  };

  const renderWidget = (widget) => {
    const { id, span } = widget;
    return (
      <div key={id} style={{ position: 'relative' }}>
        {isEditing && (
          <Button
            type="link"
            icon={<CloseOutlined />}
            onClick={() => removeWidget(id)}
            style={{ position: 'absolute', top: -10, right: -10, zIndex: 1, backgroundColor: 'white', borderRadius: '50%' }}
          />
        )}
        <Card style={{ borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' }}>
          {widget.id === 'tasksStats' && (
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Statistic
                  title="Active Projects"
                  value={totalProjects}
                  valueStyle={{ color: '#3f8600' }}
                  prefix={<ArrowUpOutlined />}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title="High Priority Tasks"
                  value={highPriorityTasks.length}
                  valueStyle={{ color: '#cf1322' }}
                  prefix={<ArrowDownOutlined />}
                />
              </Col>
              <Col span={8}>
                <Statistic
                  title="Total Tasks"
                  value={totalTasks}
                  valueStyle={{ color: '#1890ff' }}
                  prefix={<ArrowUpOutlined />}
                />
              </Col>
            </Row>
          )}
          {widget.id === 'dealsStats' && (
            <Chart options={dealsChartOptions} series={dealsChartSeries} type="pie" height={350} />
          )}
          {widget.id === 'projectsFeatures' && (
            <Table dataSource={topFeatureProjects} columns={columns} pagination={false} />
          )}
          {widget.id === 'taskCompletionRate' && (
            <Statistic
              title="Completion Rate"
              value={(tasksByStage.done / totalTasks) * 100}
              precision={2}
              suffix="%"
              valueStyle={{ color: '#52c41a' }}
            />
          )}
          {widget.id === 'upcomingDeadlines' && (
            <Table
              dataSource={projects.flatMap(project => project.tasks?.filter(task => new Date(task.deadline) > new Date()).map(task => ({
                key: task.id,
                name: task.name,
                deadline: task.deadline,
              })) || [])}
              columns={[
                { title: 'Task', dataIndex: 'name', key: 'name' },
                { title: 'Deadline', dataIndex: 'deadline', key: 'deadline', render: date => new Date(date).toLocaleDateString() },
              ]}
              pagination={false}
            />
          )}
          {widget.id === 'tasksStage' && (
            <Chart options={tasksStageChartOptions} series={tasksStageChartSeries} type="bar" height={350} />
          )}
        </Card>
      </div>
    );
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = selectedWidgets.findIndex(widget => widget.id === active.id);
      const newIndex = selectedWidgets.findIndex(widget => widget.id === over.id);
      const updatedWidgets = arrayMove(selectedWidgets, oldIndex, newIndex);
      setSelectedWidgets(updatedWidgets);
      await saveWidgetsToFirestore(user.uid, updatedWidgets);
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh', backgroundColor: '#f0f2f5' }}>
      <VerticalMenu />
      <div style={{ flex: 1, padding: '30px', overflowY: 'auto' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
          <Button type="primary" icon={<EditOutlined />} onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? 'Done' : 'Edit'}
          </Button>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)} style={{ marginLeft: 10 }}>
            Add Widget
          </Button>
        </div>
        {loading ? (
          <Spin tip="Loading...">
            <div style={{ height: 'calc(100vh - 100px)' }}></div>
          </Spin>
        ) : selectedWidgets.length === 0 ? (
          <Empty
            image={<DragOutlined style={{ fontSize: 64 }} />}
            description={
              <span>
                Personalize your dashboard! <br />
                Drag and drop to add, remove, and rearrange widgets.
              </span>
            }
          />
        ) : (
          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={selectedWidgets.map(widget => widget.id)} strategy={rectSortingStrategy}>
              <Row gutter={[16, 16]}>
                {selectedWidgets.map(widget => (
                  <Col key={widget.id} span={widget.span}>
                    <SortableItem id={widget.id}>
                      {renderWidget(widget)}
                    </SortableItem>
                  </Col>
                ))}
              </Row>
            </SortableContext>
          </DndContext>
        )}
        <Modal
          title="Add Widget"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          {widgetsData.map(widget => (
            <Card key={widget.id} style={{ marginBottom: 10, borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)' }}>
              {renderWidget(widget)}
              <Button type="primary" onClick={() => addWidget(widget.id)} style={{ marginTop: 10 }}>
                {widget.title}
              </Button>
            </Card>
          ))}
        </Modal>
      </div>
    </div>
  );
}

export default Dashboard;
