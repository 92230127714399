import React, { useState } from 'react';
import { Form, Input, Button, Typography, message, Alert } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';
import { auth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from '../../firebase';
import logo from './logo.png'; // Make sure to import your logo

const { Title, Text } = Typography;

const Register = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      if (values.password !== values.confirmPassword) {
        throw new Error("Passwords do not match.");
      }

      const userCredential = await createUserWithEmailAndPassword(auth, values.email, values.password);
      const user = userCredential.user;
      await updateProfile(user, { displayName: `${values.firstName} ${values.lastName}` });
      await sendEmailVerification(user);

      message.success("Registration successful! Please check your email for verification.");
      navigate('/login');
    } catch (err) {
      console.error("Error registering:", err);
      if (err.code === 'auth/email-already-in-use') {
        message.error('Email already in use.');
      } else {
        message.error(err.message);
      }
    }
    setLoading(false);
  };

  return (
    <div style={{
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#f5f5f5',
    }}>
      <div style={{
        width: '100%',
        maxWidth: '400px',
        background: '#ffffff',
        borderRadius: '8px',
        padding: '40px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}>
        <div style={{ textAlign: 'center', marginBottom: '30px' }}>
          <img src={logo} alt="Company Logo" style={{ width: '60px', marginBottom: '16px' }} />
          <Title level={3} style={{ color: '#0077BE', margin: 0 }}>
            Create an Account
          </Title>
        </div>
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="firstName"
            rules={[{ required: true, message: 'Please input your first name!' }]}
          >
            <Input 
              prefix={<UserOutlined style={{ color: '#0077BE' }} />} 
              placeholder="First Name" 
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: 'Please input your last name!' }]}
          >
            <Input 
              prefix={<UserOutlined style={{ color: '#0077BE' }} />} 
              placeholder="Last Name" 
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email!' }
            ]}
          >
            <Input 
              prefix={<MailOutlined style={{ color: '#0077BE' }} />} 
              placeholder="Email" 
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: '#0077BE' }} />}
              placeholder="Password"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              { required: true, message: 'Please confirm your password!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: '#0077BE' }} />}
              placeholder="Confirm Password"
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              style={{ 
                width: '100%', 
                height: '40px',
                background: '#0077BE',
                borderColor: '#0077BE',
              }} 
              size="large" 
              loading={loading}
            >
              Register
            </Button>
          </Form.Item>
        </Form>
        <Text style={{ display: 'block', textAlign: 'center' }}>
          Already have an account? <Link to="/login" style={{ color: '#0077BE' }}>Login here</Link>
        </Text>
      </div>
    </div>
  );
};

export default Register;